import { useState } from "react";
import { useDispatch } from "react-redux";
import { GlobalReducerActions } from "reducers/globalReducer";
import { Box, FormControl, FormHelperText, TextField } from "@mui/material";
import { isValidEmail } from "util/helpers";
import colors from "theme/colors";

const FormTextField = ({
  label,
  fieldName,
  required = false,
  type = "text",
  inputHelperText = "",
  retrieveInfo,
  value,
}) => {
  const dispatch = useDispatch();
  const { setErrorInputs, removeFieldFromErrorInputs } = GlobalReducerActions;
  const [inputValue, setInputValue] = useState(value);
  const [helperText, setHelperText] = useState(inputHelperText);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDisplayError = (error) => {
    const helperText = error ? error : inputHelperText;
    if (!error) dispatch(removeFieldFromErrorInputs(fieldName));
    setHelperText(helperText);
    setError(!!error);
  };

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  const handleBlur = (e) => {
    let error = "";

    if (!inputValue) {
      if (required) {
        error = "This is a required field";
        dispatch(setErrorInputs(fieldName));
      }
    } else {
      if (type === "email" && !isValidEmail(inputValue)) {
        error = "Email format is incorrect";
        dispatch(setErrorInputs(fieldName));
      }

      if (type === "url" && !isValidUrl(inputValue)) {
        error = "URL format is incorrect";
        dispatch(setErrorInputs(fieldName));
      }
    }
    retrieveInfo(fieldName, e.target.value);
    handleDisplayError(error);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": {
          m: 1,
          width: "99%",
          minWidth: "300px",
          marginBottom: "22px",
        },
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl error={error} required={required}>
        <TextField
          variant="outlined"
          id={fieldName}
          name={fieldName}
          type={type}
          value={inputValue}
          onChange={handleChange}
          label={label}
          onBlur={handleBlur}
          onKeyDown={handleEnter}
          sx={{
            "& .MuiInputLabel-root": {
              color: error ? colors.error : colors.primary,
            },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: error ? colors.error : colors.primary,
              },
              "& > fieldset": {
                borderColor: error ? colors.error : colors.primary,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? colors.error : colors.primary,
            },
            "&$cssFocused $notchedOutline": {
              borderColor: error
                ? `${colors.error} !important`
                : `${colors.primary} !important`,
            },
          }}
        />
        <FormHelperText
          style={{
            position: "absolute",
            top: "55px",
            whiteSpace: "nowrap",
            overflow: "auto",
          }}
          id={`${fieldName}-error-text`}
        >
          {helperText}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default FormTextField;
