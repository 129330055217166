import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { LogosReducerActions } from "reducers/logosReducer";
import ROUTES from "constants/Routes";

const EditLogo = () => {
  const formContent = {
    header: "Edit Logo",
    inputs: [
      {
        label: "File",
        fieldName: "image",
        type: "file",
        required: true,
        avatar: "image",
      },
      {
        label: "Logo description",
        fieldName: "description",
        helperText:
          "Add a note about this logo, such as 'white background version', the image dimensions (1200x600), if it's a specific product logo, etc.",
      },
    ],
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const logoData = useSelector((state) => state.logos.selectedLogo);
  const { getSelectedLogoData, setSelectedLogoData, saveSelectedLogoChanges } =
    LogosReducerActions;

  const setLogoData = (fieldName, value) => {
    dispatch(setSelectedLogoData({ ...logoData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveSelectedLogoChanges(kit, logoData));
  };

  useEffect(() => {
    if (kit) dispatch(getSelectedLogoData(kit, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={logoData}
      setNewData={setLogoData}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.LOGOS}`}
    />
  );
};

export default EditLogo;
