import { useSelector } from "react-redux";

import { TestimonialsReducerActions } from "reducers/testimonialsReducer";

import KitContainer from "components/kit/KitContainer";
import QuotationCard from "components/general/QuotationCard";

import { Grid } from "@mui/material";

const KitTestimonials = () => {
  const data = useSelector((state) => state.testimonials.data);
  const { getTestimonialsData } = TestimonialsReducerActions;

  return (
    <KitContainer
      header="Testimonials"
      selectedState="testimonials"
      getData={getTestimonialsData}
    >
      <Grid container>
        {data.map((quote) => (
          <QuotationCard
            quote={quote.testimonial}
            author={quote.name}
            title={quote.title}
            picture={quote.image}
          />
        ))}
      </Grid>
    </KitContainer>
  );
};

export default KitTestimonials;
