import { Avatar } from "@mui/material";
import { useStyles } from "./CardAvatar.styles";

const CardAvatar = ({ width, height, picture, name, containerStyles }) => {
  const { cardAvatarContainer } = useStyles(containerStyles);
  return (
    <div className={cardAvatarContainer}>
      {picture ? (
        <Avatar
          alt={name}
          sx={{
            width: width,
            height: height,
            margin: "0 auto",
          }}
          src={picture}
        />
      ) : (
        <Avatar
          sx={{
            width: width,
            height: height,
            margin: "0 auto",
          }}
        />
      )}
    </div>
  );
};

export default CardAvatar;
