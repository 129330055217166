import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { AwardsReducerActions } from "reducers/awardsReducer";
import { setMembersOrderStr } from "util/helpers";

import ROUTES from "constants/Routes";

const Awards = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const awardsData = useSelector((state) => state.awards.data);
  const retrievedData = useSelector((state) => state.awards.retrievedData);
  const originalPositions = useSelector(
    (state) => state.advisors.originalPositions
  );
  const newPositions = useSelector((state) => state.advisors.newPositions);
  const {
    getAwardsData,
    setAwardsData,
    setAwardsNewPositions,
    saveChanges,
    deleteAwardData,
  } = AwardsReducerActions;

  const setNewData = (data) => {
    dispatch(setAwardsData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, awardsData));
  };

  const deleteAward = (id) => {
    dispatch(deleteAwardData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getAwardsData(kit));
    if (kit && retrievedData && !awardsData.length)
      dispatch(getAwardsData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(setAwardsNewPositions(setMembersOrderStr(awardsData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awardsData]);

  return (
    <FormListContainer
      header="Add or Edit Awards"
      subheader="​​Add information about awards and recognitions you’ve received"
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_AWARD}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_AWARD}`}
      redirectLabel={"award"}
      data={awardsData}
      fieldForCaptionText="date"
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deleteAward}
      avatar="award"
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default Awards;
