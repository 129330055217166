import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { MediaCoverageReducerActions } from "reducers/mediaCoverageReducer";
import MediaCard from "components/general/MediaCard";
import Grid from "@mui/material/Grid";
import KitContainer from "components/kit/KitContainer";

const VISIBLE_ITEMS = 6; // Extraído como una constante

const KitMediaCoverage = () => {
  const data = useSelector((state) => state.mediaCoverage.data);
  const [displayedItems, setDisplayedItems] = useState(VISIBLE_ITEMS);
  const { getMediaCoverageData } = MediaCoverageReducerActions;

  const displayViewMore = useMemo(
    () => data.length > displayedItems,
    [data.length, displayedItems]
  );

  return (
    <KitContainer
      header="Media Coverage"
      selectedState="mediaCoverage"
      getData={getMediaCoverageData}
      dataLength={data.length}
      setDisplayedItems={setDisplayedItems}
      displayViewMoreButton={displayViewMore}
      visibleItems={VISIBLE_ITEMS}
    >
      <Grid container>
        {data.slice(0, displayedItems).map((article) => (
          <MediaCard
            key={article.id}
            title={article.title}
            text={article.excerpt}
            image={article.image}
            date={article.date}
            link={article.link}
            excerpt={article.excerpt}
          />
        ))}
      </Grid>
    </KitContainer>
  );
};

export default KitMediaCoverage;
