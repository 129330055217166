import { useNavigate } from "react-router-dom";

import Button from "components/general/Button";

import Routes from "constants/Routes";

import { useStyles } from "./Home.styles";

const Home = () => {
  const { homecontainer, homeTitle, homeImage, homeRedirectButton } =
    useStyles();

  const navigate = useNavigate();
  const hasStarted = localStorage.getItem("hasStarted");

  const handleClick = () => {
    localStorage.setItem("hasStarted", true);
    navigate(`${Routes.SETTINGS_PREFIX}${Routes.ABOUT}`);
  };

  return (
    <div className={homecontainer}>
      <span className={homeTitle}>{`Welcome ${
        hasStarted ? "back " : ""
      }!`}</span>
      <img className={homeImage} src="/home-image.svg" alt="" />
      {!hasStarted && (
        <Button onClick={handleClick} className={homeRedirectButton}>
          Get started
        </Button>
      )}
    </div>
  );
};

export default Home;
