import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  videoCardTitleContainer: {
    height: "106px",
    paddingTop: "20px",
    paddingLeft: "22px",
  },
  videoLink: {
    textDecoration: "inherit",
    color: "inherit",
  },
}));
