import { makeStyles } from "@material-ui/styles";

import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  tabPanelContainer: {
    borderRadius: "8px",
    background: `${colors.white}`,
    boxShadow: "0px 1px 18px -7px rgba(0, 0, 0, 0.25)",
    marginTop: "20px",
    marginBottom: "20px",
    maxWidth: "950px",
  },
}));
