import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ViewMoreButton from "components/general/ViewMoreButton";
import SectionHeader from "components/general/SectionHeader/SectionHeader";
import LoaderStatic from "components/general/loaders/Loader/LoaderStatic";
import { useStyles } from "./KitContainer.styles";

const KitContainer = ({
  header,
  selectedState,
  getData,
  dataLength,
  children,
  setDisplayedItems = () => {},
  displayViewMoreButton = false,
  visibleItems,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [viewMore, setViewMore] = useState(false);
  const loading = useSelector((state) => state.global.loading);
  const subdomain = useSelector((state) => state.global.subdomain);
  const state = useSelector((state) => state);

  useEffect(() => {
    if (subdomain && !state[selectedState]?.retrievedData)
      dispatch(getData(subdomain));
    viewMore ? setDisplayedItems(dataLength) : setDisplayedItems(visibleItems);
  }, [subdomain, viewMore]);

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  return (
    <div className={classes.container}>
      <SectionHeader header={header} subheader="" kit={true} />
      {loading ? (
        <div className={classes.loadingContainer}>
          <LoaderStatic />
        </div>
      ) : (
        <div>
          {children}
          {displayViewMoreButton && (
            <ViewMoreButton
              displayButton={displayViewMoreButton}
              viewMore={viewMore}
              handleViewMore={handleViewMore}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default KitContainer;
