import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useStyles } from "./ConfirmationModal.styles";

const ConfirmationModal = ({open, handleClose, handleClick}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className={classes.confirmationTitle}>
                {"Are you sure?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className={classes.confirmationText}>
                    This item will be permanently deleted
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.confirmationActionsContainer}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleClick} variant="contained" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ConfirmationModal;