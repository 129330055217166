import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { ImagesReducerActions } from "reducers/imagesReducer";
import { setMembersOrderStr } from "util/helpers";

import ROUTES from "constants/Routes";

const Images = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const imagesData = useSelector((state) => state.images.data);
  const retrievedData = useSelector((state) => state.images.retrievedData);
  const originalPositions = useSelector(
    (state) => state.images.originalPositions
  );
  const newPositions = useSelector((state) => state.images.newPositions);
  const {
    getImagesData,
    setImagesData,
    setImagesNewPositions,
    saveChanges,
    deleteImageData,
  } = ImagesReducerActions;

  const setNewData = (data) => {
    dispatch(setImagesData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, imagesData));
  };

  const deleteImage = (id) => {
    dispatch(deleteImageData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getImagesData(kit));
    if (kit && retrievedData && !imagesData.length)
      dispatch(getImagesData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(setImagesNewPositions(setMembersOrderStr(imagesData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesData]);

  return (
    <FormListContainer
      header="Add or edit images"
      subheader="Add high-quality images of your team, product(s), app(s) or website"
      hasImage
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_IMAGE}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_IMAGE}`}
      redirectLabel={"image"}
      itemLabel="description"
      data={imagesData}
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deleteImage}
      avatar="image"
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default Images;
