import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  teamMembersDataContainer: {
    width: "60%",
    height: "100%",
    paddingTop: "20px",
  },
  quoteUpperContainer: {
    width: "100%",
    display: "flex",
    height: "141px",
  },
  quotelowerContainer: {
    display: "flex",
    width: "100%",
    height: "76px",
  },
  quoteLeftContainer: {
    justifyContent: "center",
    display: "flex",
    alignItems: "flex-start",
    width: "108px",
  },
  quoteContainer: {
    width: "333px",
    height: "141px",
    paddingTop: "25px",
    WebkitBoxOrient: "horizontal",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  quoteAuthorContainer: {
    width: "333px",
    height: "100%",
    paddingTop: "10px",
  },
}));
