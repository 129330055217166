import React, { useState, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import { ImagesReducerActions } from "reducers/imagesReducer";
import ImageCard from "components/general/ImageCard";
import KitContainer from "components/kit/KitContainer";

const KitImages = () => {
  const data = useSelector((state) => state.images.data, shallowEqual);
  const { getImagesData } = ImagesReducerActions;
  const visibleItems = 6;
  const [displayedItems, setDisplayedItems] = useState(visibleItems);

  const handleViewMore = useCallback(() => {
    setDisplayedItems(
      (prevDisplayedItems) => prevDisplayedItems + visibleItems
    );
  }, [visibleItems]);

  return (
    <KitContainer
      header="Images"
      selectedState="images"
      getData={getImagesData}
      dataLength={data.length}
      setDisplayedItems={handleViewMore}
      displayViewMoreButton={data.length > displayedItems}
      visibleItems={visibleItems}
    >
      <Grid container>
        {data.slice(0, displayedItems).map((image) => {
          const { id, description, image: imageUrl } = image;
          return <ImageCard key={id} title={description} image={imageUrl} />;
        })}
      </Grid>
    </KitContainer>
  );
};

KitImages.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    })
  ),
};

export default React.memo(KitImages);
