import Avatar from "@mui/material/Avatar";
import colors from "theme/colors";
import { useIconsMap } from "hooks/useIconsMap";

const FormItemAvatar = ({ type = "", list = true, image = null }) => {
  const setAvatarStyle = () => ({
    width: list ? 46 : 66,
    height: list ? 46 : 66,
    margin: "0 auto",
    background: colors.avatarBackground,
  });

  const setIconStyle = (size = "45px") => ({
    fontSize: list ? "30px" : size,
    color: colors.avatarColor,
  });

  const iconsMap = useIconsMap({ style: setIconStyle() });

  return type ? (
    <Avatar sx={setAvatarStyle()} src={image}>
      {iconsMap[type]}
    </Avatar>
  ) : null;
};

export default FormItemAvatar;
