export const MEDIA_OUTLETS = {
  facebook: "Facebook",
  blog: "Blog",
  pinterest: "Pinterest",
  website: "Website",
  app_store: "App Store",
  medium: "Medium",
  linkedin: "LinkedIn",
  google_play: "Google Play",
  twitter: "Twitter",
  crunchbase: "Crunchbase",
  youtube: "YouTube",
  link: "Link",
};
