import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
  },
  fieldContainer: {
    width: "100%",
    minHeight: "10px",
    overflow: "hidden",
    display: "flex",
    alignItems: "flex-start",
  },
  itemAvatarContainer: {
    marginRight: "20px",
  },
  inputContainer: {
    width: "100%",
  },
  inputContainerWithFileUpload: {
    width: "99%",
  },
  fieldsContainer: {
    display: "flex",
    // width: '100%',
  },
  fileUploadfieldContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "30%",
    // padding: '10px 20px 0 40px',
  },
  nonFileUploadfieldsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "70%",
  },

  loadingContainer: {
    position: "relative",
    top: "20%",
  },
}));
