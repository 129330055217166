import { makeStyles } from "@material-ui/styles";
import colors from "theme/colors";

export const useStyle = makeStyles({
  editableTextArea: {
    height: "350px",
    background: colors.background,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    borderRadius: "15px",
    fontFamily: "Roboto",
    border: `1px solid ${colors.primary}`,
    marginLeft: "6px",
    padding: "34px",
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: colors.primary
    },
    '&:focus': {
      outline: "none !important",
      border: `1px solid ${colors.primary}`,
    }
  },
});
