import { makeStyles } from "@material-ui/styles";

import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  tab: {
    color: colors.gray,
    borderBottom: `1px solid ${colors.gray}`,
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "normal",
    "&.Mui-selected": {
      fontWeight: 600,
    },
    textTransform: "capitalize",
  },
}));
