import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";

const FormDatepickerField = ({ label, fieldName, value, retrieveInfo }) => {
  const [selectedDate, setSelectedDate] = useState(value || dayjs());

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    retrieveInfo(fieldName, selectedDate, false);
  }, [selectedDate]);

  return (
    <Box
      sx={{
        "& > :not(style)": {
          m: 1,
          width: "40%",
          minWidth: "300px",
          marginBottom: "22px",
        },
      }}
      noValidate
      autoComplete="off"
    >
      <Stack component="form" noValidate spacing={3}>
        <DatePicker
          label={label}
          format="MM-DD-YYYY"
          value={dayjs(selectedDate)}
          onChange={handleDateChange}
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": { color: "primary.main" },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": { borderColor: "primary.main" },
              "& > fieldset": { borderColor: "primary.main" },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          }}
        />
      </Stack>
    </Box>
  );
};

FormDatepickerField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  retrieveInfo: PropTypes.func.isRequired,
};

FormDatepickerField.defaultProps = {
  value: "",
};

export default FormDatepickerField;
