import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useStyles } from "./FormWithPosition.styles";
import FormItemAvatar from "../FormItemAvatar";
import ResolveLinkWrapper from "./ResolveLinkWrapper";
import ButtonIcon from "components/general/ButtonIcon";

const ItemRow = ({
  item,
  avatar,
  itemLabel,
  fieldForCaptionText,
  editPath,
  handleNewPosition,
  setDeleteId,
  itemLength,
}) => {
  const { [itemLabel]: label, url, id, position, image } = item;
  const styles = useStyles();

  const shouldDisplayArrow = (isDownArrow = false) => {
    return isDownArrow ? position < itemLength : position > 1;
  };

  return (
    <div className={styles.itemRowStyle} key={id}>
      <div className={styles.avatarContainer}>
        <FormItemAvatar type={avatar} image={image} />
      </div>
      <div className={styles.labelContainer}>
        <ResolveLinkWrapper
          text={label}
          caption={item[fieldForCaptionText]}
          url={url}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.iconContainer}>
          {shouldDisplayArrow() && (
            <ButtonIcon
              onClick={() => handleNewPosition(id, position, -1)}
              name="arrowUp"
            />
          )}
          {shouldDisplayArrow(true) && (
            <ButtonIcon
              onClick={() => handleNewPosition(id, position)}
              name="arrowDown"
            />
          )}
        </div>
        <Link className={styles.iconContainer} to={`${editPath}/${id}`}>
          <ButtonIcon name="edit" />
        </Link>
        <div className={styles.iconContainer}>
          <ButtonIcon onClick={() => setDeleteId(id)} name="delete" />
        </div>
      </div>
    </div>
  );
};

ItemRow.propTypes = {
  avatar: PropTypes.string,
  item: PropTypes.shape({
    image: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    position: PropTypes.number.isRequired,
    fieldForCaptionText: PropTypes.string,
  }).isRequired,
  itemLabel: PropTypes.string.isRequired,
  fieldForCaptionText: PropTypes.string.isRequired,
  editPath: PropTypes.string.isRequired,
  handleNewPosition: PropTypes.func.isRequired,
  setDeleteId: PropTypes.func.isRequired,
  itemLength: PropTypes.number.isRequired,
};

ItemRow.defaultProps = {
  avatar: "",
};

export default React.memo(ItemRow); // Using React.memo to prevent unnecessary re-renders
