import CardsWrapper from "../CardsWrapper/CardsWrapper";
import Card from "../Card/Card";
import CardText from "../CardText/CardText";
import ReactPlayer from "react-player";
import { useStyles } from "./VideoCard.styles";

const VideoCard = ({ title, video }) => {
  const classes = useStyles();
  return (
    <CardsWrapper lg={5}>
      <Card cardStyles={{ paddingRight: 0, width: "304px" }}>
        <ReactPlayer width="100%" height={176} url={video} />
        <div className={classes.videoCardTitleContainer}>
          <a
            className={classes.videoLink}
            href={video}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CardText type="header" text={title} />
          </a>
        </div>
      </Card>
    </CardsWrapper>
  );
};

export default VideoCard;
