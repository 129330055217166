import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";
import { setMembersOrderStr } from "util/helpers";
import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage, setCustomedNotificationMessage } =
  GlobalReducerActions;
const { SECTION, LIST_ITEM } = Endpoints;
const { AWARDS_TABLE } = Tables;

const NEW_AWARD = {
  link: "",
  name: "",
  image: "",
};

const AWARDS_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: [],
  selectedAward: {},
  originalPositions: "",
  newPositions: "",
  newAward: { ...NEW_AWARD },
};

export const AWARDS_REDUCER_TYPES = {
  GET_AWARDS_DATA: "GET_AWARDS_DATA",
  SET_AWARDS_DATA: "SET_AWARDS_DATA",
  GET_SELECTED_AWARD_DATA: "GET_SELECTED_AWARD_DATA",
  SET_SELECTED_AWARD_DATA: "SET_SELECTED_AWARD_DATA",
  SET_AWARDS_RETRIEVED_DATA: "SET_AWARDS_RETRIEVED_DATA",
  SET_NEW_AWARD_DATA: "SET_NEW_AWARD_DATA",
  SET_AWARDS_NEW_POSITIONS_DATA: "SET_AWARDS_NEW_POSITIONS_DATA",
};

export const awardsReducer = (state = AWARDS_REDUCER_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case AWARDS_REDUCER_TYPES.GET_AWARDS_DATA:
      const updatedPayload = [...payload];
      updatedPayload.forEach((item) => {
        if (!item.originalPosition) {
          item.originalPosition = item.position;
        }
      });
      return {
        ...state,
        data: updatedPayload,
        originalPositions: setMembersOrderStr(updatedPayload, true),
        newPositions: setMembersOrderStr(updatedPayload, true),
      };
    case AWARDS_REDUCER_TYPES.SET_AWARDS_DATA:
      return {
        ...state,
        data: payload,
      };
    case AWARDS_REDUCER_TYPES.SET_AWARDS_NEW_POSITIONS_DATA:
      return {
        ...state,
        newPositions: payload,
      };
    case AWARDS_REDUCER_TYPES.GET_SELECTED_AWARD_DATA:
      return {
        ...state,
        selectedAward: payload,
      };
    case AWARDS_REDUCER_TYPES.SET_SELECTED_AWARD_DATA:
      return {
        ...state,
        selectedAward: payload,
      };
    case AWARDS_REDUCER_TYPES.SET_NEW_AWARD_DATA:
      return {
        ...state,
        newAward: payload,
      };
    case AWARDS_REDUCER_TYPES.SET_AWARDS_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getAwardsData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${AWARDS_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: AWARDS_REDUCER_TYPES.GET_AWARDS_DATA,
          payload: response.data.item,
        });
      }
      dispatch({
        type: AWARDS_REDUCER_TYPES.SET_AWARDS_RETRIEVED_DATA,
        payload: true,
      });
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: AWARDS_REDUCER_TYPES.SET_AWARDS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const getSelectedAwardData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${LIST_ITEM}/${kit}/${AWARDS_TABLE}/${id}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: AWARDS_REDUCER_TYPES.GET_SELECTED_AWARD_DATA,
          payload: response.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: AWARDS_REDUCER_TYPES.SET_AWARDS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setAwardsNewPositions = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: AWARDS_REDUCER_TYPES.SET_AWARDS_NEW_POSITIONS_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setAwardsData = (payload) => {
  const sortedPositions = payload.sort((a, b) => a.position - b.position);
  return async (dispatch) => {
    try {
      dispatch({
        type: AWARDS_REDUCER_TYPES.SET_AWARDS_DATA,
        payload: sortedPositions,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setSelectedAwardData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: AWARDS_REDUCER_TYPES.SET_SELECTED_AWARD_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setNewAwardData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: AWARDS_REDUCER_TYPES.SET_NEW_AWARD_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  const data = [...newData];
  data.forEach((item) => delete item.originalPosition);
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: AWARDS_TABLE,
          item: data,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: AWARDS_REDUCER_TYPES.GET_AWARDS_DATA,
          payload: response.data.item,
        });
        dispatch(setNotificationMessage("default_success"));
      }
    } catch (error) {
      dispatch(setNotificationMessage("default_error"));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const saveSelectedAwardChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "put",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: AWARDS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: AWARDS_REDUCER_TYPES.GET_SELECTED_AWARD_DATA,
          payload: response.data,
        });
        dispatch(setNotificationMessage("default_success"));
      }
    } catch (error) {
      dispatch(setNotificationMessage("default_error"));
      console.log(error);
    } finally {
      dispatch({
        type: AWARDS_REDUCER_TYPES.SET_AWARDS_RETRIEVED_DATA,
        payload: false,
      });
      dispatch(setLoading(false));
    }
  };
};

const saveNewAward = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: AWARDS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: AWARDS_REDUCER_TYPES.GET_AWARDS_DATA,
          payload: response.data.item,
        });
        dispatch({
          type: AWARDS_REDUCER_TYPES.SET_NEW_AWARD_DATA,
          payload: { ...NEW_AWARD },
        });
        dispatch(
          setCustomedNotificationMessage(
            `${newData.name} has been successfully added!`
          )
        );
      }
    } catch (error) {
      dispatch(setNotificationMessage("default_error"));
      console.log(error);
      dispatch(setLoading(false));
    } finally {
      dispatch({
        type: AWARDS_REDUCER_TYPES.SET_AWARDS_RETRIEVED_DATA,
        payload: false,
      });
      dispatch(setLoading(false));
    }
  };
};

const deleteAwardData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "delete",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: AWARDS_TABLE,
          id,
        },
      });
      if (response?.status === 200) {
        const { name } = response.data.deletedItem;
        dispatch(
          setCustomedNotificationMessage(
            `${name} has been successfuly removed!`
          )
        );
        dispatch({
          type: AWARDS_REDUCER_TYPES.GET_AWARDS_DATA,
          payload: response.data.updatedList,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const AwardsReducerActions = {
  getAwardsData,
  setAwardsData,
  getSelectedAwardData,
  setSelectedAwardData,
  setNewAwardData,
  saveChanges,
  saveSelectedAwardChanges,
  saveNewAward,
  deleteAwardData,
  setAwardsNewPositions,
};
