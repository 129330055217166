import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";
import { setMembersOrderStr } from "util/helpers";
import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage, setCustomedNotificationMessage } = GlobalReducerActions;
const { SECTION, LIST_ITEM } = Endpoints;
const { MEDIA_CONTACTS_TABLE } = Tables;

const NEW_MEDIA_CONTACT = {
  name: "",
  email: "",
  phone: "",
  image: "",
};

const MEDIA_CONTACTS_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: [],
  selectedMediaContact: {},
  originalPositions: '',
  newPositions: '',
  newMediaContact: { ...NEW_MEDIA_CONTACT },
};

export const MEDIA_CONTACTS_REDUCER_TYPES = {
  GET_MEDIA_CONTACTS_DATA: "GET_MEDIA_CONTACTS_DATA",
  SET_MEDIA_CONTACTS_DATA: "SET_MEDIA_CONTACTS_DATA",
  GET_SELECTED_MEDIA_CONTACT_DATA: "GET_SELECTED_MEDIA_CONTACT_DATA",
  SET_SELECTED_MEDIA_CONTACT_DATA: "SET_SELECTED_MEDIA_CONTACT_DATA",
  SET_MEDIA_CONTACTS_RETRIEVED_DATA: "SET_MEDIA_CONTACTS_RETRIEVED_DATA",
  SET_NEW_MEDIA_CONTACT_DATA: "SET_NEW_MEDIA_CONTACT_DATA",
  SET_MEDIA_CONTACTS_NEW_POSITIONS_DATA: "SET_MEDIA_CONTACTS_NEW_POSITIONS_DATA",
};

export const mediaContactsReducer = (
  state = MEDIA_CONTACTS_REDUCER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case MEDIA_CONTACTS_REDUCER_TYPES.GET_MEDIA_CONTACTS_DATA:
      const updatedPayload = [...payload];
      updatedPayload.forEach(item => {
        if (!item.originalPosition) {
          item.originalPosition = item.position
        }
      });
      return {
        ...state,
        data: updatedPayload,
        originalPositions: setMembersOrderStr(updatedPayload, true),
        newPositions: setMembersOrderStr(updatedPayload, true)
      };
    case MEDIA_CONTACTS_REDUCER_TYPES.SET_MEDIA_CONTACTS_DATA:
      return {
        ...state,
        data: payload,
      };
    case MEDIA_CONTACTS_REDUCER_TYPES.SET_MEDIA_CONTACTS_NEW_POSITIONS_DATA:
      return {
        ...state,
        newPositions: payload,
      };
    case MEDIA_CONTACTS_REDUCER_TYPES.GET_SELECTED_MEDIA_CONTACT_DATA:
      return {
        ...state,
        selectedMediaContact: payload,
      };
    case MEDIA_CONTACTS_REDUCER_TYPES.SET_SELECTED_MEDIA_CONTACT_DATA:
      return {
        ...state,
        selectedMediaContact: payload,
      };
    case MEDIA_CONTACTS_REDUCER_TYPES.SET_NEW_MEDIA_CONTACT_DATA:
      return {
        ...state,
        newMediaContact: payload,
      };
    case MEDIA_CONTACTS_REDUCER_TYPES.SET_MEDIA_CONTACTS_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getMediaContactsData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${MEDIA_CONTACTS_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: MEDIA_CONTACTS_REDUCER_TYPES.GET_MEDIA_CONTACTS_DATA,
          payload: response.data.item,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: MEDIA_CONTACTS_REDUCER_TYPES.SET_MEDIA_CONTACTS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const getSelectedMediaContactData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${LIST_ITEM}/${kit}/${MEDIA_CONTACTS_TABLE}/${id}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: MEDIA_CONTACTS_REDUCER_TYPES.GET_SELECTED_MEDIA_CONTACT_DATA,
          payload: response.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: MEDIA_CONTACTS_REDUCER_TYPES.SET_MEDIA_CONTACTS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setMediaContactsData = (payload) => {
  const sortedPositions = payload.sort((a, b) => a.position - b.position);
  return async (dispatch) => {
    try {
      dispatch({
        type: MEDIA_CONTACTS_REDUCER_TYPES.SET_MEDIA_CONTACTS_DATA,
        payload: sortedPositions,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setMediaContactsNewPositions = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MEDIA_CONTACTS_REDUCER_TYPES.SET_MEDIA_CONTACTS_NEW_POSITIONS_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setSelectedMediaContactData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MEDIA_CONTACTS_REDUCER_TYPES.SET_SELECTED_MEDIA_CONTACT_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setNewMediaContactData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MEDIA_CONTACTS_REDUCER_TYPES.SET_NEW_MEDIA_CONTACT_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  const data = [...newData];
  data.forEach(item => delete item.originalPosition);
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: MEDIA_CONTACTS_TABLE,
          item: data,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: MEDIA_CONTACTS_REDUCER_TYPES.GET_MEDIA_CONTACTS_DATA,
          payload: response.data.item,
        });
        dispatch(setNotificationMessage('default_success'));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveSelectedMediaContactChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "put",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: MEDIA_CONTACTS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: MEDIA_CONTACTS_REDUCER_TYPES.GET_SELECTED_MEDIA_CONTACT_DATA,
          payload: response.data,
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: MEDIA_CONTACTS_REDUCER_TYPES.SET_MEDIA_CONTACTS_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const saveNewMediaContact = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: MEDIA_CONTACTS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: MEDIA_CONTACTS_REDUCER_TYPES.GET_MEDIA_CONTACTS_DATA,
          payload: response.data.item,
        });
        dispatch({
          type: MEDIA_CONTACTS_REDUCER_TYPES.SET_NEW_MEDIA_CONTACT_DATA,
          payload: { ...NEW_MEDIA_CONTACT },
        });
        dispatch(setCustomedNotificationMessage(`${newData.name} has been successfully added!`));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: MEDIA_CONTACTS_REDUCER_TYPES.SET_MEDIA_CONTACTS_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const deleteMediaContactData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "delete",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: MEDIA_CONTACTS_TABLE,
          id,
        },
      });
      if (response?.status === 200) {
        const { name } = response.data.deletedItem;
        dispatch(setCustomedNotificationMessage(`${name} has been successfuly removed!`));
        dispatch({
          type: MEDIA_CONTACTS_REDUCER_TYPES.GET_MEDIA_CONTACTS_DATA,
          payload: response.data.updatedList,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const MediaContactsReducerActions = {
  getMediaContactsData,
  setMediaContactsData,
  getSelectedMediaContactData,
  setSelectedMediaContactData,
  setNewMediaContactData,
  saveChanges,
  saveSelectedMediaContactChanges,
  saveNewMediaContact,
  deleteMediaContactData,
  setMediaContactsNewPositions,
};
