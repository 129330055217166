import { makeStyles } from "@material-ui/styles";

import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  informationFormContainer: () => ({
    display: "flex",
    width: "100%",
  }),
  informationFormRow: () => ({
    height: "80%",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  }),
  informationFormCol: () => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }),
  kitInfoInput: () => ({
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.primary,
    },
  }),
  kitInfoTitle: () => ({
    color: colors.black,
    fontFamily: "Roboto",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    marginBottom: "16px",
  }),
  kitInfoSentence: () => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginLeft: "23px",
    whiteSpace: "nowrap",
  }),
  kitInfoCopy: () => ({
    color: colors.black,
    fontFamily: "Roboto",
    fontsize: "12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "normal",
  }),
  kitInfoBoldCopy: () => ({
    fontWeight: "bold",
    marginLeft: "2px",
    marginRight: "2px",
  }),
}));
