const Tables = {
  ABOUT_TABLE: "AboutTable",
  TEAM_TABLE: "TeamTable",
  ADVISORS_TABLE: "AdvisorsTable",
  INVESTORS_TABLE: "InvestorsTable",
  LINKS_TABLE: "LinksTable",
  LOCATIONS_TABLE: "LocationsTable",
  MEDIA_CONTACTS_TABLE: "MediaContactsTable",
  MEDIA_COVERAGE_TABLE: "MediaCoverageTable",
  PRESS_RELEASES_TABLE: "PressReleasesTable",
  AWARDS_TABLE: "AwardsTable",
  TESTIMONIALS_TABLE: "TestimonialsTable",
  LOGOS_TABLE: "LogosTable",
  IMAGES_TABLE: "ImagesTable",
  VIDEOS_TABLE: "VideosTable",
  DESIGN_TABLE: "DesignTable",
};

export default Tables;
