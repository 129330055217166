import { useState } from "react";
import CardsWrapper from "../CardsWrapper/CardsWrapper";
import Card from "../Card/Card";
import CardText from "../CardText/CardText";
import CustomedModal from "../CustomedModal/CustomedModal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CardMedia from "@mui/material/CardMedia";
import { Button } from "@mui/material";
import { useStyles } from "./ImageCard.styles";
import useColor from "hooks/useColor";

const ImageCard = ({
  title,
  image = "https://img2.rtve.es/i/?w=1600&i=1442912677842.jpg",
}) => {
  const primary = useColor("primary");
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <CardsWrapper lg={5}>
      {/* cardStyles to be replaced when themed components are implemented */}
      <Card
        cardStyles={{
          paddingRight: 0,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <CardMedia
          onClick={handleOpen}
          component="img"
          sx={{ objectFit: "contain", cursor: "pointer" }}
          height="160"
          image={image}
          alt={title}
        />
        <div className={classes.imageCardTitleContainer}>
          <CardText type="plain" text={title} />
        </div>
        <div className={classes.imageCardDownloadContainer}>
          <Button
            size="small"
            sx={{ color: primary }}
            startIcon={<FileDownloadIcon />}
          >
            Download
          </Button>
        </div>
      </Card>
      <CustomedModal open={open} handleClose={handleClose}>
        <img
          src={image}
          alt={title}
          style={{ maxHeight: "90%", maxWidth: "90%" }}
        />
      </CustomedModal>
    </CardsWrapper>
  );
};

export default ImageCard;
