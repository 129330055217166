import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { PressReleasesReducerActions } from "reducers/pressReleasesReducer";
import ROUTES from "constants/Routes";

const EditPressRelease = () => {
  const formContent = {
    header: "Edit Press Release",
    inputs: [
      {
        label: "Title",
        fieldName: "title",
        required: true,
      },
      {
        label: "Press Release Date",
        fieldName: "date",
        type: "datepicker",
      },
      {
        label: "Text",
        fieldName: "text",
        type: "editableTextArea",
        placeholder: "Write or paste your press release copy here",
      },
    ],
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const pressReleaseData = useSelector(
    (state) => state.pressReleases.selectedPressRelease
  );
  const {
    getSelectedPressReleaseData,
    setSelectedPressReleaseData,
    saveSelectedPressReleaseChanges,
  } = PressReleasesReducerActions;

  const setPressReleaseData = (fieldName, value) => {
    dispatch(
      setSelectedPressReleaseData({ ...pressReleaseData, [fieldName]: value })
    );
  };

  const handleSubmit = () => {
    dispatch(saveSelectedPressReleaseChanges(kit, pressReleaseData));
  };

  useEffect(() => {
    if (kit) dispatch(getSelectedPressReleaseData(kit, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={pressReleaseData}
      setNewData={setPressReleaseData}
      handleSubmit={handleSubmit}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.PRESS_RELEASES}`}
    />
  );
};

export default EditPressRelease;
