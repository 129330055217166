import { makeStyles } from "@material-ui/styles";
import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  sidebarContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "285px",
    height: "100%",
    paddingLeft: "35px",
    background: (props) => props.background,
    "@global": {
      "*::-webkit-scrollbar": {
        width: "0.4em",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: colors.lightGray,
        borderRadius: "15px",
      },
    },
  },
  logoContainer: {
    width: "100%",
    height: "5%",
    padding: "30px 30px 50px 0",
  },
  headerLogo: {
    width: "80%",
  },
  actionsContainer: {
    height: "90%",
    overflowY: "auto",
  },
  topButtonsContainer: {
    width: "100%",
    height: "20%",
  },
  accordionsContainer: {
    width: "100%",
    paddingTop: "30px",
    minHeight: "42%",
    overflow: "hidden",
  },
  bottomButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingBottom: "20px",
    width: "100%",
    height: "35%",
  },
  accordionSummary: {
    paddingLeft: 0,
    marginBottom: "0",
    height: "40px",
    "&.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "20px",
      overflow: "hidden",
    },
  },
  fixedButton: {
    width: "95%",
    height: "56px",
    padding: "19px 0 19px 20px",
    display: "flex",
    justifyContent: "flex-start",
    borderRadius: "0px 100px 100px 0px",
  },
  fixedButtonIcon: {
    width: "18px",
    marginRight: "15px",
    textTransform: "capitalize",
  },
  disabledButton: {
    filter: "grayscale(100%)",
  },
  divider: {
    marginTop: "11px",
    width: "95%",
  },
  accordion: {
    width: "100%",
    background: "transparent",
    boxShadow: "none",
    paddingLeft: "0",
    "&.MuiAccordion-root": {
      marginTop: 0,
      marginBottom: 0,
    },
    "&.MuiAccordion-root:before": {
      display: "none",
    },
  },
  accordionButton: {
    width: "95%",
    height: "40px",
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "60px",
    borderRadius: "0px 100px 100px 0px",
    textTransform: "none",
    background: "#E5ECF8",
    color: "black",
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  accordionDetails: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "60px",
  },
  expandedAccordionButton: {
    background: colors.primary,
    color: "white",
  },
}));
