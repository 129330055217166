import { useState } from "react";

import axiosInstance from "services/axiosInstance";

const useFetch = () => {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
    response: null,
  });

  const fetchData = async (url, method, data = null) => {
    try {
      setStatus({
        loading: true,
        error: false,
        response: null,
      });

      const res = await axiosInstance({
        method,
        url,
        data,
      });

      setStatus({
        loading: false,
        error: false,
        response: res,
      });
    } catch (err) {
      setStatus({
        loading: false,
        error: err?.response?.data?.error || "default_error",
        response: null,
      });
    }
  };

  return { ...status, fetchData };
};

export default useFetch;
