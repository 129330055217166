import { useRef } from "react";
import debounce from "lodash/debounce";

import { Box } from "@mui/material";
import LocalFormInput from "components/form/LocalFormInput";

import { useStyles } from "./InformationStep.styles";

const InformationStep = ({ updateFormState }) => {
  const {
    informationFormContainer,
    informationFormCol,
    informationFormRow,
    kitInfoInput,
    kitInfoTitle,
    kitInfoSentence,
    kitInfoBoldCopy,
    kitInfoCopy,
  } = useStyles();

  const kitNameRef = useRef();
  const subdomainRef = useRef();

  const validateForm = debounce(() => {
    let hasError = false;
    const formValues = {};

    [kitNameRef?.current, subdomainRef?.current].forEach((item) => {
      if (item.error || !item.value) {
        hasError = true;
      } else {
        formValues[item.name] = item.value;
      }
    });

    updateFormState(formValues, hasError);
  }, 600);

  return (
    <Box className={informationFormContainer}>
      <div className={informationFormRow}>
        <div className={informationFormCol}>
          <span className={kitInfoTitle}>Organization Name</span>
          <LocalFormInput
            required
            className={kitInfoInput}
            autoFocus={true}
            label={"Organization Name"}
            ref={kitNameRef}
            fieldName={"kitName"}
            validateForm={validateForm}
          />
          <span className={kitInfoTitle}>Press Kit URL</span>
          <LocalFormInput
            required
            className={kitInfoInput}
            ref={subdomainRef}
            label={"mycompany.pressdeck.co"}
            fieldName={"kitSubdomain"}
            validateForm={validateForm}
          />
          <div className={kitInfoSentence}>
            <span className={kitInfoCopy}>
              This will be your custom URL to share your press kit.
            </span>
            <span className={`${kitInfoCopy} ${kitInfoBoldCopy}`}>
              {`Important:`}
            </span>
            <span className={kitInfoCopy}>This URL cannot be changed.</span>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default InformationStep;
