import { Modal, Fade } from "@mui/material";
import { useStyles } from "./CustomedModal.styles";

const CustomedModal = ({ open, handleClose, children }) => {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} timeout={500} className={classes.content}>
        {children}
      </Fade>
    </Modal>
  );
};

export default CustomedModal;
