import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { VideosReducerActions } from "reducers/videosReducer";
import ROUTES from "constants/Routes";

const NewVideo = () => {
  const formContent = {
    header: "Add New Video",
    inputs: [
      {
        label: "Video Name",
        fieldName: "name",
        required: true,
      },
      {
        label: "YouTube URL",
        fieldName: "youtube_url",
        type: "url",
        required: true,
        helperText:
          "Paste the exact link to your video. For example: https//youtube.com/watch?xyz",
      },
    ],
  };

  const dispatch = useDispatch();
  const { setNewVideoData, saveNewVideo } = VideosReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const videoData = useSelector((state) => state.videos.newVideo);

  const setNewVideo = (fieldName, value) => {
    dispatch(setNewVideoData({ ...videoData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveNewVideo(kit, videoData));
  };

  return (
    <FormContainer
      formContent={formContent}
      data={videoData}
      setNewData={setNewVideo}
      handleSubmit={handleSubmit}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.VIDEOS}`}
    />
  );
};

export default NewVideo;
