import React, { useState, useMemo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PressReleaseTextArea from "components/general/PressReleaseTextArea/PressReleaseTextArea";
import PressReleaseCard from "components/general/PressReleaseCard/PressReleaseCard";
import RedirectButton from "components/general/RedirectButton/RedirectButton";
import Grid from "@mui/material/Grid";
import KitContainer from "components/kit/KitContainer";
import { PressReleasesReducerActions } from "reducers/pressReleasesReducer";

const KitPressReleases = () => {
  const navigate = useNavigate();
  const params = useParams();
  const data = useSelector((state) => state.pressReleases.data);
  const visibleItems = 6;
  const [displayedItems, setDisplayedItems] = useState(visibleItems);
  const { getPressReleasesData } = PressReleasesReducerActions;
  const [selectedRelease, setSelectedRelease] = useState(params.id);

  const onClickCard = useCallback(
    (id) => {
      setSelectedRelease(id);
      navigate(id);
    },
    [navigate]
  );

  const renderList = useMemo(
    () =>
      data
        .slice(0, displayedItems)
        .map(({ id, date, title, text }) => (
          <PressReleaseCard
            key={id}
            id={id}
            date={date}
            header={title}
            text={text}
            selectedRelease={selectedRelease}
            onClickCard={onClickCard}
          />
        )),
    [data, displayedItems, onClickCard, selectedRelease]
  );

  return (
    <KitContainer
      header="Press Releases"
      selectedState="pressReleases"
      getData={getPressReleasesData}
      dataLength={data.length}
      setDisplayedItems={setDisplayedItems}
      displayViewMoreButton={data.length > displayedItems}
      visibleItems={visibleItems}
    >
      {selectedRelease ? (
        <>
          <RedirectButton
            path={`/press_releases`}
            handleClick={() => setSelectedRelease(false)}
          />
          <PressReleaseTextArea
            id={selectedRelease}
            styles={{ width: "100%" }}
          />
        </>
      ) : (
        <Grid container>{renderList}</Grid>
      )}
    </KitContainer>
  );
};

export default KitPressReleases;
