import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  awardCardContainer: {
    width: "308px",
    height: "145px",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    padding: "0 0 0.5rem 2rem",
  },
  awardLink: {
    textDecoration: "none",
  },
  awardAvatar: {
    width: "70px",
    height: "70px",
  },
}));
