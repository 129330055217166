import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { AboutReducerActions } from "reducers/aboutReducer";

const About = () => {
  const formContent = {
    header: "About",
    subheader: "Add information about your company",
    inputs: [
      {
        label: "",
        fieldName: "about_text",
        type: "editableTextArea",
        placeholder: "Type something about your company",
      },
    ],
  };

  const dispatch = useDispatch();
  const { getAboutData, setAboutData, saveChanges } = AboutReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const aboutData = useSelector((state) => state.about.data);
  const retrievedData = useSelector((state) => state.about.retrievedData);

  const setNewAboutData = (fieldName, value) => {
    dispatch(setAboutData({ ...aboutData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, aboutData));
  };

  useEffect(() => {
    if (!retrievedData && kit) dispatch(getAboutData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={aboutData}
      setNewData={setNewAboutData}
      handleSubmit={handleSubmit}
    />
  );
};

export default About;
