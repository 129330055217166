import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import useColor from "hooks/useColor";

import { CssBaseline, Button } from "@mui/material";

import {
  InfoOutlined as InfoOutlinedIcon,
  Groups as GroupsIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  Paid as PaidIcon,
  LocationOn as LocationOnIcon,
  PermContactCalendar as PermContactCalendarIcon,
  PermMedia as PermMediaIcon,
  Public as PublicIcon,
  EmojiEvents as EmojiEventsIcon,
  ThreeP as ThreePIcon,
  Link as LinkIcon,
  BrandingWatermark as BrandingWatermarkIcon,
  CropOriginal as CropOriginalIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
} from "@mui/icons-material";

import { useStyles } from "./KitSidebar.styles";

const iconsMap = {
  about: <InfoOutlinedIcon style={{ fontSize: "18px" }} />,
  team: <GroupsIcon />,
  advisors: (
    <RecordVoiceOverIcon style={{ fontSize: "18px", paddingLedt: "100px" }} />
  ),
  investors: <PaidIcon style={{ fontSize: "18px" }} />,
  locations: <LocationOnIcon style={{ fontSize: "18px" }} />,
  media_contacts: <PermContactCalendarIcon style={{ fontSize: "18px" }} />,
  media_coverage: <PublicIcon style={{ fontSize: "18px" }} />,
  press_releases: <PermMediaIcon style={{ fontSize: "18px" }} />,
  awards: <EmojiEventsIcon style={{ fontSize: "18px" }} />,
  testimonials: <ThreePIcon style={{ fontSize: "18px" }} />,
  links: <LinkIcon style={{ fontSize: "18px" }} />,
  logos: <BrandingWatermarkIcon style={{ fontSize: "18px" }} />,
  images: <CropOriginalIcon style={{ fontSize: "18px" }} />,
  videos: <PlayCircleOutlineIcon style={{ fontSize: "18px" }} />,
};

const KitSideBar = ({ sections }) => {
  const navigate = useNavigate();
  const currentSection = useSelector((state) => state.global.currentSection);
  const primary = useColor("primary");
  const secondary = useColor("secondary");
  const tertiary = useColor("tertiary");
  const background = useColor("background");

  const {
    sidebarContainer,
    headerLogo,
    logoContainer,
    fixedButton,
    sectionTitle,
    sectionItemContainer,
    actionsContainer,
  } = useStyles({ secondary, tertiary, background });

  const handleClick = (sectionPath) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/${sectionPath}`);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      <div className={sidebarContainer}>
        <div className={logoContainer}>
          <img
            className={headerLogo}
            src="/logo_package/png/color_bgtransparent_h_screen.png"
            alt="Press Deck Logo"
          />
        </div>
        <div className={actionsContainer}>
          {sections.map((section) => (
            <>
              <div className={sectionTitle}>{section.title}</div>
              <div className={sectionItemContainer}>
                {section.items.map(({ title, sectionPath }) => (
                  <Button
                    key={sectionPath}
                    className={fixedButton}
                    sx={{
                      textTransform: "none",
                    }}
                    style={{
                      color:
                        sectionPath === currentSection
                          ? primary
                          : "rgba(0, 0, 0, 0.8)",
                    }}
                    onClick={() => handleClick(sectionPath)}
                    startIcon={iconsMap[sectionPath]}
                  >
                    {title}
                  </Button>
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KitSideBar;
