import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import useColor from "hooks/useColor";

import { isEmptyObject } from "util/helpers";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CssBaseline,
  Button,
  Divider,
} from "@mui/material";

import { useStyles } from "./Sidebar.styles";

import {
  SUB_TO_MAIN_SECTIONS_MAP,
  SECTION_TO_STORE_NAME_MAP,
} from "constants/Sections";

const { REACT_APP_HOSTNAME: hostname } = process.env;

const SideBar = ({ sections }) => {
  const navigate = useNavigate();

  const [selectedMainSection, setSelectedMainSection] = useState(null);
  const [selectedSubSection, setSelectedSubSection] = useState(null);
  const [isRedirectDisabled, setIsRedirectDisabled] = useState(null);

  const currentKit = useSelector((state) => state.auth.currentKit);
  const store = useSelector((state) => state); // to be removed once states are managed localy instead of with a redux store
  const loading = useSelector((state) => state.global.loading);

  const { logout } = useAuth0();
  const primary = useColor("primary");
  const secondary = useColor("secondary");
  const tertiary = useColor("tertiary");
  const background = useColor("background");

  const {
    sidebarContainer,
    headerLogo,
    logoContainer,
    fixedButton,
    fixedButtonIcon,
    disabledButton,
    divider,
    accordion,
    accordionButton,
    expandedAccordionButton,
    accordionSummary,
    topButtonsContainer,
    bottomButtonsContainer,
    accordionsContainer,
    actionsContainer,
    accordionDetails,
  } = useStyles({ secondary, tertiary, background });

  const resolveLogout = () => {
    logout();
    localStorage.setItem("role", "");
  };

  const handleRedirectClick = () => {
    const url = `http://${currentKit}.${hostname}/${selectedSubSection}`;
    window.open(url, "_blank");
  };

  const handleAccordionChange = (panel) => (e, isExpanded) => {
    setSelectedMainSection(isExpanded ? panel : false);
  };

  const handleNavItemClick = (route) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(route);
  };

  useEffect(() => {
    const subSection = window.location.pathname.split("/")[2];
    setSelectedSubSection(subSection);

    const isHomeOrSettings = subSection === "home" || subSection === "settings";
    setSelectedMainSection(
      isHomeOrSettings ? null : SUB_TO_MAIN_SECTIONS_MAP[subSection]
    );
  }, [window.location.pathname]);

  useEffect(() => {
    const storeName = SECTION_TO_STORE_NAME_MAP[selectedSubSection];
    const sectionData = store[storeName]?.data;
    setIsRedirectDisabled(
      typeof sectionData === "object"
        ? isEmptyObject(sectionData)
        : !sectionData?.lenght
    );
  }, [loading, selectedSubSection]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      <div className={sidebarContainer}>
        <div className={logoContainer}>
          <img
            className={headerLogo}
            src="/logo_package/png/color_bgtransparent_h_screen.png"
            alt="Press Deck Logo"
          />
        </div>
        <div className={actionsContainer}>
          <div className={topButtonsContainer}>
            <Button
              className={fixedButton}
              sx={{ textTransform: "none" }}
              onClick={() => handleNavItemClick("/settings/home")}
              startIcon={
                <img
                  className={fixedButtonIcon}
                  src="/button_icons/home.png"
                  alt="Home Logo"
                />
              }
            >
              Home
            </Button>
            <Button
              className={`${fixedButton} ${
                isRedirectDisabled ? disabledButton : ""
              }`}
              sx={{ textTransform: "none" }}
              onClick={handleRedirectClick}
              disabled={isRedirectDisabled}
              startIcon={
                <img
                  className={fixedButtonIcon}
                  src="/button_icons/view.png"
                  alt="View Logo"
                />
              }
            >
              View
            </Button>
          </div>
          <Divider className={divider} />
          <div className={accordionsContainer}>
            {sections.map((section) => (
              <Accordion
                key={section.title}
                sx={{ margin: 0 }}
                expanded={selectedMainSection === section.title}
                className={accordion}
                onChange={handleAccordionChange(section.title)}
              >
                <AccordionSummary
                  className={accordionSummary}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Button
                    className={`${accordionButton} ${
                      selectedMainSection === section.title
                        ? expandedAccordionButton
                        : ""
                    }`}
                  >
                    {section.title}
                  </Button>
                </AccordionSummary>
                <AccordionDetails className={accordionDetails}>
                  <ul style={{ padding: 0 }}>
                    {section.items.map(({ title, sectionPath }) => (
                      <li
                        key={sectionPath}
                        onClick={() =>
                          handleNavItemClick(`/settings/${sectionPath}`)
                        }
                        style={{
                          fontWeight:
                            sectionPath === selectedSubSection
                              ? "bold"
                              : "normal",
                          color:
                            sectionPath === selectedSubSection
                              ? primary
                              : "black",
                          listStyleType: "none",
                          lineHeight: "34px",
                          fontSize: "0.875rem",
                          cursor: "pointer",
                          marginBottom: "3px",
                        }}
                      >
                        {title}
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <div className={bottomButtonsContainer}>
            <Button
              className={fixedButton}
              sx={{ textTransform: "none" }}
              onClick={() => handleNavItemClick("/settings/account")}
              startIcon={
                <img
                  className={fixedButtonIcon}
                  src="/button_icons/settings.png"
                  alt="Settings Logo"
                />
              }
            >
              Settings
            </Button>
            <Button
              className={fixedButton}
              sx={{ textTransform: "none", color: "black" }}
              onClick={resolveLogout}
              startIcon={
                <img
                  className={fixedButtonIcon}
                  src="/button_icons/logout.png"
                  alt="Log Out Logo"
                />
              }
            >
              Log Out
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
