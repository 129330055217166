import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { VideosReducerActions } from "reducers/videosReducer";
import { setMembersOrderStr } from "util/helpers";

import ROUTES from "constants/Routes";

const Videos = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const videosData = useSelector((state) => state.videos.data);
  const retrievedData = useSelector((state) => state.videos.retrievedData);
  const originalPositions = useSelector(
    (state) => state.videos.originalPositions
  );
  const newPositions = useSelector((state) => state.videos.newPositions);
  const {
    getVideosData,
    setVideosData,
    setVideosNewPositions,
    saveChanges,
    deleteVideoData,
  } = VideosReducerActions;

  const setNewData = (data) => {
    dispatch(setVideosData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, videosData));
  };

  const deleteVideo = (id) => {
    dispatch(deleteVideoData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getVideosData(kit));
    if (kit && retrievedData && !videosData.length)
      dispatch(getVideosData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(setVideosNewPositions(setMembersOrderStr(videosData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videosData]);

  return (
    <FormListContainer
      header="Add or edit videos"
      subheader="Add URLs from YouTube"
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_VIDEO}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_VIDEO}`}
      redirectLabel={"video"}
      data={videosData}
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deleteVideo}
      itemLabel="name"
      fieldForCaptionText="youtube_url"
      avatar="video"
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default Videos;
