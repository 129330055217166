import { useState, useEffect } from "react";

import { useStyle } from "./FormEditableTextArea.styles";

const FormEditableTextArea = ({ placeholder = "", fieldName, value, retrieveInfo }) => {
  const classes = useStyle();
  const [text, setText] = useState(value);

  useEffect(() => {
    retrieveInfo(fieldName, text, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <textarea
      className={classes.editableTextArea}
      aria-label="empty textarea"
      value={text}
      onChange={(e) => setText(e.target.value)}
      placeholder={placeholder}
    />
  );
};

export default FormEditableTextArea;
