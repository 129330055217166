import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";
import { setMembersOrderStr } from "util/helpers";
import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage, setCustomedNotificationMessage } = GlobalReducerActions;
const { SECTION, LIST_ITEM } = Endpoints;
const { VIDEOS_TABLE } = Tables;

const NEW_VIDEO = {
  name: "",
  youtube_url: "",
};

const VIDEOS_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: [],
  selectedVideo: {},
  originalPositions: '',
  newPositions: '',
  newVideo: { ...NEW_VIDEO },
};

export const VIDEOS_REDUCER_TYPES = {
  GET_VIDEOS_DATA: "GET_VIDEOS_DATA",
  SET_VIDEOS_DATA: "SET_VIDEOS_DATA",
  GET_SELECTED_VIDEO_DATA: "GET_SELECTED_VIDEO_DATA",
  SET_SELECTED_VIDEO_DATA: "SET_SELECTED_VIDEO_DATA",
  SET_VIDEOS_RETRIEVED_DATA: "SET_VIDEOS_RETRIEVED_DATA",
  SET_NEW_VIDEO_DATA: "SET_NEW_VIDEO_DATA",
  SET_VIDEOS_NEW_POSITIONS_DATA: "SET_VIDEOS_NEW_POSITIONS_DATA",
};

export const videosReducer = (state = VIDEOS_REDUCER_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case VIDEOS_REDUCER_TYPES.GET_VIDEOS_DATA:
      const updatedPayload = [...payload];
      updatedPayload.forEach(item => {
        if (!item.originalPosition) {
          item.originalPosition = item.position
        }
      });
      return {
        ...state,
        data: updatedPayload,
        originalPositions: setMembersOrderStr(updatedPayload, true),
        newPositions: setMembersOrderStr(updatedPayload, true)
      };
    case VIDEOS_REDUCER_TYPES.SET_VIDEOS_DATA:
      return {
        ...state,
        data: payload,
      };
    case VIDEOS_REDUCER_TYPES.SET_VIDEOS_NEW_POSITIONS_DATA:
      return {
        ...state,
        newPositions: payload,
      };
    case VIDEOS_REDUCER_TYPES.GET_SELECTED_VIDEO_DATA:
      return {
        ...state,
        selectedVideo: payload,
      };
    case VIDEOS_REDUCER_TYPES.SET_SELECTED_VIDEO_DATA:
      return {
        ...state,
        selectedVideo: payload,
      };
    case VIDEOS_REDUCER_TYPES.SET_NEW_VIDEO_DATA:
      return {
        ...state,
        newVideo: payload,
      };
    case VIDEOS_REDUCER_TYPES.SET_VIDEOS_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getVideosData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${VIDEOS_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: VIDEOS_REDUCER_TYPES.GET_VIDEOS_DATA,
          payload: response.data.item,
        });
      };
    }

    catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: VIDEOS_REDUCER_TYPES.SET_VIDEOS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const getSelectedVideoData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${LIST_ITEM}/${kit}/${VIDEOS_TABLE}/${id}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: VIDEOS_REDUCER_TYPES.GET_SELECTED_VIDEO_DATA,
          payload: response.data.data,
        });
        
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: VIDEOS_REDUCER_TYPES.SET_VIDEOS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setVideosNewPositions = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: VIDEOS_REDUCER_TYPES.SET_VIDEOS_NEW_POSITIONS_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setVideosData = (payload) => {
  const sortedPositions = payload.sort((a, b) => a.position - b.position);
  return async (dispatch) => {
    try {
      dispatch({
        type: VIDEOS_REDUCER_TYPES.SET_VIDEOS_DATA,
        payload: sortedPositions,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setSelectedVideoData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: VIDEOS_REDUCER_TYPES.SET_SELECTED_VIDEO_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setNewVideoData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: VIDEOS_REDUCER_TYPES.SET_NEW_VIDEO_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  const data = [...newData];
  data.forEach(item => delete item.originalPosition);
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: VIDEOS_TABLE,
          item: data,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: VIDEOS_REDUCER_TYPES.GET_VIDEOS_DATA,
          payload: response.data.item,
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const saveSelectedVideoChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "put",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: VIDEOS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: VIDEOS_REDUCER_TYPES.GET_SELECTED_VIDEO_DATA,
          payload: response.data,
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: VIDEOS_REDUCER_TYPES.SET_VIDEOS_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const saveNewVideo = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: VIDEOS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: VIDEOS_REDUCER_TYPES.GET_VIDEOS_DATA,
          payload: response.data.item,
        });
        dispatch({
          type: VIDEOS_REDUCER_TYPES.SET_NEW_VIDEO_DATA,
          payload: { ...NEW_VIDEO },
        });
        dispatch(setCustomedNotificationMessage(`${newData.name} has been successfully added!`));
      }
      dispatch({
        type: VIDEOS_REDUCER_TYPES.SET_VIDEOS_RETRIEVED_DATA,
        payload: false,
      });
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: VIDEOS_REDUCER_TYPES.SET_VIDEOS_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const deleteVideoData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "delete",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: VIDEOS_TABLE,
          id,
        },
      });
      if (response?.status === 200) {
        const { name } = response.data.deletedItem;
        dispatch(setCustomedNotificationMessage(`${name} has been successfuly removed!`));
        dispatch({
          type: VIDEOS_REDUCER_TYPES.GET_VIDEOS_DATA,
          payload: response.data.updatedList,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const VideosReducerActions = {
  getVideosData,
  setVideosData,
  getSelectedVideoData,
  setSelectedVideoData,
  setNewVideoData,
  saveChanges,
  saveSelectedVideoChanges,
  saveNewVideo,
  deleteVideoData,
  setVideosNewPositions
};
