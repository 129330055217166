import { useState, forwardRef } from "react";
import debounce from "lodash/debounce";

import colors from "theme/colors";

import {
  Box,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";

import { isValidEmail } from "util/helpers";

const LocalFormInput = forwardRef(
  (
    {
      label,
      fieldName,
      required = false,
      type = "text",
      inputHelperText = "",
      value,
      inputRef,
      validateForm,
      ...rest
    },
    ref
  ) => {
    const [helperText, setHelperText] = useState(inputHelperText);

    const validateFields = debounce(() => {
      let error = false;
      let helperText = "";

      if (!ref?.current.value && required) {
        helperText = "This is a required field";
        error = true;
      } else if (type === "email" && !isValidEmail(ref?.current.value)) {
        helperText = "Email format is incorrect";
        error = true;
      }

      if (ref?.current) ref.current.error = error;
      setHelperText(helperText);

      validateForm();
    }, 600);

    return (
      <Box
        component="form"
        sx={{
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minWidth: "300px",
            marginLeft: "0px",
            marginTop: "0px",
            marginBottom: "25px",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl error={ref?.current?.error} required={required}>
          <InputLabel
            htmlFor={fieldName}
            sx={{
              "&.MuiInputLabel-animated": {
                color: colors[helperText ? "error" : "primary"],
              },
            }}
          >
            {label}
          </InputLabel>
          <OutlinedInput
            id={fieldName}
            name={fieldName}
            type={type}
            label={label}
            inputRef={ref}
            onChange={validateFields}
            onBlur={validateFields}
            {...rest}
          />
          <FormHelperText
            style={{
              position: "absolute",
              top: "55px",
              whiteSpace: "nowrap",
              overflow: "auto",
            }}
            id={`${fieldName}-error-text`}
          >
            {helperText}
          </FormHelperText>
        </FormControl>
      </Box>
    );
  }
);

export default LocalFormInput;
