import React, { useState, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import { VideosReducerActions } from "reducers/videosReducer";
import VideoCard from "components/general/VideoCard/VideoCard";
import KitContainer from "components/kit/KitContainer";

const KitVideos = () => {
  const data = useSelector((state) => state.videos.data, shallowEqual);
  const { getVideosData } = VideosReducerActions;
  const visibleItems = 3;
  const [displayedItems, setDisplayedItems] = useState(visibleItems);

  const handleViewMore = useCallback(() => {
    setDisplayedItems(
      (prevDisplayedItems) => prevDisplayedItems + visibleItems
    );
  }, [visibleItems]);

  return (
    <KitContainer
      header="Videos"
      selectedState="videos"
      getData={getVideosData}
      dataLength={data.length}
      setDisplayedItems={handleViewMore}
      displayViewMoreButton={data.length > displayedItems}
      visibleItems={visibleItems}
    >
      <Grid container>
        {data.slice(0, displayedItems).map((video) => {
          const { id, name, youtube_url } = video;
          return <VideoCard key={id} title={name} video={youtube_url} />;
        })}
      </Grid>
    </KitContainer>
  );
};

KitVideos.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      youtube_url: PropTypes.string.isRequired,
    })
  ),
};

export default React.memo(KitVideos);
