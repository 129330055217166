import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "store";
import { Auth0Provider } from "@auth0/auth0-react";

import App from "./containers/App/Index";

const {
  REACT_APP_AUTH0_DOMAIN: domain,
  REACT_APP_AUTH0_CLIENT_ID: clientId,
  REACT_APP_AUTH0_AUTHORIZER_AUDIENCE: audience,
  REACT_APP_REDIRECT_URI: redirectUri,
} = process.env;

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={redirectUri}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
