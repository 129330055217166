import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  imageContainer: {
    padding: "10px",
    marginBottom: "30px",
  },
  imageCardTitleContainer: {
    padding: "28px 32px 0",
  },
  imageCardDownloadContainer: {
    paddingTop: "28px",
    paddingLeft: "27px",
    paddingBottom: "22px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    outline: "none",
  },
}));
