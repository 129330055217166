import PropTypes from "prop-types";
import CardsWrapper from "../CardsWrapper/CardsWrapper";
import Card from "../Card/Card";
import CardText from "../CardText/CardText";
import { useStyles } from "./PressReleaseCard.styles";
import { formatIfDate } from "util/dateFormat";

const PressReleaseCard = ({
  id,
  date,
  header,
  text,
  onClickCard,
  selectedRelease,
}) => {
  const styles = useStyles();
  const handleClick = () => {
    onClickCard(id);
  };
  return (
    <CardsWrapper lg={selectedRelease ? 12 : 4}>
      <Card onClick={handleClick} className={styles.cardContainer}>
        <CardText type="date" text={formatIfDate(date)} />
        <CardText type="header" text={header} />
        <CardText
          type="plain"
          text={text}
          customedStyles={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 6,
          }}
        />
      </Card>
    </CardsWrapper>
  );
};

export default PressReleaseCard;

PressReleaseCard.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  selectedRelease: PropTypes.string,
  onClickCard: PropTypes.func.isRequired,
};

PressReleaseCard.defaultProps = {
  selectedRelease: null,
};
