import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { LogosReducerActions } from "reducers/logosReducer";
import ROUTES from "constants/Routes";
import { setMembersOrderStr } from "util/helpers";

const Team = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const logosData = useSelector((state) => state.logos.data);
  const retrievedData = useSelector((state) => state.logos.retrievedData);
  const originalPositions = useSelector(
    (state) => state.logos.originalPositions
  );
  const newPositions = useSelector((state) => state.logos.newPositions);
  const {
    getLogosData,
    setLogosData,
    setLogosNewPositions,
    saveChanges,
    deleteLogoData,
  } = LogosReducerActions;

  const setNewData = (data) => {
    dispatch(setLogosData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, logosData));
  };

  const deleteLogo = (id) => {
    dispatch(deleteLogoData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getLogosData(kit));
    if (kit && retrievedData && !logosData.length) dispatch(getLogosData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(setLogosNewPositions(setMembersOrderStr(logosData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logosData]);

  return (
    <FormListContainer
      header="Add or edit logos"
      subheader="Add high-quality logos for your company or product"
      hasImage
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_LOGO}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_LOGO}`}
      redirectLabel={"logo"}
      itemLabel="description"
      data={logosData}
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deleteLogo}
      avatar="image"
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default Team;
