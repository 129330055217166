import React, { useState, useRef, useEffect, forwardRef } from "react";
import { useStyles } from "./Icon.styles";

const Icon = forwardRef((props, ref) => {
  const { name, size, fill, ...rest } = props;
  const ImportedIconRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const { icon } = useStyles({ size, fill });

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    const importIcon = async () => {
      try {
        ImportedIconRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!images/icons/${name}.svg`
          )
        ).default;
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    importIcon();

    return () => {
      isMounted = false;
    };
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    return <ImportedIcon className={icon} ref={ref} {...rest} />;
  }

  return null;
});

export default Icon;
