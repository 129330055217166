import useColor from "hooks/useColor";
import { useIconsMap } from "hooks/useIconsMap";

import CardText from "../CardText/CardText";
import { Tooltip, Avatar } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";

import { MEDIA_OUTLETS } from "constants/MediaOutlets";

import { useStyles } from "./LinkTile.styles";

const LinkTile = ({ image, url }) => {
  const classes = useStyles();
  const primary = useColor("primary");
  const iconsMap = useIconsMap({
    style: {
      fontSize: 50,
      color: "#808484",
    },
  });

  const LinkTooltip = withStyles({
    tooltip: {
      color: "white",
      backgroundColor: primary,
    },
  })(Tooltip);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <LinkTooltip title={url}>
      <div
        className={classes.singleTileContainer}
        onClick={() => openInNewTab(url)}
      >
        <div className={classes.linkAvatarContainer}>
          <Avatar sx={{ width: 74, height: 74, background: "#EFEDE8" }}>
            {iconsMap[image]}
          </Avatar>
        </div>
        <div>
          <CardText type="mediaOutlet" text={MEDIA_OUTLETS[image]} />
        </div>
      </div>
    </LinkTooltip>
  );
};

export default LinkTile;
