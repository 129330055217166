import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { LinksReducerActions } from "reducers/linksReducer";

const Links = () => {
  const formContent = {
    header: "Links",
    subheader:
      "Add links to your website, social media profiles, app pages, blog, etc.",
    inputs: [
      {
        label: "Website URL",
        fieldName: "website_url",
        required: true,
        avatar: "website",
        type: "url",
      },
      {
        label: "Crunchbase Profile Link",
        fieldName: "crunchbase_url",
        avatar: "crunchbase",
        type: "url",
      },
      {
        label: "Blog URL",
        fieldName: "blog_url",
        avatar: "blog",
        type: "url",
      },
      {
        label: "Medium URL",
        fieldName: "medium_url",
        avatar: "medium",
        type: "url",
      },
      {
        label: "LinkedIn URL",
        fieldName: "linkedin_url",
        avatar: "linkedin",
        type: "url",
      },
      {
        label: "Twitter URL",
        fieldName: "twitter_url",
        avatar: "twitter",
        type: "url",
      },
      {
        label: "Facebook URL",
        fieldName: "facebook_url",
        avatar: "facebook",
        type: "url",
      },
      {
        label: "Pinterest URL",
        fieldName: "pinterest_url",
        avatar: "pinterest",
        type: "url",
      },
      {
        label: "YouTube Link",
        fieldName: "youtube_url",
        avatar: "youtube",
        type: "url",
      },
      {
        label: "App Store URL",
        fieldName: "app_store_url",
        avatar: "app_store",
        type: "url",
      },
      {
        label: "Google Play URL",
        fieldName: "google_play_url",
        avatar: "google_play",
        type: "url",
      },
      {
        label: "Other",
        fieldName: "other_1",
        avatar: "website",
        type: "url",
      },
      {
        label: "Other",
        fieldName: "other_2",
        avatar: "website",
        type: "url",
      },
      {
        label: "Other",
        fieldName: "other_3",
        avatar: "website",
        type: "url",
      },
    ],
  };

  const dispatch = useDispatch();
  const { getLinksData, setLinksData, saveChanges } = LinksReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const linksData = useSelector((state) => state.links.data);
  const retrievedData = useSelector((state) => state.links.retrievedData);

  const setNewLinksData = (fieldName, value) => {
    dispatch(setLinksData({ ...linksData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, linksData));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getLinksData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={linksData}
      setNewData={setNewLinksData}
      handleSubmit={handleSubmit}
      inputAvatar={true}
    />
  );
};

export default Links;
