import { default as MaterialButton } from "@mui/material/Button";
import { useStyles } from "./Button.styles";

const Button = ({
  handleClick,
  children,
  size = "medium",
  variant = "contained",
  styleProps,
  ...rest
}) => {
  const { buttonStyle } = useStyles(styleProps);

  return (
    <MaterialButton
      className={buttonStyle}
      onClick={handleClick}
      variant={variant}
      size={size}
      {...rest}
    >
      {children}
    </MaterialButton>
  );
};

export default Button;
