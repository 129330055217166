import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import colors from "theme/colors";

const iconsMap = {
  arrowDown: {
    icon: <KeyboardArrowDownIcon />,
    style: {
      color: colors.primary,
    },
  },
  arrowUp: {
    icon: <KeyboardArrowUpIcon />,
    style: {
      color: colors.primary,
    },
  },
  edit: {
    icon: <EditIcon />,
    style: {
      color: colors.primary,
    },
  },
  delete: {
    icon: <DeleteIcon />,
    style: {
      color: colors.delete,
    },
  },
};

const ButtonIcon = ({
  onClick,
  variant = "text",
  name = "",
  iconstyles = null,
  ...rest
}) => {
  return (
    <IconButton
      style={iconsMap[name].style}
      variant={variant}
      onClick={onClick}
      {...rest}
    >
      {iconsMap[name].icon}
    </IconButton>
  );
};

export default ButtonIcon;
