import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  layoutMainContainer: () => ({
    display: "flex",
    width: "100%",
    minHeight: "100vh",
    overflow: "hidden",
  }),

  layoutSectionContainer: () => ({
    position: "relative",
    width: "calc(100% - 230px)",
    left: "285px",
    bgcolor: "background.default",
    marginTop: "80px",
    padding: "0 97px 32px 32px",
  }),
}));
