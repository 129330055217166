import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { LogosReducerActions } from "reducers/logosReducer";
import ROUTES from "constants/Routes";

const NewLogo = () => {
  const formContent = {
    header: "Add New Logo",
    inputs: [
      {
        label: "File",
        fieldName: "image",
        type: "file",
        required: true,
        avatar: "image",
      },
      {
        label: "Logo description",
        fieldName: "description",
        helperText:
          "Add a note about this logo, such as 'white background version', the image dimensions (1200x600), if it's a specific product logo, etc.",
      },
    ],
  };

  const dispatch = useDispatch();
  const { setNewLogoData, saveNewLogo } = LogosReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const logoData = useSelector((state) => state.logos.newLogo);

  const setNewLogo = (fieldName, value) => {
    dispatch(setNewLogoData({ ...logoData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveNewLogo(kit, logoData));
  };

  return (
    <FormContainer
      formContent={formContent}
      data={logoData}
      setNewData={setNewLogo}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.LOGOS}`}
    />
  );
};

export default NewLogo;
