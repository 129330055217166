const Routes = {
  HOME: "/home",
  REGISTER: "/register",
  ABOUT: "/about",
  SETTINGS_PREFIX: "/settings",
  ACCOUNT: "/account",
  KIT_PREFIX: "/pr-kit",
  ADVISORS: "/advisors",
  ADVISORS_NEW_MEMBER: "/advisors/new_member",
  ADVISORS_EDIT_MEMBER: "/advisors/edit_member",
  INVESTORS: "/investors",
  INVESTORS_NEW_MEMBER: "/investors/new_member",
  INVESTORS_EDIT_MEMBER: "/investors/edit_member",
  MEDIA_CONTACTS: "/media_contacts",
  NEW_MEDIA_CONTACT: "/media_contacts/new",
  EDIT_MEDIA_CONTACT: "/media_contacts/edit",
  TEAM: "/team",
  TEAM_NEW_MEMBER: "/team/new_member",
  TEAM_EDIT_MEMBER: "/team/edit_member",
  LINKS: "/links",
  LOCATIONS: "/locations",
  NEW_LOCATION: "/locations/new",
  EDIT_LOCATION: "/locations/edit",
  MEDIA_COVERAGE: "/media_coverage",
  MEDIA_COVERAGE_NEW_ARTICLE: "/media_coverage/new_article",
  MEDIA_COVERAGE_EDIT_ARTICLE: "/media_coverage/edit_article",
  PRESS_RELEASES: "/press_releases",
  NEW_PRESS_RELEASE: "/press_releases/new",
  EDIT_PRESS_RELEASE: "/press_releases/edit",
  AWARDS: "/awards",
  NEW_AWARD: "/awards/new",
  EDIT_AWARD: "/awards/edit",
  TESTIMONIALS: "/testimonials",
  NEW_TESTIMONIAL: "/testimonials/new",
  EDIT_TESTIMONIAL: "/testimonials/edit",
  LOGOS: "/logos",
  NEW_LOGO: "/logos/new",
  EDIT_LOGO: "/logos/edit",
  IMAGES: "/images",
  NEW_IMAGE: "/images/new",
  EDIT_IMAGE: "/images/edit",
  VIDEOS: "/videos",
  NEW_VIDEO: "/videos/new",
  EDIT_VIDEO: "/videos/edit",
  DESIGN: "/design",
};

export default Routes;
