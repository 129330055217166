import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { GlobalReducerActions } from "reducers/globalReducer";

const useSubdomain = () => {
  const { setSubdomain } = GlobalReducerActions;
  const dispatch = useDispatch();

  useEffect(() => {
    const hostnameParts = window.location.hostname.split(".");
    const subdomain = hostnameParts.length > 1 ? hostnameParts[0] : "app";
    dispatch(setSubdomain(subdomain));
  }, []);
};

export default useSubdomain;
