import { Alert, Snackbar } from "@mui/material";

const Notification = ({
  handler,
  notificationState,
  vertical = "top",
  horizontal = "right",
  type,
}) => {
  const { display, message } = notificationState;

  return (
    <Snackbar
      open={display}
      autoHideDuration={3000}
      onClose={handler}
      anchorOrigin={{
        vertical,
        horizontal,
      }}
    >
      <Alert severity={type} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
