import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  confirmationDialog: {
    // '& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop': {
    //   backgroundColor: 'rgba(0, 0, 0, 0.15)',
    // }
  },
  confirmationTitle: {
    fontWeight: 400,
    fontSize: "2rem",
    lineHeight: "37px",
    color: "#767A7C",
    marginBottom: "22px",
  },
  confirmationText: {
    fontWeight: 400,
    height: "84px",
    fontSize: "1.25rem",
    lineHeight: "22px",
    marginBottom: "22px",
    color: "#867B7B",
  },
  confirmationActionsContainer: {
    marginBottom: "16px",
    paddingRight: "25px",
  },
}));
