import { makeStyles } from "@material-ui/styles";

import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  registerFormContainer: () => ({
    width: "80%",
    height: "80%",
  }),
  stepContainer: () => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
  }),
  notSelected: () => ({
    display: "none",
  }),
  titlesContainer: () => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "28px",
    marginTop: "10%",
  }),
  registrationTitelTextBase: () => ({
    color: colors.black,
    fontFamily: "Roboto",
    fontStyle: "normal",
    lineHeight: "normal",
  }),
  registrationTitle: () => ({
    fontSize: "36px",
    fontWeight: "700",
  }),
  registerStepper: () => ({
    width: "80%",
    marginBottom: "36px",

    "& .MuiStep-root": {
      padding: "0px",
    },
    "& .MuiStepConnector-horizontal": {
      top: "15px",
      left: "calc(-100% + 24px)",
      right: "calc(100%)",
    },
    "& .MuiStepConnector-line": {
      borderTopWidth: "3px",
      borderTopColor: colors.primary,
    },
  }),
  registerActiveStepper: () => ({
    "& .MuiSvgIcon-root": {
      color: `${colors.primary} !important`,
    },
  }),
  registerStep: () => ({
    "& .MuiStepLabel-horizontal": {
      width: "fit-content",
      display: "flex",
      alignItems: "flex-start",
    },
    "& .MuiSvgIcon-root": {
      color: colors.lightGray,
    },
  }),
  registerStepLabel: () => ({
    "& .MuiStepLabel-alternativeLabel": {
      color: colors.black,
      fontFamily: "Roboto",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
      marginTop: "5px",
    },
  }),
}));
