import { makeStyles } from "@material-ui/styles";
import { setBoxShadow } from "util/helpers";

export const useStyles = makeStyles(() => ({
  singleTileContainer: {
    marginBottom: "65px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  linkTile: {
    width: "160px",
    height: "160px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    cursor: "pointer",
    ...setBoxShadow(),
  },
  linkAvatarContainer: {
    paddingBottom: "20px",
  },
}));
