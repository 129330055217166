import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { TeamReducerActions } from "reducers/teamReducer";
import ROUTES from "constants/Routes";

const TeamNewMember = () => {
  const formContent = {
    header: "Add New Team Member",
    inputs: [
      {
        label: "Name",
        fieldName: "name",
        required: true,
      },
      {
        label: "Title/Role",
        fieldName: "title_role",
      },
      {
        label: "Email",
        fieldName: "email",
        type: "email",
      },
      {
        label: "LinkedIn URL",
        fieldName: "linkedin_url",
        type: "url",
      },
      {
        label: "Twitter URL",
        fieldName: "twitter_url",
        type: "url",
      },
      {
        label: "Image",
        fieldName: "image",
        avatar: "member",
        type: "file",
      },
    ],
  };

  const dispatch = useDispatch();
  const { setNewTeamMemberData, saveNewTeamMember } = TeamReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const teamMemberData = useSelector((state) => state.team.newTeamMember);

  const setNewTeamMember = (fieldName, value) => {
    dispatch(setNewTeamMemberData({ ...teamMemberData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveNewTeamMember(kit, teamMemberData));
  };

  return (
    <FormContainer
      formContent={formContent}
      data={teamMemberData}
      setNewData={setNewTeamMember}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.TEAM}`}
    />
  );
};

export default TeamNewMember;
