import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  cardsContainer: (props) => ({
    padding: "10px 20px 10px 0",
    marginBottom: "30px",
    display: "flex",
    ...props,
  }),
  linkStyles: {
    cursor: "pointer",
    textDecoration: "none",
  },
}));
