const dayjs = require("dayjs");
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export const formatIfDate = (input) => {
  const formats = [
    "MM/DD/YYYY",
    "DD/MM/YYYY",
    "YYYY-MM-DD",
    "DD-MM-YYYY",
    "MM.DD.YYYY",
    "DD.MM.YYYY",
    "YYYY/MM/DD",
    "YYYY/DD/MM",
    "MM-DD-YYYY",
  ];

  const date = dayjs(input, formats, true);
  if (date.isValid()) {
    return date.format("MM-DD-YYYY");
  } else {
    return input;
  }
};
