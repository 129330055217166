import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { DesignReducerActions } from "reducers/designReducer";

const Design = () => {
  const formContent = {
    header: "Press Kit Design",
    subheader: "Add your logo and customize the color of your press kit.",
    route: "/",
    inputs: [
      {
        label: "Primary Color",
        fieldName: "primary_color",
        type: "colorPicker",
      },
      {
        label: "Secondary Color",
        fieldName: "secondary_color",
        type: "colorPicker",
      },
      {
        label: " Tertiary Color",
        fieldName: "tertiary_color",
        type: "colorPicker",
      },
      {
        label: "Logo",
        fieldName: "image",
        type: "file",
        avatar: "file",
      },
    ],
  };

  const dispatch = useDispatch();
  const { getDesignData, setDesignData, saveChanges } = DesignReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const designData = useSelector((state) => state.design.data);
  const retrievedData = useSelector((state) => state.design.retrievedData);

  const setNewDesignData = (fieldName, value) => {
    dispatch(setDesignData({ ...designData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, designData));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getDesignData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={designData}
      setNewData={setNewDesignData}
      handleSubmit={handleSubmit}
      fileUpload={true}
    />
  );
};

export default Design;
