import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";
import { setMembersOrderStr } from "util/helpers";
import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage, setCustomedNotificationMessage } =
  GlobalReducerActions;
const { SECTION, LIST_ITEM } = Endpoints;
const { IMAGES_TABLE } = Tables;

const NEW_IMAGE = {
  image: "",
  description: "",
};

const IMAGES_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: [],
  selectedImage: {},
  originalPositions: "",
  newPositions: "",
  newImage: { ...NEW_IMAGE },
};

export const IMAGES_REDUCER_TYPES = {
  GET_IMAGES_DATA: "GET_IMAGES_DATA",
  SET_IMAGES_DATA: "SET_IMAGES_DATA",
  GET_SELECTED_IMAGE_DATA: "GET_SELECTED_IMAGE_DATA",
  SET_SELECTED_IMAGE_DATA: "SET_SELECTED_IMAGE_DATA",
  SET_IMAGES_RETRIEVED_DATA: "SET_IMAGES_RETRIEVED_DATA",
  SET_NEW_IMAGE_DATA: "SET_NEW_IMAGE_DATA",
  SET_IMAGES_NEW_POSITIONS_DATA: "SET_IMAGES_NEW_POSITIONS_DATA",
};

export const imagesReducer = (state = IMAGES_REDUCER_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case IMAGES_REDUCER_TYPES.GET_IMAGES_DATA:
      const updatedPayload = [...payload];
      updatedPayload.forEach((item) => {
        if (!item.originalPosition) {
          item.originalPosition = item.position;
        }
      });
      return {
        ...state,
        data: updatedPayload,
        originalPositions: setMembersOrderStr(updatedPayload, true),
        newPositions: setMembersOrderStr(updatedPayload, true),
      };
    case IMAGES_REDUCER_TYPES.SET_IMAGES_DATA:
      return {
        ...state,
        data: payload,
      };
    case IMAGES_REDUCER_TYPES.SET_IMAGES_NEW_POSITIONS_DATA:
      return {
        ...state,
        newPositions: payload,
      };
    case IMAGES_REDUCER_TYPES.GET_SELECTED_IMAGE_DATA:
      return {
        ...state,
        selectedImage: payload,
      };
    case IMAGES_REDUCER_TYPES.SET_SELECTED_IMAGE_DATA:
      return {
        ...state,
        selectedImage: payload,
      };
    case IMAGES_REDUCER_TYPES.SET_NEW_IMAGE_DATA:
      return {
        ...state,
        newImage: payload,
      };
    case IMAGES_REDUCER_TYPES.SET_IMAGES_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getImagesData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${IMAGES_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: IMAGES_REDUCER_TYPES.GET_IMAGES_DATA,
          payload: response.data.item,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: IMAGES_REDUCER_TYPES.SET_IMAGES_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const getSelectedImageData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${LIST_ITEM}/${kit}/${IMAGES_TABLE}/${id}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: IMAGES_REDUCER_TYPES.GET_SELECTED_IMAGE_DATA,
          payload: response.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: IMAGES_REDUCER_TYPES.SET_IMAGES_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setImagesNewPositions = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: IMAGES_REDUCER_TYPES.SET_IMAGES_NEW_POSITIONS_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setImagesData = (payload) => {
  const sortedPositions = payload.sort((a, b) => a.position - b.position);
  return async (dispatch) => {
    try {
      dispatch({
        type: IMAGES_REDUCER_TYPES.SET_IMAGES_DATA,
        payload: sortedPositions,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setSelectedImageData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: IMAGES_REDUCER_TYPES.SET_SELECTED_IMAGE_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setNewImageData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: IMAGES_REDUCER_TYPES.SET_NEW_IMAGE_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  const data = [...newData];
  data.forEach((item) => delete item.originalPosition);
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: IMAGES_TABLE,
          item: data,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: IMAGES_REDUCER_TYPES.GET_IMAGES_DATA,
          payload: response.data.item,
        });
        dispatch(setNotificationMessage("default_success"));
      }
    } catch (error) {
      dispatch(setNotificationMessage("default_error"));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const saveSelectedImageChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "put",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: IMAGES_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: IMAGES_REDUCER_TYPES.GET_SELECTED_IMAGE_DATA,
          payload: response.data,
        });
        dispatch(setNotificationMessage("default_success"));
      }
    } catch (error) {
      dispatch(setNotificationMessage("default_error"));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: IMAGES_REDUCER_TYPES.SET_IMAGES_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const saveNewImage = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: IMAGES_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: IMAGES_REDUCER_TYPES.GET_IMAGES_DATA,
          payload: response.data.item,
        });
        dispatch({
          type: IMAGES_REDUCER_TYPES.SET_NEW_IMAGE_DATA,
          payload: { ...NEW_IMAGE },
        });
        dispatch(
          setCustomedNotificationMessage(
            `${newData.description} has been successfully added!`
          )
        );
      }
    } catch (error) {
      dispatch(setNotificationMessage("default_error"));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: IMAGES_REDUCER_TYPES.SET_IMAGES_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const deleteImageData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "delete",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: IMAGES_TABLE,
          id,
        },
      });
      if (response?.status === 200) {
        const { description } = response.data.deletedItem;
        dispatch(
          setCustomedNotificationMessage(
            `${description} has been successfuly removed!`
          )
        );
        dispatch({
          type: IMAGES_REDUCER_TYPES.GET_IMAGES_DATA,
          payload: response.data.updatedList,
        });
      }
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

export const ImagesReducerActions = {
  getImagesData,
  setImagesData,
  getSelectedImageData,
  setSelectedImageData,
  setNewImageData,
  saveChanges,
  saveSelectedImageChanges,
  saveNewImage,
  deleteImageData,
  setImagesNewPositions,
};
