import { Tooltip as MuiTooltip } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import useColor from "hooks/useColor";

const Tooltip = ({ placement, title, handleClick = () => {}, children }) => {
  const primary = useColor("primary");

  const StyledTooltip = withStyles({
    tooltip: {
      color: "white",
      backgroundColor: primary,
    },
  })(MuiTooltip);

  return (
    <StyledTooltip placement={placement} title={title} onClick={handleClick}>
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
