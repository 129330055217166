import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { MediaCoverageReducerActions } from "reducers/mediaCoverageReducer";
import { setMembersOrderStr } from "util/helpers";

import ROUTES from "constants/Routes";

const MediaCoverage = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const mediaCoverageData = useSelector((state) => state.mediaCoverage.data);
  const retrievedData = useSelector(
    (state) => state.mediaCoverage.retrievedData
  );
  const originalPositions = useSelector(
    (state) => state.mediaCoverage.originalPositions
  );
  const newPositions = useSelector((state) => state.mediaCoverage.newPositions);
  const {
    getMediaCoverageData,
    setMediaCoverageData,
    setMediaCoverageNewPositions,
    saveChanges,
    deleteArticleData,
  } = MediaCoverageReducerActions;

  const setNewData = (data) => {
    dispatch(setMediaCoverageData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, mediaCoverageData));
  };

  const deleteArticle = (id) => {
    dispatch(deleteArticleData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getMediaCoverageData(kit));
    if (kit && retrievedData && !mediaCoverageData.length)
      dispatch(getMediaCoverageData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(
      setMediaCoverageNewPositions(setMembersOrderStr(mediaCoverageData))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaCoverageData]);

  return (
    <FormListContainer
      header="Published Articles"
      subheader="Add published articles, blog posts, or stories about your product or business"
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.MEDIA_COVERAGE_NEW_ARTICLE}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.MEDIA_COVERAGE_EDIT_ARTICLE}`}
      redirectLabel={"article"}
      data={mediaCoverageData}
      fieldForCaptionText="date"
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deleteArticle}
      avatar="media"
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default MediaCoverage;
