import React, { useState, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import { LogosReducerActions } from "reducers/logosReducer";
import ImageCard from "components/general/ImageCard";
import KitContainer from "components/kit/KitContainer";

const KitLogos = () => {
  const data = useSelector((state) => state.logos.data, shallowEqual);
  const { getLogosData } = LogosReducerActions;
  const visibleItems = 3;
  const [displayedItems, setDisplayedItems] = useState(visibleItems);

  const handleViewMore = useCallback(() => {
    setDisplayedItems(
      (prevDisplayedItems) => prevDisplayedItems + visibleItems
    );
  }, [visibleItems]);

  return (
    <KitContainer
      header="Logos"
      selectedState="logos"
      getData={getLogosData}
      dataLength={data.length}
      setDisplayedItems={handleViewMore}
      displayViewMoreButton={data.length > displayedItems}
      visibleItems={visibleItems}
    >
      <Grid container>
        {data.slice(0, displayedItems).map((logo) => {
          const { id, description, image } = logo;
          return <ImageCard key={id} title={description} image={image} />;
        })}
      </Grid>
    </KitContainer>
  );
};

KitLogos.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    })
  ),
};

export default React.memo(KitLogos);
