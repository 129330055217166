import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";
import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage } = GlobalReducerActions;
const { SECTION } = Endpoints;
const { ABOUT_TABLE } = Tables;

const ABOUT_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: {
    about_text: "",
  },
};

export const ABOUT_REDUCER_TYPES = {
  GET_ABOUT_DATA: "GET_ABOUT_DATA",
  SET_ABOUT_DATA: "SET_ABOUT_DATA",
  SET_ABOUT_RETRIEVED_DATA: "SET_ABOUT_RETRIEVED_DATA",
};

export const aboutReducer = (state = ABOUT_REDUCER_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ABOUT_REDUCER_TYPES.GET_ABOUT_DATA:
      return {
        ...state,
        data: payload,
      };
    case ABOUT_REDUCER_TYPES.SET_ABOUT_DATA:
      return {
        ...state,
        data: payload,
      };
    case ABOUT_REDUCER_TYPES.SET_ABOUT_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getAboutData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${ABOUT_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: ABOUT_REDUCER_TYPES.GET_ABOUT_DATA,
          payload: {
            ...response.data.item,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: ABOUT_REDUCER_TYPES.SET_ABOUT_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setAboutData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ABOUT_REDUCER_TYPES.SET_ABOUT_DATA,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: ABOUT_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: ABOUT_REDUCER_TYPES.GET_ABOUT_DATA,
          payload: {
            ...response.data.item,
          },
        });
        dispatch(setNotificationMessage('default_success'));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

export const AboutReducerActions = {
  getAboutData,
  setAboutData,
  saveChanges,
};
