import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useNotification from "hooks/useNotification";

import { GlobalReducerActions } from "reducers/globalReducer";

import Notification from "components/general/Notification";
import SectionHeader from "components/general/SectionHeader";
import LoaderStatic from "components/general/loaders/Loader/LoaderStatic";
import TabSwitch from "components/settings/Tabs/TabSwitch";
import TabPanel from "components/settings/Tabs/TabPanel";
import DesignTab from "components/settings/DesignTab";

import { SETTINGS_TABS } from "./Settings.constants";
import { useStyles } from "./Settings.styles";

const { REACT_APP_STRIPE_PORTAL } = process.env;

const Settings = () => {
  const loading = useSelector((state) => state.global.loading);
  const user = useSelector((state) => state.auth.user);

  const customedNotificationMessage = useSelector(
    (state) => state.global.customedNotificationMessage
  );
  const { setCustomedNotificationMessage } = GlobalReducerActions;
  const {
    displaySuccess,
    notificationHandler,
    notificationState,
    messageType,
  } = useNotification(customedNotificationMessage);
  const classes = useStyles();

  const dispatch = useDispatch();

  const [activeTabId, setActiveTabId] = useState(SETTINGS_TABS[0].id);

  const handletabSwitch = (newActivetabId) => {
    if (newActivetabId === SETTINGS_TABS[2].id) {
      window.open(
        `${REACT_APP_STRIPE_PORTAL}?prefilled_email=${user}`,
        "_blank"
      );
    } else {
      setActiveTabId(newActivetabId);
    }
  };

  const resetNotification = () => {
    dispatch(setCustomedNotificationMessage(""));
    notificationHandler();
  };

  useEffect(() => {
    return () => {
      resetNotification();
    };
  }, []);

  useEffect(() => {
    if (customedNotificationMessage) displaySuccess();
  }, [customedNotificationMessage]);

  return (
    <div className={classes.container}>
      <SectionHeader hasGraySectionTitle={false} />
      <div>
        <TabSwitch
          activeTabId={activeTabId}
          tabsConfig={SETTINGS_TABS}
          handleClick={handletabSwitch}
        />
      </div>
      {!loading ? (
        <div>
          <TabPanel activeTabId={activeTabId} tabId={SETTINGS_TABS[0].id}>
            profile
          </TabPanel>
          <TabPanel activeTabId={activeTabId} tabId={SETTINGS_TABS[1].id}>
            <DesignTab />
          </TabPanel>
          <TabPanel activeTabId={activeTabId} tabId={SETTINGS_TABS[2].id}>
            billing
          </TabPanel>
        </div>
      ) : (
        <div className={classes.loadingContainer}>
          <LoaderStatic />
        </div>
      )}
      <Notification
        handler={resetNotification}
        notificationState={notificationState}
        vertical="bottom"
        type={messageType}
      />
    </div>
  );
};

export default Settings;
