import { makeStyles } from "@material-ui/styles";

import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  registrationContainer: () => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100vw",
    height: "100vh",
    background: colors.lightBlue,
  }),
  registrationCol: () => ({
    height: "1inherit",
    width: "67%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "35px 0px 0px 0px",
    background: colors.white,
  }),
  logoContainer: {
    display: "flex",
    alignItems: "flex-start",
    width: "33%",
    marginTop: "12%",
    marginLeft: "40px",
  },
  headerLogo: {
    width: "50%",
    height: "fit-content",
  },
}));
