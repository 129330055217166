import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { TeamReducerActions } from "reducers/teamReducer";
import { setMembersOrderStr } from "util/helpers";

import ROUTES from "constants/Routes";

const Team = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const teamMembersData = useSelector((state) => state.team.data);
  const retrievedData = useSelector((state) => state.team.retrievedData);
  const originalPositions = useSelector(
    (state) => state.team.originalPositions
  );
  const newPositions = useSelector((state) => state.team.newPositions);
  const {
    getTeamData,
    setTeamData,
    setTeamMembersNewPositions,
    saveChanges,
    deleteTeamMemberData,
  } = TeamReducerActions;

  const setNewData = (data) => {
    dispatch(setTeamData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, teamMembersData));
  };

  const deleteTeamMember = (id) => {
    dispatch(deleteTeamMemberData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getTeamData(kit));
    if (kit && retrievedData && !teamMembersData.length)
      dispatch(getTeamData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(setTeamMembersNewPositions(setMembersOrderStr(teamMembersData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembersData]);

  return (
    <FormListContainer
      header="Add your Team"
      subheader="Add each person on your team, their role, photo, and contact information"
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.TEAM_NEW_MEMBER}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.TEAM_EDIT_MEMBER}`}
      redirectLabel={"team member"}
      data={teamMembersData}
      fieldForCaptionText="title_role"
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deleteTeamMember}
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default Team;
