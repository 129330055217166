import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  loader: {
    width: "100px",
    height: "100px",
  },
  loaderContainer: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50% , -50%)",
    background: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
}));
