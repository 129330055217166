export const MAIN_SECTIONS = {
  the_basics: "The Basics",
  newsroom: "Newsroom",
  assets: "Assets",
  account: "Settings",
};

export const SIDEBAR_SECTIONS = [
  {
    title: MAIN_SECTIONS.the_basics,
    items: [
      { title: "About", sectionPath: "about" },
      { title: "Team", sectionPath: "team" },
      { title: "Advisors", sectionPath: "advisors" },
      { title: "Investors", sectionPath: "investors" },
      { title: "Locations", sectionPath: "locations" },
    ],
  },
  {
    title: MAIN_SECTIONS.newsroom,
    items: [
      { title: "Media Contacts", sectionPath: "media_contacts" },
      { title: "Media Coverage", sectionPath: "media_coverage" },
      { title: "Press Releases", sectionPath: "press_releases" },
      { title: "Awards", sectionPath: "awards" },
      { title: "Testimonials", sectionPath: "testimonials" },
    ],
  },
  {
    title: MAIN_SECTIONS.assets,
    items: [
      { title: "Links", sectionPath: "links" },
      { title: "Logos", sectionPath: "logos" },
      { title: "Images", sectionPath: "images" },
      { title: "Videos", sectionPath: "videos" },
    ],
  },
];

export const SUB_TO_MAIN_SECTIONS_MAP = {
  about: MAIN_SECTIONS.the_basics,
  team: MAIN_SECTIONS.the_basics,
  advisors: MAIN_SECTIONS.the_basics,
  investors: MAIN_SECTIONS.the_basics,
  locations: MAIN_SECTIONS.the_basics,
  media_contacts: MAIN_SECTIONS.newsroom,
  media_coverage: MAIN_SECTIONS.newsroom,
  press_releases: MAIN_SECTIONS.newsroom,
  awards: MAIN_SECTIONS.newsroom,
  testimonials: MAIN_SECTIONS.newsroom,
  links: MAIN_SECTIONS.assets,
  logos: MAIN_SECTIONS.assets,
  images: MAIN_SECTIONS.assets,
  videos: MAIN_SECTIONS.assets,
  account: MAIN_SECTIONS.account,
};

export const SECTION_TO_STORE_NAME_MAP = {
  about: "about",
  team: "team",
  advisors: "advisors",
  investors: "investors",
  locations: "locations",
  media_contacts: "mediaContacts",
  media_coverage: "mediaCoverage",
  press_releases: "pressReleases",
  awards: "awards",
  testimonials: "testimonials",
  links: "links",
  logos: "logos",
  images: "images",
  videos: "videos",
  account: "account",
};
