import { makeStyles } from "@material-ui/styles";
import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  subscriptionSelectionContainer: () => ({
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    height: "100%",
  }),
  subscriptionCardContainer: () => ({
    flex: "1",
    margin: "5px",
    justifySelf: "flex-start",
    height: "80%",
  }),
  clickableArea: () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
  }),
  cardTextContainer: () => ({
    color: "inherit",
  }),
  unselectedCard: () => ({
    opacity: "0.4",
  }),
  subscriptionDescriptionText: () => ({
    padding: "15px 15px 0px",
    margin: "0px",
    height: "inherit",
    backgroundColor: colors.white,
  }),
  cardIcon: () => ({
    width: "60px",
    height: "60px",
    margin: "10px auto 0px ",
    color: colors.white,
    backgroundColor: colors.lightGray,
  }),
  skeletonStyles: () => ({
    maxWidth: "80%",
    margin: "10px auto",
  }),
}));
