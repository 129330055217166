import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";

import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading } = GlobalReducerActions;
const { SECTION } = Endpoints;
const { DESIGN_TABLE } = Tables;

const DESIGN_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: {
    primary_color: "",
    secondary_color: "",
    tertiary_color: "",
    image: "",
  },
};

export const DESIGN_REDUCER_TYPES = {
  GET_DESIGN_DATA: "GET_DESIGN_DATA",
  SET_DESIGN_DATA: "SET_DESIGN_DATA",
  SET_DESIGN_RETRIEVED_DATA: "SET_DESIGN_RETRIEVED_DATA",
};

export const designReducer = (state = DESIGN_REDUCER_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case DESIGN_REDUCER_TYPES.GET_DESIGN_DATA:
      return {
        ...state,
        data: payload,
      };
    case DESIGN_REDUCER_TYPES.SET_DESIGN_DATA:
      return {
        ...state,
        data: payload,
      };
    case DESIGN_REDUCER_TYPES.SET_DESIGN_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getDesignData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${DESIGN_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: DESIGN_REDUCER_TYPES.GET_DESIGN_DATA,
          payload: {
            ...response.data.item,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: DESIGN_REDUCER_TYPES.SET_DESIGN_RETRIEVED_DATA,
        payload: true,
      });
      dispatch(setLoading(false));
    }
  };
};

const setDesignData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: DESIGN_REDUCER_TYPES.SET_DESIGN_DATA,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: DESIGN_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: DESIGN_REDUCER_TYPES.GET_DESIGN_DATA,
          payload: {
            ...response.data.item,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const DesignReducerActions = {
  getDesignData,
  setDesignData,
  saveChanges,
};
