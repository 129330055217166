import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { MediaContactsReducerActions } from "reducers/mediaContactsReducer";
import ROUTES from "constants/Routes";

const NewMediaContact = () => {
  const formContent = {
    header: "Add new contact person",
    subheader:
      "Add the name and contact information for your main media contact(s)",
    route: "/contact",
    inputs: [
      {
        label: "Contact Name",
        fieldName: "name",
        required: true,
      },
      {
        label: "Contact Email",
        fieldName: "email",
        type: "email",
        required: true,
      },
      {
        label: "Contact Phone Number",
        fieldName: "phone",
      },

      {
        label: "Image",
        fieldName: "image",
        type: "file",
      },
    ],
  };

  const dispatch = useDispatch();
  const { setNewMediaContactData, saveNewMediaContact } =
    MediaContactsReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const mediaContactData = useSelector(
    (state) => state.mediaContacts.newMediaContact
  );

  const setNewMediaContact = (fieldName, value) => {
    dispatch(
      setNewMediaContactData({ ...mediaContactData, [fieldName]: value })
    );
  };

  const handleSubmit = () => {
    dispatch(saveNewMediaContact(kit, mediaContactData));
  };

  return (
    <FormContainer
      formContent={formContent}
      data={mediaContactData}
      setNewData={setNewMediaContact}
      fileUpload={true}
      handleSubmit={handleSubmit}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.MEDIA_CONTACTS}`}
    />
  );
};

export default NewMediaContact;
