import { makeStyles } from "@material-ui/styles";
import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  homecontainer: () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  }),
  homeTitle: () => ({
    letterSpacing: "1.3px",
    color: colors.black,
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "64px",
    fontStyle: "normal",
    fontWeight: "400",
  }),
  homeImage: () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "60%",
    maxWidth: "429px",
  }),
  homeRedirectButton: () => ({
    borderRadius: "40px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.03)",
    textTransform: "none",
    width: "187px",
    height: "43px",
    fontSize: "px",
  }),
}));
