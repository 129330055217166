import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Icon from "components/general/Icon";

import { hexToRgb } from "util/helpers";

import { useStyles } from "./FormColorPickerField.styles";

import { DEFAULT_COLORS } from "constants/Design";

const FormColorPickerField = ({ label, fieldName, value, retrieveInfo }) => {
  const [colorCode, setColorCode] = useState(
    value || DEFAULT_COLORS[fieldName]
  );
  const [showColorPicker, setShowColorPicker] = useState(false);
  const classes = useStyles({ colorCode });

  const formatedRGB = hexToRgb(colorCode).join(" ");

  useEffect(() => {
    if (value) setColorCode(value);
  }, []);

  useEffect(() => {
    retrieveInfo(fieldName, colorCode, false);
  }, [colorCode]);

  const onClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  return (
    <div className={classes.colorPickerContainer}>
      <label className={classes.labelText}>{label}</label>
      <div className={classes.colorContainer}>
        <div id={label} className={classes.colorBox}></div>
        {showColorPicker && (
          <ClickAwayListener onClickAway={() => setShowColorPicker(false)}>
            <div>
              <SketchPicker
                className={classes.colorPicker}
                color={colorCode}
                onChange={(updatedColor) => setColorCode(updatedColor.hex)}
              />
            </div>
          </ClickAwayListener>
        )}
      </div>
      <span className={classes.colorCodesText}>{colorCode}</span>
      <span className={classes.colorCodesText}>{`RGB: ${formatedRGB}`}</span>
      <Icon
        className={classes.selectColorButton}
        name="pencil"
        size="24"
        onClick={onClick}
      />
    </div>
  );
};

export default FormColorPickerField;
