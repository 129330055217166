import { setCurrentSection } from "util/helpers";

import { useStyle } from "./SectionHeader.styles";

const SectionHeader = ({
  header,
  subheader = "",
  kit = false,
  hasGraySectionTitle = true,
}) => {
  const classes = useStyle({ hasGraySectionTitle });

  return (
    <div className={classes.headerContainer}>
      <div style={{ width: "100%" }}>
        <h1 className={classes.formHeader}>
          {!kit && (
            <span className={classes.section}>{setCurrentSection()} </span>
          )}
          <span className={classes.headerText}>{header}</span>
        </h1>
        {subheader && <h4 className={classes.formSubheader}>{subheader}</h4>}
      </div>
    </div>
  );
};

export default SectionHeader;
