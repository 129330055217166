import ArticleIcon from "@mui/icons-material/Article";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AppleIcon from "@mui/icons-material/Apple";
import TodayIcon from "@mui/icons-material/Today";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import DescriptionIcon from "@mui/icons-material/Description";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FeedIcon from "@mui/icons-material/Feed";
import TitleIcon from "@mui/icons-material/Title";
import LinkIcon from "@mui/icons-material/Link";
import Typography from "@mui/material/Typography";
import colors from "theme/colors";

export const useIconsMap = ({ imageStyle = { width: 23 }, style = {} }) => {
  const iconsMap = {
    member: (
      <img src="/icons/member.png" alt="member icon" style={imageStyle} />
    ),
    role: <ArchitectureIcon style={style} />,
    description: <DescriptionIcon style={style} />,
    title: <TitleIcon style={style} />,
    link: <LinkIcon style={style} />,
    quote: <FormatQuoteIcon style={style} />,
    email: <EmailIcon style={style} />,
    phone: <PhoneIphoneIcon style={style} />,
    media: <ArticleIcon style={style} />,
    award: <img src="/icons/award.png" alt="award icon" style={imageStyle} />,
    image: <img src="/icons/image.png" alt="award icon" style={imageStyle} />,
    video: (
      <img src="/icons/slideshow.png" alt="video icon" style={imageStyle} />
    ),
    file: <FileUploadIcon style={style} />,
    location: (
      <img src="/icons/location.png" alt="location icon" style={imageStyle} />
    ),
    locationBlack: <ApartmentIcon style={style} />,
    website: <LanguageIcon style={style} />,
    linkedin: <LinkedInIcon style={style} />,
    twitter: <TwitterIcon style={style} />,
    facebook: <FacebookIcon style={style} />,
    pinterest: <PinterestIcon style={style} />,
    youtube: <YouTubeIcon style={style} />,
    google_play: <PlayArrowIcon style={style} />,
    app_store: <AppleIcon style={style} />,
    calendar: <TodayIcon style={style} />,
    blog: <RssFeedIcon style={style} />,
    publication: <FeedIcon style={style} />,
    crunchbase: (
      <Typography
        sx={{
          display: "inline",
          color: colors.avatarColor,
          fontWeight: "bold",
          fontSize: "25px",
        }}
        component="span"
        variant="body2"
      >
        CB
      </Typography>
    ),
    medium: (
      <Typography
        sx={{
          display: "inline",
          color: "#808484",
          fontWeight: "bold",
          fontSize: "25px",
        }}
        component="span"
        variant="body2"
      >
        M
      </Typography>
    ),
  };
  return iconsMap;
};
