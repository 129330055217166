import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import AwardCard from "components/general/AwardCard";
import KitContainer from "components/kit/KitContainer";

import { AwardsReducerActions } from "reducers/awardsReducer";

const KitMediaCoverage = () => {
  const data = useSelector((state) => state.awards.data);
  const { getAwardsData } = AwardsReducerActions;

  const visibleItems = 6;
  const [displayedItems, setDisplayedItems] = useState(visibleItems);

  return (
    <KitContainer
      header="Awards"
      selectedState="awards"
      getData={getAwardsData}
      dataLength={data.length}
      setDisplayedItems={setDisplayedItems}
      displayViewMoreButton={data.length > displayedItems}
      visibleItems={visibleItems}
    >
      <Grid container>
        {data.slice(0, displayedItems).map((award) => {
          const { id, name, image, link, date } = award;
          return (
            <AwardCard
              key={id}
              name={name}
              image={image}
              date={date}
              link={link}
            />
          );
        })}
      </Grid>
    </KitContainer>
  );
};

KitMediaCoverage.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    })
  ),
};

export default KitMediaCoverage;
