import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  cardContainer: (props) => ({
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingRight: "5px",
    ...props,
  }),
}));
