import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { InvestorsReducerActions } from "reducers/investorsReducer";
import { setMembersOrderStr } from "util/helpers";
import ROUTES from "constants/Routes";

const Investors = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const investorsData = useSelector((state) => state.investors.data);
  const retrievedData = useSelector((state) => state.investors.retrievedData);
  const originalPositions = useSelector(
    (state) => state.investors.originalPositions
  );
  const newPositions = useSelector((state) => state.investors.newPositions);
  const {
    getInvestorsData,
    setInvestorsData,
    saveChanges,
    deleteInvestorData,
    setInvestorsNewPositions,
  } = InvestorsReducerActions;

  const setNewData = (data) => {
    dispatch(setInvestorsData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, investorsData));
  };

  const deleteInvestor = (id) => {
    dispatch(deleteInvestorData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getInvestorsData(kit));
    if (kit && retrievedData && !investorsData.length)
      dispatch(getInvestorsData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(setInvestorsNewPositions(setMembersOrderStr(investorsData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorsData]);

  return (
    <FormListContainer
      header="Add Investors"
      subheader="Add names, descriptions, and images/logos of your investors"
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.INVESTORS_NEW_MEMBER}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.INVESTORS_EDIT_MEMBER}`}
      redirectLabel={"investor"}
      data={investorsData}
      fieldForCaptionText="description"
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deleteInvestor}
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default Investors;
