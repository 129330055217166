import * as React from "react";
import CardsWrapper from "../CardsWrapper/CardsWrapper";
import Card from "../Card/Card";
import { IconButton } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useStyles } from "./TeamMemberCard.styles";
import CardAvatar from "../CardAvatar/CardAvatar";
import Tooltip from "../Tooltip/Tooltip";
import CardText from "../CardText/CardText";
import { Link } from "react-router-dom";

const TeamMemberCard = ({
  name,
  title,
  picture = "",
  email,
  twitter = "",
  linkedIn = "",
  phone = 0,
  website_url = "",
  description,
}) => {
  const classes = useStyles();

  const setTootltipText = (platformUrl) => {
    let trimmedUrl;
    if (platformUrl && platformUrl[platformUrl?.length - 1] === "/") {
      trimmedUrl = platformUrl.substring(0, platformUrl.length - 1);
    } else {
      trimmedUrl = platformUrl;
    }

    const url = trimmedUrl ? trimmedUrl.split("/") : "";
    const userName = url.length ? url[url.length - 1] : "";
    return userName;
  };

  const setTwitterTooltipText = (str) => {
    let twitterStr = "";

    if (str[0] === "@" && !str.includes("/")) twitterStr = str;
    if (str.includes("https://twitter.com/")) {
      twitterStr = str.replace("https://twitter.com/", "@").split("?")[0];
    }

    return twitterStr;
  };
  return (
    <CardsWrapper>
      <Card
        cardStyles={{
          width: "404px",
          height: "190px",
          flexDirection: "column",
          justifyContent: "center",
          paddingRight: "5px",
        }}
      >
        <div className={classes.upperContainer}>
          <CardAvatar
            width={91}
            height={91}
            name={name}
            picture={picture}
            containerStyles={{
              width: "40%",
              height: "100%",
              paddingTop: "16px",
            }}
          />
          <div className={classes.teamMembersDataContainer}>
            <Tooltip placement="bottom" title={name}>
              <CardText type="header" text={name} />
            </Tooltip>
            {description && (
              <Tooltip placement="bottom" title={description}>
                <CardText type="subheader" text={description} />
              </Tooltip>
            )}
            <Tooltip placement="bottom" title={title}>
              <CardText type="subheader" text={title} />
            </Tooltip>
          </div>
        </div>
        <div className={classes.lowerContainer}>
          <div className={classes.iconsContainer}>
            {linkedIn ? (
              <Tooltip placement="top" title={setTootltipText(linkedIn)}>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={() => window.open(linkedIn, "blank").focus()}
                >
                  <LinkedInIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {twitter ? (
              <Tooltip placement="top" title={setTwitterTooltipText(twitter)}>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={() => window.open(twitter, "blank").focus()}
                >
                  <TwitterIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
          <div className={classes.contactInfoContainer}>
            <Tooltip placement="bottom" title={email}>
              <div>
                <CardText type="plain" text={email} />
              </div>
            </Tooltip>
            {phone ? (
              <Tooltip placement="bottom" title={`+${phone}`}>
                <div>
                  <CardText type="plain" text={`+${phone}`} />
                </div>
              </Tooltip>
            ) : null}
            {website_url ? (
              <Tooltip placement="bottom" title={website_url}>
                <Link to={`/${website_url}`}>
                  <CardText type="plain" text={website_url} />
                </Link>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </Card>
    </CardsWrapper>
  );
};

export default TeamMemberCard;
