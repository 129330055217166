import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
  },
  loadingContainer: {
    position: "relative",
    top: "20%",
  },
}));
