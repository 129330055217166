import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import RedirectButton from "components/general/RedirectButton/RedirectButton";

import { GlobalReducerActions } from "reducers/globalReducer";

import Button from "components/general/Button";

import { useStyles } from "./FormActions.styles";

const FormActions = ({ loading, onSave, styleProps, listPath }) => {
  const {
    formActionsContainer,
    rowStyle,
    formActionsButton,
    formActionsButtonsContainer,
  } = useStyles(styleProps);
  const dispatch = useDispatch();
  const errorInputs = useSelector((state) => state.global.errorInputs);
  const { clearErrorInputsArray, setCustomedNotificationMessage } =
    GlobalReducerActions;

  useEffect(() => {
    return () => {
      dispatch(clearErrorInputsArray());
    };
  }, []);

  return (
    <div className={formActionsContainer}>
      <div className={rowStyle}>
        {listPath && (
          <RedirectButton
            path={listPath}
            handleClick={() => dispatch(setCustomedNotificationMessage(""))}
          />
        )}
        <div className={formActionsButtonsContainer}>
          <Button
            htmltype="submit"
            disabled={!!errorInputs.length}
            loading={loading}
            color="primary"
            onClick={onSave}
            className={formActionsButton}
            startIcon={<img src="/button_icons/save.png" alt="save icon" />}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormActions;
