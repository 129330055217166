const GLOBAL_REDUCER_INITIAL_STATE = {
  prKit: false,
  kit: null,
  subdomain: "app",
  liveKit: false,
  currentSectionContainer: "",
  currentSectionHeader: "Home",
  currentSection: "home",
  loading: false,
  errorInputs: [],
  notificationMessage: "",
  customedNotificationMessage: "",
};

export const GLOBAL_REDUCER_TYPES = {
  SET_PR_KIT: "SET_PR_KIT",
  SET_KIT: "SET_KIT",
  SET_SUBDOMAIN: "SET_SUBDOMAIN",
  SET_LIVE_KIT: "SET_LIVE_KIT",
  SET_CURRENT_SECTION: "SET_CURRENT_SECTION",
  SET_CURRENT_SECTION_CONTAINER: "SET_CURRENT_SECTION_CONTAINER",
  SET_CURRENT_SECTION_HEADER: "SET_CURRENT_SECTION_HEADER",
  SET_LOADING: "SET_LOADING",
  SET_ERROR_INPUTS: "SET_ERROR_INPUTS",
  REMOVE_FIELD_FROM_ERROR_INPUTS: "REMOVE_FIELD_FROM_ERROR_INPUTS",
  CLEAR_ERROR_INPUTS: "CLEAR_ERROR_INPUTS",
  SET_NOTIFICATION_MESSAGE: "SET_NOTIFICATION_MESSAGE",
  SET_CUSTOMED_NOTIFICATION_MESSAGE: "SET_CUSTOMED_NOTIFICATION_MESSAGE",
};

export const globalReducer = (state = GLOBAL_REDUCER_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GLOBAL_REDUCER_TYPES.SET_PR_KIT:
      return {
        ...state,
        prKit: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_KIT:
      return {
        ...state,
        kit: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_SUBDOMAIN:
      return {
        ...state,
        subdomain: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_LIVE_KIT:
      return {
        ...state,
        liveKit: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_CURRENT_SECTION:
      return {
        ...state,
        currentSection: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_CURRENT_SECTION_CONTAINER:
      return {
        ...state,
        currentSectionContainer: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_CURRENT_SECTION_HEADER:
      return {
        ...state,
        currentSectionHeader: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_ERROR_INPUTS:
      return {
        ...state,
        errorInputs: [...state.errorInputs, payload],
      };
    case GLOBAL_REDUCER_TYPES.REMOVE_FIELD_FROM_ERROR_INPUTS:
      const index = state.errorInputs.indexOf(payload);
      if (index > -1) {
        return {
          ...state,
          errorInputs: [
            ...state.errorInputs.slice(0, index),
            ...state.errorInputs.slice(index + 1),
          ],
        };
      } else {
        return state;
      }
    case GLOBAL_REDUCER_TYPES.CLEAR_ERROR_INPUTS:
      return {
        ...state,
        errorInputs: [],
      };
    case GLOBAL_REDUCER_TYPES.SET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_CUSTOMED_NOTIFICATION_MESSAGE:
      return {
        ...state,
        customedNotificationMessage: payload,
      };
    default:
      return state;
  }
};

const setPrKit = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_PR_KIT, payload: true });
    } catch (error) {
      console.log(error);
    }
  };
};

const setKit = (newKit) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_KIT, payload: newKit });
    } catch (error) {
      console.log(error);
    }
  };
};

const setSubdomain = (subdomain) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GLOBAL_REDUCER_TYPES.SET_SUBDOMAIN,
        payload: subdomain,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setPrForm = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_PR_KIT, payload: false });
    } catch (error) {
      console.log(error);
    }
  };
};

const setCurrentSection = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_CURRENT_SECTION, payload });
    } catch (error) {
      console.log(error);
    }
  };
};

const setCurrentSectionContainer = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GLOBAL_REDUCER_TYPES.SET_CURRENT_SECTION_CONTAINER,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setCurrentSectionHeader = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GLOBAL_REDUCER_TYPES.SET_CURRENT_SECTION_HEADER,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setLoading = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_LOADING, payload });
    } catch (error) {
      console.log(error);
    }
  };
};

const setErrorInputs = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_ERROR_INPUTS, payload });
    } catch (error) {
      console.log(error);
    }
  };
};

const removeFieldFromErrorInputs = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GLOBAL_REDUCER_TYPES.REMOVE_FIELD_FROM_ERROR_INPUTS,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const clearErrorInputsArray = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GLOBAL_REDUCER_TYPES.CLEAR_ERROR_INPUTS });
    } catch (error) {
      console.log(error);
    }
  };
};

const setNotificationMessage = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GLOBAL_REDUCER_TYPES.SET_NOTIFICATION_MESSAGE,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setCustomedNotificationMessage = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GLOBAL_REDUCER_TYPES.SET_CUSTOMED_NOTIFICATION_MESSAGE,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const GlobalReducerActions = {
  setPrKit,
  setKit,
  setSubdomain,
  setPrForm,
  setCurrentSection,
  setCurrentSectionHeader,
  setCurrentSectionContainer,
  setLoading,
  setErrorInputs,
  removeFieldFromErrorInputs,
  clearErrorInputsArray,
  setNotificationMessage,
  setCustomedNotificationMessage,
};
