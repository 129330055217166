import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";
import { setMembersOrderStr } from "util/helpers";
import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage, setCustomedNotificationMessage } =
  GlobalReducerActions;
const { SECTION, LIST_ITEM } = Endpoints;
const { MEDIA_COVERAGE_TABLE } = Tables;

const NEW_ARTICLE = {
  name: "",
  image: "",
  date: "",
  title: "",
  link: "",
  excerpt: "",
};

const MEDIA_COVERAGE_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: [],
  selectedArticle: {},
  originalPositions: "",
  newPositions: "",
  newArticle: { ...NEW_ARTICLE },
};

export const MEDIA_COVERAGE_REDUCER_TYPES = {
  GET_MEDIA_COVERAGE_DATA: "GET_MEDIA_COVERAGE_DATA",
  SET_MEDIA_COVERAGE_DATA: "SET_MEDIA_COVERAGE_DATA",
  GET_SELECTED_ARTICLE_DATA: "GET_SELECTED_ARTICLE_DATA",
  SET_SELECTED_ARTICLE_DATA: "SET_SELECTED_ARTICLE_DATA",
  SET_MEDIA_COVERAGE_RETRIEVED_DATA: "SET_MEDIA_COVERAGE_RETRIEVED_DATA",
  SET_NEW_ARTICLE_DATA: "SET_NEW_ARTICLE_DATA",
  SET_MEDIA_COVERAGE_NEW_POSITIONS_DATA:
    "SET_MEDIA_COVERAGE_NEW_POSITIONS_DATA",
};

export const mediaCoverageReducer = (
  state = MEDIA_COVERAGE_REDUCER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case MEDIA_COVERAGE_REDUCER_TYPES.GET_MEDIA_COVERAGE_DATA:
      const updatedPayload = [...payload];
      updatedPayload.forEach((item) => {
        if (!item.originalPosition) {
          item.originalPosition = item.position;
        }
      });
      return {
        ...state,
        data: updatedPayload,
        originalPositions: setMembersOrderStr(updatedPayload, true),
        newPositions: setMembersOrderStr(updatedPayload, true),
      };
    case MEDIA_COVERAGE_REDUCER_TYPES.SET_MEDIA_COVERAGE_DATA:
      return {
        ...state,
        data: payload,
      };
    case MEDIA_COVERAGE_REDUCER_TYPES.SET_MEDIA_COVERAGE_NEW_POSITIONS_DATA:
      return {
        ...state,
        newPositions: payload,
      };
    case MEDIA_COVERAGE_REDUCER_TYPES.GET_SELECTED_ARTICLE_DATA:
      return {
        ...state,
        selectedArticle: payload,
      };
    case MEDIA_COVERAGE_REDUCER_TYPES.SET_SELECTED_ARTICLE_DATA:
      return {
        ...state,
        selectedArticle: payload,
      };
    case MEDIA_COVERAGE_REDUCER_TYPES.SET_NEW_ARTICLE_DATA:
      return {
        ...state,
        newArticle: payload,
      };
    case MEDIA_COVERAGE_REDUCER_TYPES.SET_MEDIA_COVERAGE_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getMediaCoverageData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${MEDIA_COVERAGE_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: MEDIA_COVERAGE_REDUCER_TYPES.GET_MEDIA_COVERAGE_DATA,
          payload: response.data.item,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: MEDIA_COVERAGE_REDUCER_TYPES.SET_MEDIA_COVERAGE_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const getSelectedArticleData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${LIST_ITEM}/${kit}/${MEDIA_COVERAGE_TABLE}/${id}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: MEDIA_COVERAGE_REDUCER_TYPES.GET_SELECTED_ARTICLE_DATA,
          payload: response.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: MEDIA_COVERAGE_REDUCER_TYPES.SET_MEDIA_COVERAGE_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setMediaCoverageData = (payload) => {
  const sortedPositions = payload.sort((a, b) => a.position - b.position);
  return async (dispatch) => {
    try {
      dispatch({
        type: MEDIA_COVERAGE_REDUCER_TYPES.SET_MEDIA_COVERAGE_DATA,
        payload: sortedPositions,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setMediaCoverageNewPositions = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MEDIA_COVERAGE_REDUCER_TYPES.SET_MEDIA_COVERAGE_NEW_POSITIONS_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setSelectedArticleData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MEDIA_COVERAGE_REDUCER_TYPES.SET_SELECTED_ARTICLE_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setNewArticleData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MEDIA_COVERAGE_REDUCER_TYPES.SET_NEW_ARTICLE_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  const data = [...newData];
  data.forEach((item) => delete item.originalPosition);
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: MEDIA_COVERAGE_TABLE,
          item: data,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: MEDIA_COVERAGE_REDUCER_TYPES.GET_MEDIA_COVERAGE_DATA,
          payload: response.data.item,
        });
        dispatch(setNotificationMessage("default_success"));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setNotificationMessage("default_error"));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const saveSelectedArticleChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "put",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: MEDIA_COVERAGE_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: MEDIA_COVERAGE_REDUCER_TYPES.GET_SELECTED_ARTICLE_DATA,
          payload: response.data,
        });
        dispatch(setNotificationMessage("default_success"));
      }
      dispatch({
        type: MEDIA_COVERAGE_REDUCER_TYPES.SET_MEDIA_COVERAGE_RETRIEVED_DATA,
        payload: false,
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setNotificationMessage("default_error"));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const saveNewArticle = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: MEDIA_COVERAGE_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: MEDIA_COVERAGE_REDUCER_TYPES.GET_MEDIA_COVERAGE_DATA,
          payload: response.data.item,
        });
        dispatch({
          type: MEDIA_COVERAGE_REDUCER_TYPES.SET_NEW_ARTICLE_DATA,
          payload: { ...NEW_ARTICLE },
        });
        dispatch(
          setCustomedNotificationMessage(
            `${newData.name} has been successfully added!`
          )
        );
      }
    } catch (error) {
      dispatch(setNotificationMessage("default_error"));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: MEDIA_COVERAGE_REDUCER_TYPES.SET_MEDIA_COVERAGE_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const deleteArticleData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "delete",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: MEDIA_COVERAGE_TABLE,
          id,
        },
      });
      if (response?.status === 200) {
        const { name } = response.data.deletedItem;
        dispatch(
          setCustomedNotificationMessage(
            `${name} has been successfuly removed!`
          )
        );
        dispatch({
          type: MEDIA_COVERAGE_REDUCER_TYPES.GET_MEDIA_COVERAGE_DATA,
          payload: response.data.updatedList,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const MediaCoverageReducerActions = {
  getMediaCoverageData,
  setMediaCoverageData,
  getSelectedArticleData,
  setSelectedArticleData,
  setNewArticleData,
  saveChanges,
  saveSelectedArticleChanges,
  saveNewArticle,
  deleteArticleData,
  setMediaCoverageNewPositions,
};
