import { useState } from "react";
import { useSelector } from "react-redux";
import { LocationsReducerActions } from "reducers/locationsReducer";
import KitContainer from "components/kit/KitContainer";
import LocationCard from "components/general/LocationCard/LocationCard";
import Grid from "@mui/material/Grid";

const KitLocations = () => {
  const data = useSelector((state) => state.locations.data);
  const { getLocationsData } = LocationsReducerActions;
  const [displayedItems, setDisplayedItems] = useState(20);

  return (
    <KitContainer
      header="Locations"
      selectedState="locations"
      getData={getLocationsData}
      dataLength={data.length}
      displayViewMoreButton={data.length > displayedItems}
      visibleItems={displayedItems}
      setDisplayedItems={setDisplayedItems}
    >
      <Grid container>
        {data.length
          ? data.map((location) => (
              <LocationCard
                header={location.location_name}
                streetAddress={location.adress_line_1}
                zip={location.zip_code}
                country={location.country}
              />
            ))
          : null}
      </Grid>
    </KitContainer>
  );
};

export default KitLocations;
