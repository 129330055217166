import { Card as MuiCard } from "@mui/material";
import { useStyles } from "./Card.styles";

const Card = ({ children, cardStyles, ...rest }) => {
  const { cardContainer } = useStyles(cardStyles);
  return (
    <MuiCard className={cardContainer} {...rest}>
      {children}
    </MuiCard>
  );
};

export default Card;
