import { useState } from "react";

import MESSAGES from "constants/Messages";

const useNotification = (customedMsg) => {
  const [display, setDisplay] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const notificationHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDisplay(false);
  };

  const displayError = (error = "default_error") => {
    setMessageType("error");
    setMessage(MESSAGES[error]);
    setDisplay(true);
  };

  const displaySuccess = (msg = "default_success") => {
    setMessageType("success");
    setMessage(customedMsg || MESSAGES[msg]);
    setDisplay(true);
  };

  return {
    displayError,
    displaySuccess,
    notificationHandler,
    messageType,
    notificationState: { display, message },
  };
};

export default useNotification;
