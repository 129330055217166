import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { MediaContactsReducerActions } from "reducers/mediaContactsReducer";
import ROUTES from "constants/Routes";

const EditMediaContact = () => {
  const formContent = {
    header: "Add new contact person",
    subheader:
      "Add the name and contact information for your main media contact(s)",
    route: "/contact",
    inputs: [
      {
        label: "Contact Name",
        fieldName: "name",
        required: true,
      },
      {
        label: "Contact Email",
        fieldName: "email",
        type: "email",
        required: true,
      },
      {
        label: "Contact Phone Number",
        fieldName: "phone",
      },

      {
        label: "Image",
        fieldName: "image",
        type: "file",
      },
    ],
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const mediaContactData = useSelector(
    (state) => state.mediaContacts.selectedMediaContact
  );
  const {
    getSelectedMediaContactData,
    setSelectedMediaContactData,
    saveSelectedMediaContactChanges,
  } = MediaContactsReducerActions;

  const setMediaContactData = (fieldName, value) => {
    dispatch(
      setSelectedMediaContactData({ ...mediaContactData, [fieldName]: value })
    );
  };

  const handleSubmit = () => {
    dispatch(saveSelectedMediaContactChanges(kit, mediaContactData));
  };

  useEffect(() => {
    if (kit) dispatch(getSelectedMediaContactData(kit, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={mediaContactData}
      setNewData={setMediaContactData}
      fileUpload={true}
      handleSubmit={handleSubmit}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.MEDIA_CONTACTS}`}
    />
  );
};

export default EditMediaContact;
