import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  cardHeader: (props) => ({
    color: "black",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "20px",
    letterSpacing: "-0.022em",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "55px",
    ...props,
  }),
  cardSubheader: (props) => ({
    color: "#A7A7A7",
    fontWeight: 400,
    fontSize: "16px",
    letterSpacing: "-0.022em",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...props,
  }),
  plainText: (props) => ({
    color: "#03010C",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "0.4px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...props,
  }),
  dateText: (props) => ({
    color: "#A7A7A7",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...props,
  }),
  mediaOutletText: (props) => ({
    color: "#03010C",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "50px",
    letterSpacing: "0.4px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...props,
  }),
  nameText: (props) => ({
    color: "#03010C",
    fontWeight: 600,
    fontSize: "14px",
    letterSpacing: "-0.032em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...props,
  }),
}));
