import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { TestimonialsReducerActions } from "reducers/testimonialsReducer";
import ROUTES from "constants/Routes";

const EditTestimonial = () => {
  const formContent = {
    header: "Edit Testimonial or Quote",
    inputs: [
      {
        label: "Paste the Testimonial or Quote",
        fieldName: "testimonial",
      },

      {
        label: "Name of Person",
        fieldName: "name",
        helperText: "Who gave you the testimonial. E.g. John Smith",
      },
      {
        label: "Title or Role",
        fieldName: "title",
        helperText:
          "Add a persons title or role (ex: Marketing Manager at SpaceX)",
      },
      {
        label: "Link",
        fieldName: "link",
        helperText:
          "Add a link to the person's social media profile (Twitter, Linkedin, etc) or an external link to the testimonial quote",
        type: "url",
      },
      {
        label: "Image",
        fieldName: "image",
        type: "file",
        helperText: "Image of the person",
      },
    ],
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const testimonialData = useSelector(
    (state) => state.testimonials.selectedTestimonial
  );
  const {
    getSelectedTestimonialData,
    setSelectedTestimonialData,
    saveSelectedTestimonialChanges,
  } = TestimonialsReducerActions;

  const setTestimonialData = (fieldName, value) => {
    dispatch(
      setSelectedTestimonialData({ ...testimonialData, [fieldName]: value })
    );
  };

  const handleSubmit = () => {
    dispatch(saveSelectedTestimonialChanges(kit, testimonialData));
  };

  useEffect(() => {
    if (kit) dispatch(getSelectedTestimonialData(kit, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={testimonialData}
      setNewData={setTestimonialData}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.TESTIMONIALS}`}
    />
  );
};

export default EditTestimonial;
