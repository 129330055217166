import React, { useState, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import { InvestorsReducerActions } from "reducers/investorsReducer";
import KitContainer from "components/kit/KitContainer";
import TeamMemberCard from "components/general/TeamMemberCard";

const KitInvestors = () => {
  const data = useSelector((state) => state.investors.data, shallowEqual);
  const { getInvestorsData } = InvestorsReducerActions;
  const visibleItems = 20;
  const [displayedItems, setDisplayedItems] = useState(visibleItems);

  const handleViewMore = useCallback(() => {
    setDisplayedItems(
      (prevDisplayedItems) => prevDisplayedItems + visibleItems
    );
  }, [visibleItems]);

  return (
    <KitContainer
      header="Investors"
      selectedState="investors"
      getData={getInvestorsData}
      dataLength={data.length}
      displayViewMoreButton={data.length > displayedItems}
      visibleItems={visibleItems}
      setDisplayedItems={handleViewMore}
    >
      <Grid container>
        {data.slice(0, displayedItems).map((member) => {
          const {
            id,
            name,
            title_role,
            email,
            image,
            twitter_url,
            linkedin_url,
            website_url,
            description,
          } = member;
          return (
            <TeamMemberCard
              key={id}
              name={name}
              title={title_role}
              email={email}
              picture={image}
              twitter={twitter_url}
              linkedIn={linkedin_url}
              website_url={website_url}
              description={description}
            />
          );
        })}
      </Grid>
    </KitContainer>
  );
};

KitInvestors.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      title_role: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      twitter_url: PropTypes.string,
      linkedin_url: PropTypes.string,
    })
  ),
};

export default React.memo(KitInvestors);
