const colors = {
  primary: "#4C92FF",
  secondary: "#DDE4FF",
  background: "#FBFBFA",
  tertiary: "#E2E2DF",
  transparent: "rgba(0,0,0,0)",
  white: "#ffffff",
  black: "#03010C",
  error: "#d32f2f",
  delete: "#B3261E",
  lightGray: "#D9D9D9",
  gray: "#A7A7A7",
  lightBlue: "#B0CAFB",
  avatarColor: "#808484",
  avatarBackground: "#EFEDE8",
  avatarBorder: "#B8B6B6",
};

export default colors;
