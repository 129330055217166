import { Link, useLocation } from "react-router-dom";
import { useStyles } from "./ErrorPage.styles";
import Button from "components/general/Button";
import { Typography } from "@mui/material";

const ErrorPage = () => {
  const { errorPageContainer, imageSize, contentContainer } = useStyles();
  const location = useLocation();
  const isSettingsPage = location.pathname.includes("settings");
  return (
    <div className={errorPageContainer}>
      <div className={contentContainer}>
        <img
          className={imageSize}
          src="/error_status/not_found.svg"
          alt="not found"
        />
        <Typography variant="h4" gutterBottom sx={{ whiteSpace: "pre-line" }}>
          {`Sorry, this page isn’t available.`}
        </Typography>
        {isSettingsPage ? (
          <Link to="/settings/home">
            <Button>Go back to pressdeck.co</Button>
          </Link>
        ) : (
          <Link to="/about">
            <Button>Go back to pressdeck.co</Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
