const Endpoints = {
  SECTION: "/section",
  LIST_ITEM: "/list-item",
  USER: "/user",
  TEAM_CONFIG: "/team/config",
  TEAM_EDIT: "/team/edit",
  ADVISORS_CONFIG: "/advisors/config",
  ADVISOR_EDIT: "/advisors/edit",
  INVESTORS_CONFIG: "/investors/config",
  INVESTORS_EDIT: "/investors/edit",
  MEDIA_COVERAGE_CONFIG: "/media-coverage/config",
  MEDIA_COVERAGE_EDIT: "/media-coverage/edit",
  PRESS_RELEASES_CONFIG: "/press-releases/config",
  PRESS_RELEASES_EDIT: "/press-releases/edit",
  AWARDS_CONFIG: "/awards/config",
  AWARDS_EDIT: "/awards/edit",
  TESTIMONIALS_CONFIG: "/testimonials/config",
  TESTIMONIALS_EDIT: "/testimonials/edit",
  IMAGES_CONFIG: "/images/config",
  IMAGES_EDIT: "/images/edit",
  VIDEOS_CONFIG: "/videos/config",
  VIDEOS_EDIT: "/videos/edit",
  ABOUT: "/about",
  LOCATION: "/location",
  LINKS: "/links",
  MEDIA_CONTACT: "/media-contact",
  LOGO_EDIT: "/logo/edit",
  DESIGN: "/design",
  PAYMENT: "/payment",
};

export default Endpoints;
