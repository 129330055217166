import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { AdvisorsReducerActions } from "reducers/advisorsReducer";
import ROUTES from "constants/Routes";

const AdvisorsNewMember = () => {
  const formContent = {
    header: "Add New Advisor",
    inputs: [
      {
        label: "Name",
        fieldName: "name",
        required: true,
      },
      {
        label: "Title/Role",
        fieldName: "title_role",
      },
      {
        label: "Email",
        fieldName: "email",
        type: "email",
      },
      {
        label: "LinkedIn URL",
        fieldName: "linkedin_url",
        type: "url",
      },
      {
        label: "Twitter URL",
        fieldName: "twitter_url",
        type: "url",
      },
      {
        label: "Image",
        fieldName: "image",
        type: "file",
      },
    ],
  };

  const dispatch = useDispatch();
  const { setNewAdvisorData, saveNewAdvisor } = AdvisorsReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const advisorData = useSelector((state) => state.advisors.newAdvisor);

  const setNewAdvisor = (fieldName, value) => {
    dispatch(setNewAdvisorData({ ...advisorData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveNewAdvisor(kit, advisorData));
  };

  return (
    <FormContainer
      formContent={formContent}
      data={advisorData}
      setNewData={setNewAdvisor}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.ADVISORS}`}
    />
  );
};

export default AdvisorsNewMember;
