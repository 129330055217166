import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import LoaderWithOverlay from "components/general/loaders/LoaderWithOverlay";

import ROUTES from "constants/Routes";

import { AuthReducerActions } from "reducers/authReducer";

const CustomRoute = ({ isPrivate, component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const storedUser = useSelector((state) => state.auth.user);
  const currentKit = useSelector((state) => state.auth.currentKit);
  const isInitialLoad = useSelector((state) => state.auth.isInitialLoad);

  const { getUser } = AuthReducerActions;

  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  const isSuccessRedirect = queryParams.get("isSuccessRedirect");

  useEffect(() => {
    if (isAuthenticated && isInitialLoad) {
      const { email } = user;
      dispatch(getUser(email, isSuccessRedirect));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isInitialLoad, dispatch]);

  if (isLoading || (isAuthenticated && !storedUser)) {
    return <LoaderWithOverlay />;
  }

  if (isPrivate) {
    if (!isAuthenticated) {
      return loginWithRedirect();
    }

    if (storedUser && !currentKit && pathname !== ROUTES.REGISTER) {
      return <Navigate replace to={ROUTES.REGISTER} />;
    }
  }

  return <Component {...rest} />;
};

export default CustomRoute;
