import { useState } from "react";
import { Box, Button, Avatar } from "@mui/material";
import { useIconsMap } from "hooks/useIconsMap";
import colors from "theme/colors";
import { imgToBase64 } from "util/helpers";

const FormFileUploadField = ({
  label,
  required = false,
  fieldName,
  avatar,
  retrieveInfo,
  value,
}) => {
  const iconsMap = useIconsMap({
    imageStyle: {
      width: avatar === "image" ? 340 : 75,
      background: avatar === "image" ? colors.avatarBackground : "transparent",
      borderRadius: "14px",
    },
  });

  const [image, setImage] = useState(value || iconsMap[avatar]);

  const onChange = async (e) => {
    if (e.target?.files?.[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      const encodedImg = await imgToBase64(e.target.files[0]);
      const formatedEncodedImg = encodedImg.replace(
        /^data:image\/\w+;base64,/,
        ""
      );
      retrieveInfo(fieldName, formatedEncodedImg);
    }
  };

  const sxObject = () => {
    const sx = {
      width: 158,
      height: 158,
      background: colors.avatarBackground,
      color: colors.avatarColor,
      border: `6px solid ${colors.avatarBorder}`,
    };

    if (avatar === "image") {
      sx.width = 285;
      sx.height = 192;
    }

    return sx;
  };

  const setVariant = () => (avatar === "image" ? "square" : "circular");

  return (
    <Box component="form" noValidate autoComplete="off">
      <div
        style={{
          minHeight: "160px",
          overflow: "hidden",
          paddingTop: "30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Avatar src={image} sx={sxObject()} variant={setVariant()}>
          {image}
        </Avatar>
      </div>
      <input
        id="fileid"
        type="file"
        name={fieldName}
        hidden
        onChange={onChange}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
        }}
      >
        <Button
          style={{
            width: "113px",
            height: "40px",
            borderRadius: "8px",
            padding: "5px 10px",
          }}
          variant="contained"
          onClick={() => document.getElementById("fileid").click()}
        >
          Choose File
        </Button>
      </div>
    </Box>
  );
};

export default FormFileUploadField;
