import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { TestimonialsReducerActions } from "reducers/testimonialsReducer";
import ROUTES from "constants/Routes";

const NewTestimonial = () => {
  const formContent = {
    header: "Add Testimonial or Quote",
    inputs: [
      {
        label: "Paste the Testimonial or Quote",
        fieldName: "testimonial",
      },

      {
        label: "Name of Person",
        fieldName: "name",
        helperText: "Who gave you the testimonial. E.g. John Smith",
      },
      {
        label: "Title or Role",
        fieldName: "title",
        helperText:
          "Add a persons title or role (ex: Marketing Manager at SpaceX)",
      },
      {
        label: "Link",
        fieldName: "link",
        helperText:
          "Add a link to the person's social media profile (Twitter, Linkedin, etc) or an external link to the testimonial quote",
        type: "url",
      },
      {
        label: "Image",
        fieldName: "image",
        type: "file",
        helperText: "Image of the person",
      },
    ],
  };

  const dispatch = useDispatch();
  const { setNewTestimonialData, saveNewTestimonial } =
    TestimonialsReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const testimonialData = useSelector(
    (state) => state.testimonials.newTestimonial
  );

  const setNewTestimonial = (fieldName, value) => {
    dispatch(setNewTestimonialData({ ...testimonialData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveNewTestimonial(kit, testimonialData));
  };

  return (
    <FormContainer
      formContent={formContent}
      data={testimonialData}
      setNewData={setNewTestimonial}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.TESTIMONIALS}`}
    />
  );
};

export default NewTestimonial;
