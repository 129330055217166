import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Theme from "theme";

import Box from "@mui/material/Box";

import Register from "containers/Register";
import Home from "containers/Home";
import Team from "containers/Team";
import Links from "containers/Links";
import MediaContacts from "containers/MediaContacts";
import NewMediaContact from "containers/MediaContacts/NewMediaContact";
import EditMediaContact from "containers/MediaContacts/EditMediaContact";
import About from "containers/About";
import Locations from "containers/Locations/Locations";
import NewLocation from "containers/Locations/NewLocation";
import EditLocation from "containers/Locations/EditLocation";
import Advisors from "containers/Advisors/Advisors";
import AdvisorsNewMember from "containers/Advisors/AdvisorsNewMember";
import AdvisorsEditMember from "containers/Advisors/AdvisorsEditMember";
import TeamNewMember from "containers/Team/TeamNewMember";
import TeamEditMember from "containers/Team/TeamEditMember";
import Investors from "containers/Investors/Investors";
import InvestorsNewMember from "containers/Investors/InvestorsNewMember";
import InvestorsEditMember from "containers/Investors/InvestorsEditMember";
import MediaCoverage from "containers/MediaCoverage";
import NewArticle from "containers/MediaCoverage/NewArticle";
import EditArticle from "containers/MediaCoverage/EditArticle";
import PressReleases from "containers/PressReleases";
import NewPressRelease from "containers/PressReleases/NewPressRelease";
import EditPressRelease from "containers/PressReleases/EditPressRelease";
import Awards from "containers/Awards";
import NewAward from "containers/Awards/NewAward";
import EditAward from "containers/Awards/EditAward";
import Testimonials from "containers/Testimonials";
import NewTestimonial from "containers/Testimonials/NewTestimonial";
import EditTestimonial from "containers/Testimonials/EditTestimonial";
import Logos from "containers/Logos";
import NewLogo from "containers/Logos/NewLogo";
import EditLogo from "containers/Logos/EditLogo";
import Images from "containers/Images";
import NewImage from "containers/Images/NewImage";
import EditImage from "containers/Images/EditImage";
import Videos from "containers/Videos";
import NewVideo from "containers/Videos/NewVideo";
import EditVideo from "containers/Videos/EditVideo";
import Design from "containers/Design";
import KitAbout from "containers/prKit/KitAbout/KitAbout";
import KitLogos from "containers/prKit/KitLogos";
import KitTestimonials from "containers/prKit/KitTestimonials";
import KitPressReleases from "containers/prKit/KitPressReleases";
import KitMediaCoverage from "containers/prKit/KitMediaCoverage/KitMediaCoverage";
import KitTeam from "containers/prKit/KitTeam";
import KitAdvisors from "containers/prKit/KitAdvisors/KitAdvisors";
import KitInvestors from "containers/prKit/KitInvestors/KitInvestors";
import KitLocations from "containers/prKit/KitLocations/KitLocations";
import KitMediaContacts from "containers/prKit/KitMediaContacts/KitMediaContacts";
import KitImages from "containers/prKit/KitImages";
import KitLinks from "containers/prKit/KitLinks";
import KitAwards from "containers/prKit/KitAwards";
import KitVideos from "containers/prKit/KitVideos/KitVideos";
import Settings from "containers/Settings";

import CustomRoute from "components/general/CustomRoute";
import LayoutResolver from "./LayoutResolver";

import { StyledEngineProvider } from "@mui/material";

import useSubdomain from "hooks/useSubdomain";
import useAxiosInterceptors from "hooks/useAxiosInterceptors";

import ROUTES from "constants/Routes";
import ErrorPage from "containers/ErrorPage";

const App = () => {
  useAxiosInterceptors();
  useSubdomain();

  const renderRoutes = () => (
    <Routes>
      <Route
        exact
        path={ROUTES.REGISTER}
        element={<CustomRoute component={Register} isPrivate />}
      />

      {/* PRIVATE SETTINGS ROUTES */}
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.HOME}`}
        element={<CustomRoute component={Home} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.ABOUT}`}
        element={<CustomRoute component={About} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.TEAM}`}
        element={<CustomRoute component={Team} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.TEAM_NEW_MEMBER}`}
        element={<CustomRoute component={TeamNewMember} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.TEAM_EDIT_MEMBER}/:id`}
        element={<CustomRoute component={TeamEditMember} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.ADVISORS}`}
        element={<CustomRoute component={Advisors} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.ADVISORS_NEW_MEMBER}`}
        element={<CustomRoute component={AdvisorsNewMember} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.ADVISORS_EDIT_MEMBER}/:id`}
        element={<CustomRoute component={AdvisorsEditMember} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.INVESTORS}`}
        element={<CustomRoute component={Investors} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.INVESTORS_NEW_MEMBER}`}
        element={<CustomRoute component={InvestorsNewMember} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.INVESTORS_EDIT_MEMBER}/:id`}
        element={<CustomRoute component={InvestorsEditMember} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.LOCATIONS}`}
        element={<CustomRoute component={Locations} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_LOCATION}`}
        element={<CustomRoute component={NewLocation} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_LOCATION}/:id`}
        element={<CustomRoute component={EditLocation} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.LINKS}`}
        element={<CustomRoute component={Links} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.MEDIA_CONTACTS}`}
        element={<CustomRoute component={MediaContacts} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_MEDIA_CONTACT}`}
        element={<CustomRoute component={NewMediaContact} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_MEDIA_CONTACT}/:id`}
        element={<CustomRoute component={EditMediaContact} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.MEDIA_COVERAGE}`}
        element={<CustomRoute component={MediaCoverage} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.MEDIA_COVERAGE_NEW_ARTICLE}`}
        element={<CustomRoute component={NewArticle} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.MEDIA_COVERAGE_EDIT_ARTICLE}/:id`}
        element={<CustomRoute component={EditArticle} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.PRESS_RELEASES}`}
        element={<CustomRoute component={PressReleases} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_PRESS_RELEASE}`}
        element={<CustomRoute component={NewPressRelease} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_PRESS_RELEASE}/:id`}
        element={<CustomRoute component={EditPressRelease} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.AWARDS}`}
        element={<CustomRoute component={Awards} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_AWARD}`}
        element={<CustomRoute component={NewAward} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_AWARD}/:id`}
        element={<CustomRoute component={EditAward} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.TESTIMONIALS}`}
        element={<CustomRoute component={Testimonials} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_TESTIMONIAL}`}
        element={<CustomRoute component={NewTestimonial} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_TESTIMONIAL}/:id`}
        element={<CustomRoute component={EditTestimonial} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.LOGOS}`}
        element={<CustomRoute component={Logos} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_LOGO}`}
        element={<CustomRoute component={NewLogo} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_LOGO}/:id`}
        element={<CustomRoute component={EditLogo} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.IMAGES}`}
        element={<CustomRoute component={Images} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_IMAGE}`}
        element={<CustomRoute component={NewImage} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_IMAGE}/:id`}
        element={<CustomRoute component={EditImage} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.VIDEOS}`}
        element={<CustomRoute component={Videos} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_VIDEO}`}
        element={<CustomRoute component={NewVideo} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_VIDEO}/:id`}
        element={<CustomRoute component={EditVideo} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.DESIGN}`}
        element={<CustomRoute component={Design} isPrivate />}
      />
      <Route
        exact
        path={`${ROUTES.SETTINGS_PREFIX}${ROUTES.ACCOUNT}`}
        element={<CustomRoute component={Settings} isPrivate />}
      />

      {/* PUBLIC LIVE ROUTES */}
      <Route
        exact
        path={ROUTES.ABOUT}
        element={<CustomRoute component={KitAbout} />}
      />
      <Route
        exact
        path={ROUTES.ADVISORS}
        element={<CustomRoute component={KitAdvisors} />}
      />
      <Route
        exact
        path={ROUTES.INVESTORS}
        element={<CustomRoute component={KitInvestors} />}
      />
      <Route
        exact
        path={ROUTES.LOCATIONS}
        element={<CustomRoute component={KitLocations} />}
      />
      <Route
        exact
        path={ROUTES.MEDIA_CONTACTS}
        element={<CustomRoute component={KitMediaContacts} />}
      />
      <Route
        exact
        path={ROUTES.LOGOS}
        element={<CustomRoute component={KitLogos} />}
      />
      <Route
        exact
        path={ROUTES.TESTIMONIALS}
        element={<CustomRoute component={KitTestimonials} />}
      />
      <Route
        exact
        path={ROUTES.PRESS_RELEASES}
        element={<CustomRoute component={KitPressReleases} />}
      />
      <Route
        exact
        path={`${ROUTES.PRESS_RELEASES}/:id`}
        element={<CustomRoute component={KitPressReleases} />}
      />
      <Route
        exact
        path={ROUTES.MEDIA_COVERAGE}
        element={<CustomRoute component={KitMediaCoverage} />}
      />
      <Route
        exact
        path={ROUTES.TEAM}
        element={<CustomRoute component={KitTeam} />}
      />
      <Route
        exact
        path={ROUTES.IMAGES}
        element={<CustomRoute component={KitImages} />}
      />
      <Route
        exact
        path={ROUTES.LINKS}
        element={<CustomRoute component={KitLinks} />}
      />
      <Route
        exact
        path={ROUTES.AWARDS}
        element={<CustomRoute component={KitAwards} />}
      />
      <Route
        exact
        path={ROUTES.VIDEOS}
        element={<CustomRoute component={KitVideos} />}
      />
      <Route path="/*" element={<CustomRoute component={ErrorPage} />} />
    </Routes>
  );

  return (
    <StyledEngineProvider injectFirst>
      <Theme>
        <Box sx={{ display: "flex" }}>
          <Router>
            <LayoutResolver>{renderRoutes()}</LayoutResolver>
          </Router>
        </Box>
      </Theme>
    </StyledEngineProvider>
  );
};

export default App;
