import React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { formatIfDate } from "util/dateFormat";
import { useStyles } from "./FormWithPosition.styles";

const textEllipsisStyle = { textOverflow: "ellipsis", overflow: "hidden" };

const Caption = React.memo(({ caption, url }) => {
  const { captionText } = useStyles();
  if (!caption) return null;

  return (
    <Box component="div" className={captionText} sx={textEllipsisStyle}>
      {url ? (
        <Link target="_blank" to={caption}>
          {caption}
        </Link>
      ) : (
        formatIfDate(caption)
      )}
    </Box>
  );
});

const ResolveLinkWrapper = ({ text, caption, url }) => {
  const { itemWrapper, itemText } = useStyles();

  const Item = (
    <div className={itemWrapper}>
      <Box component="div" className={itemText} sx={textEllipsisStyle}>
        {text}
      </Box>
      <Caption caption={caption} url={url} />
    </div>
  );

  if (url) {
    return (
      <Link target="_blank" to={url}>
        {Item}
      </Link>
    );
  }

  return Item;
};

ResolveLinkWrapper.propTypes = {
  text: PropTypes.string.isRequired,
  caption: PropTypes.string,
  url: PropTypes.string,
};

ResolveLinkWrapper.defaultProps = {
  caption: null,
  url: null,
};

export default ResolveLinkWrapper;
