import { useSelector } from "react-redux";

import Box from "@mui/material/Box";

import KitSideBar from "../KitSidebar/KitSidebar";
import Sidebar from "components/layout/Sidebar";

import { SIDEBAR_SECTIONS } from "constants/Sections";

import { useStyles } from "./CompleteLayout.styles";

const CompleteLayout = ({ children }) => {
  const { layoutMainContainer, layoutSectionContainer } = useStyles();

  const currentKit = useSelector((state) => state.auth.currentKit);
  const subdomain = useSelector((state) => state.global.subdomain);

  return (
    <Box component="main" className={layoutMainContainer}>
      {subdomain === "app" ? (
        !currentKit ? null : (
          <Sidebar
            sections={SIDEBAR_SECTIONS}
            logo="/logo_package/png/color_bgtransparent_v_print.png"
          />
        )
      ) : (
        <KitSideBar
          sections={SIDEBAR_SECTIONS}
          logo="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUqIkTZ3DGTflNhorrSJbM2ILvmotLJAIQFw&usqp=CAU"
        />
      )}

      <Box className={layoutSectionContainer}>{children}</Box>
    </Box>
  );
};

export default CompleteLayout;
