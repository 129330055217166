import { makeStyles } from "@material-ui/styles";
import colors from "theme/colors";

export const useStyle = makeStyles({
  headerContainer: {
    height: "100px",
    marginBottom: "0",
    padding: "20px 10px",
    display: "flex",
    alignItems: "center",
  },
  formHeader: {
    margin: "0 0 24px 0",
    fontWeight: "400",
    fontSize: "36px",
    lineHeight: "47px",
  },
  headerText: {
    textTransform: "uppercase",
  },
  section: {
    color: ({ hasGraySectionTitle }) =>
      hasGraySectionTitle ? "#A7A7A7" : colors.black,
    textTransform: "capitalize",
  },
  formSubheader: {
    marginTop: "0",
    marginBottom: "15px",
    color: "#867B7B",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "10px",
    letterSpacing: "-0.017em",
  },
});
