import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import BasicLayout from "components/layout/BasicLayout";
import CompleteLayout from "components/layout/CompleteLayout";

import ROUTES from "constants/Routes";

const LayoutResolver = ({ children }) => {
  const { pathname } = useLocation();

  const isLiveView =
    !pathname.includes(ROUTES.SETTINGS_PREFIX) && pathname !== ROUTES.REGISTER;

  const needsLayout = isLiveView || pathname !== ROUTES.REGISTER;

  const SelectedLayout = useMemo(
    () => (needsLayout ? CompleteLayout : BasicLayout),
    [needsLayout]
  );

  return (
    <SelectedLayout>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {children}
      </LocalizationProvider>
    </SelectedLayout>
  );
};

export default LayoutResolver;
