import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  locationCardContainer: {
    display: "flex",
  },
  locationCardUpperContainer: {
    display: "flex",
    width: "100%",
    height: "76px",
    paddingTop: "22px",
  },
  locationIconContainer: {
    display: "flex",
    justifyContent: "center",
    width: "84px",
    height: "100%",
  },
  locationHeaderContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "220px",
    height: "100%",
  },
  locationCardLowerContainer: {
    width: "100%",
    height: "86px",
    paddingTop: "8px",
    paddingLeft: "84px",
  },
}));
