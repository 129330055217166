import { makeStyles } from "@material-ui/styles";
import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  sidebarContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "285px",
    height: "100%",
    paddingLeft: "35px",
    background: (props) => props.background,
    "@global": {
      "*::-webkit-scrollbar": {
        width: "0.4em",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: colors.lightGray,
        borderRadius: "15px",
      },
    },
  },
  logoContainer: {
    width: "100%",
    height: "5%",
    padding: "30px 30px 50px 0",
  },
  headerLogo: {
    width: "80%",
  },
  sectionTitle: {
    color: "#A7A7A7",
    fontSize: "16px",
    lineHeight: "10px",
    marginBottom: "10px",
  },
  fixedButton: {
    width: "95%",
    color: "rgba(0, 0, 0, 0.8)",
    lineHeight: "10px",
    paddingLeft: "3px",
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "16px",
    "&:hover": {
      color: colors.primary,
      background: "transparent",
    },
  },
  sectionItemContainer: {
    marginBottom: "15px",
  },
  actionsContainer: {
    width: "100%",
    paddingTop: "20px",
    minHeight: "35%",
    overflow: "hidden",
  },
}));
