import { SUB_TO_MAIN_SECTIONS_MAP } from "../constants/Sections";

export function deepCopy(obj, hash = new WeakMap()) {
  // Return the original value if it's not an object or it's null
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // Clone Date objects by creating a new Date with the same time
  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  // Clone arrays by reducing over them and cloning each element recursively
  if (Array.isArray(obj)) {
    return obj.reduce((arr, item, i) => {
      arr[i] = deepCopy(item, hash);
      return arr;
    }, []);
  }

  // Clone Map instances by creating a new Map and cloning each key-value pair
  if (obj instanceof Map) {
    const result = new Map();
    obj.forEach((value, key) => {
      result.set(key, deepCopy(value, hash));
    });
    return result;
  }

  // Clone Set instances by creating a new Set and cloning each element
  if (obj instanceof Set) {
    const result = new Set();
    obj.forEach((value) => {
      result.add(deepCopy(value, hash));
    });
    return result;
  }

  // Handle circular references by returning previously cloned object reference
  if (hash.has(obj)) {
    return hash.get(obj);
  }

  // Create a clone of the object by preserving the prototype chain
  const cloneObj = Object.create(Object.getPrototypeOf(obj));
  hash.set(obj, cloneObj);

  // Recursively clone each property of the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      cloneObj[key] = deepCopy(obj[key], hash);
    }
  }
  return cloneObj;
}

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const setHoverEffect = (effects) => ({
  transition: "1s",
  "&:hover": {
    transform: "scale(1.1)",
    ...effects,
  },
});

export const setBoxShadow = () => ({
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
});

export const imgToBase64 = (img) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const setMembersOrderStr = (members, original = false) => {
  let order = "";
  members.forEach(
    (member) =>
      (order += `${original ? member.originalPosition : member.position}-${
        member.id
      };`)
  );
  return order;
};

export const setCurrentSection = () => {
  const section = window.location.pathname.replace("/settings/", "");
  return SUB_TO_MAIN_SECTIONS_MAP[section];
};

export const hexToRgb = (hex, options) => {
  const { opacity, cssFormat } = options || {};
  if (hex) {
    if (hex.indexOf("rgb") > -1) {
      return hex;
    }

    let h = hex.replace("#", "");
    h = h.match(new RegExp(`(.{${h.length / 3}})`, "g"));

    for (let i = 0; i < h.length; i += 1) {
      h[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16);
    }

    if (typeof opacity !== "undefined") {
      h.push(opacity);
    }

    return cssFormat ? `rgba(${h.join(",")})` : h;
  }
  return null;
};

export const isEmptyObject = (obj) => Object.keys(obj).length === 0;
