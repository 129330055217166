import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { AwardsReducerActions } from "reducers/awardsReducer";
import ROUTES from "constants/Routes";

const NewAward = () => {
  const formContent = {
    header: "Add Award",
    inputs: [
      {
        label: "Link",
        fieldName: "link",
      },
      {
        label: "Name of the Reward or Recognition",
        fieldName: "name",
      },
      {
        label: "Award or Recognition Date",
        fieldName: "date",
        type: "datepicker",
      },
      {
        label: "Award Logo",
        fieldName: "image",
        type: "file",
        avatar: "award",
      },
    ],
  };

  const dispatch = useDispatch();
  const { setNewAwardData, saveNewAward } = AwardsReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const advisorData = useSelector((state) => state.awards.newAward);

  const setNewAward = (fieldName, value) => {
    dispatch(setNewAwardData({ ...advisorData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveNewAward(kit, advisorData));
  };

  return (
    <FormContainer
      formContent={formContent}
      data={advisorData}
      setNewData={setNewAward}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.AWARDS}`}
    />
  );
};

export default NewAward;
