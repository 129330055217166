import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import FormActions from "components/form/FormActions";
import FormColorPickerField from "components/form/FormColorPickerField";

import { DesignReducerActions } from "reducers/designReducer";

import { useStyles } from "./DesignTab.styles";

const DesignTab = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { getDesignData, setDesignData, saveChanges } = DesignReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const loading = useSelector((state) => state.global.loading);
  const designData = useSelector((state) => state.design.data);
  const primaryColor = useSelector((state) => state.design.data.primary_color);
  const secondaryColor = useSelector(
    (state) => state.design.data.secondary_color
  );
  const tertiaryColor = useSelector(
    (state) => state.design.data.tertiary_color
  );
  const retrievedData = useSelector((state) => state.design.retrievedData);

  const setNewDesignData = (fieldName, value) => {
    dispatch(setDesignData({ ...designData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, designData));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getDesignData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    retrievedData && (
      <div className={classes.designTabContainer}>
        <div className={classes.titleRow}>Design</div>
        {/* Feature to be implemented later */}
        {/* <div className={classes.formRow}>
          <div className={classes.formTextContainer}>
            <span className={classes.formTitle}>COLORS</span>
            <span className={classes.descriptionText}>
              Add your brand colors to your live press kit.
            </span>
          </div>
          <div className={classes.colorInputsContainer}>
            <FormColorPickerField
              label="Primary Color"
              fieldName="primary_color"
              value={primaryColor}
              retrieveInfo={setNewDesignData}
            />
            <FormColorPickerField
              label="Secondary "
              fieldName="secondary_color"
              value={secondaryColor}
              retrieveInfo={setNewDesignData}
            />
            <FormColorPickerField
              label="Tertiary Color"
              fieldName="tertiary_color"
              value={tertiaryColor}
              retrieveInfo={setNewDesignData}
            />
          </div>
        </div> */}
        <div className={classes.formRow}>
          <div className={classes.formTextContainer}>
            <span className={classes.formTitle}>LOGO</span>
            <span className={classes.descriptionText}>
              Upload the company logo you want to appear on your press kit
            </span>
          </div>
        </div>
        <FormActions loading={loading} onSave={handleSubmit} />
      </div>
    )
  );
};

export default DesignTab;
