import { makeStyles } from "@material-ui/styles";
import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
  },
  addItemButton: {
    cursor: "pointer",
    display: "flex",
    padding: "0 24px",
    alignItems: "center",
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    color: colors.primary,
    letterSpacing: "-0.022em",
    lineHeight: "60px",
    fontSize: "1rem",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  iconContainer: {
    display: "flex",
    flexBasis: "7%",
    justifyContent: "center",
  },
  labelContainer: {
    display: "flex",
    flexBasis: "95%",
  },
  buttonContainer: {
    display: "flex",
    flex: "99%",
    marginBottom: "32px",
    justifyContent: "flex-end",
  },
  saveButton: {
    background: colors.secondary,
    color: colors.primary,
  },
  loadingContainer: {
    position: "relative",
    top: "20%",
  },
}));
