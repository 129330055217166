import { makeStyles } from "@material-ui/styles";

import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  colorPickerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "102px",
    marginRight: "44px",
  },
  colorContainer: {
    width: "100px",
    height: "100px",
    boxShadow: "0px 10px 60px 0px rgba(0, 0, 0, 0.10)",
    marginTop: "22px",
    marginBottom: "10px",
  },
  colorCodesText: {
    color: colors.gray,
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: "10px",
  },
  selectColorButton: {
    margin: "auto",
    marginTop: "5px",
    cursor: "pointer",
  },
  labelText: {
    color: `${colors.black}`,
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    textAlign: "center",
  },
  colorPicker: {
    position: "absolute",
    top: "25%",
  },
  colorBox: {
    height: "100%",
    borderRadius: "4px",
    background: (props) => (props.colorCode ? props.colorCode : "white"),
    cursor: "pointer",
  },
}));
