import Button from "@mui/material/Button";

const ViewMoreButton = ({ viewMore, handleViewMore }) => {
  const handleClick = () => {
    handleViewMore();
    if (viewMore) window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button variant="text" onClick={handleClick}>
        {viewMore ? "VIEW LESS" : "VIEW MORE"}
      </Button>
    </div>
  );
};

export default ViewMoreButton;
