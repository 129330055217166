import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  errorPageContainer: () => ({
    display: "flex",
    height: "85%",
    justifyContent: "center",
  }),
  imageSize: () => ({
    width: "85%",
  }),
  contentContainer: () => ({
    justifyContent: "center",
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
}));
