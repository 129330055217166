import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import RegistrationForm from "components/form/RegistrationForm";

import { useStyles } from "./Register.styles";

import ROUTES from "constants/Routes";

const Register = () => {
  const storedUser = useSelector((state) => state.auth.user);
  const kit = useSelector((state) => state.auth.kit);

  const { registrationContainer, registrationCol, logoContainer, headerLogo } =
    useStyles();

  return storedUser && kit ? (
    <Navigate replace to={ROUTES.HOME} />
  ) : (
    <div className={registrationContainer}>
      <div className={logoContainer}>
        <img
          className={headerLogo}
          src="/logo_package/png/color_bgtransparent_h_screen.png"
          alt="Press Deck Logo"
        />
      </div>
      <div className={registrationCol}>
        <RegistrationForm />
      </div>
    </div>
  );
};

export default Register;
