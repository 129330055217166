import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";
import { setMembersOrderStr } from "util/helpers";
import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage, setCustomedNotificationMessage } = GlobalReducerActions;
const { SECTION, LIST_ITEM } = Endpoints;
const { TEAM_TABLE } = Tables;

const NEW_TEAM_MEMBER = {
  name: "",
  title_role: "",
  email: "",
  linkedin_url: "",
  twitter_url: "",
  image: "",
};

const TEAM_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: [],
  selectedTeamMember: {},
  originalPositions: '',
  newPositions: '',
  newTeamMember: { ...NEW_TEAM_MEMBER },
};

export const TEAM_REDUCER_TYPES = {
  GET_TEAM_DATA: "GET_TEAM_DATA",
  SET_TEAM_DATA: "SET_TEAM_DATA",
  GET_SELECTED_TEAM_MEMBER_DATA: "GET_SELECTED_TEAM_MEMBER_DATA",
  SET_SELECTED_TEAM_MEMBER_DATA: "SET_SELECTED_TEAM_MEMBER_DATA",
  SET_TEAM_RETRIEVED_DATA: "SET_TEAM_RETRIEVED_DATA",
  SET_NEW_TEAM_MEMBER_DATA: "SET_NEW_TEAM_MEMBER_DATA",
  SET_TEAM_MEMBERS_NEW_POSITIONS_DATA: "SET_TEAM_MEMBERS_NEW_POSITIONS_DATA",
};

export const teamReducer = (state = TEAM_REDUCER_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case TEAM_REDUCER_TYPES.GET_TEAM_DATA:
      const updatedPayload = [...payload];
      updatedPayload.forEach(item => {
        if (!item.originalPosition) {
          item.originalPosition = item.position
        }
      });
      return {
        ...state,
        data: updatedPayload,
        originalPositions: setMembersOrderStr(updatedPayload, true),
        newPositions: setMembersOrderStr(updatedPayload, true)
      };
    case TEAM_REDUCER_TYPES.SET_TEAM_DATA:
      return {
        ...state,
        data: payload,
      };
    case TEAM_REDUCER_TYPES.SET_TEAM_MEMBERS_NEW_POSITIONS_DATA:
      return {
        ...state,
        newPositions: payload,
      };
    case TEAM_REDUCER_TYPES.GET_SELECTED_TEAM_MEMBER_DATA:
      return {
        ...state,
        selectedTeamMember: payload,
      };
    case TEAM_REDUCER_TYPES.SET_SELECTED_TEAM_MEMBER_DATA:
      return {
        ...state,
        selectedTeamMember: payload,
      };
    case TEAM_REDUCER_TYPES.SET_NEW_TEAM_MEMBER_DATA:
      return {
        ...state,
        newTeamMember: payload,
      };
    case TEAM_REDUCER_TYPES.SET_TEAM_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getTeamData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${TEAM_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: TEAM_REDUCER_TYPES.GET_TEAM_DATA,
          payload: response.data.item,
        });
      };
    }

    catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: TEAM_REDUCER_TYPES.SET_TEAM_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setTeamMembersNewPositions = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TEAM_REDUCER_TYPES.SET_TEAM_MEMBERS_NEW_POSITIONS_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const getSelectedTeamMemberData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${LIST_ITEM}/${kit}/${TEAM_TABLE}/${id}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: TEAM_REDUCER_TYPES.GET_SELECTED_TEAM_MEMBER_DATA,
          payload: response.data.data,
        });
        
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: TEAM_REDUCER_TYPES.SET_TEAM_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setTeamData = (payload) => {
  const sortedPositions = payload.sort((a, b) => a.position - b.position);
  return async (dispatch) => {
    try {
      dispatch({
        type: TEAM_REDUCER_TYPES.SET_TEAM_DATA,
        payload: sortedPositions,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setSelectedTeamMemberData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TEAM_REDUCER_TYPES.SET_SELECTED_TEAM_MEMBER_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setNewTeamMemberData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TEAM_REDUCER_TYPES.SET_NEW_TEAM_MEMBER_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  const data = [...newData];
  data.forEach(item => delete item.originalPosition);
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: TEAM_TABLE,
          item: data,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: TEAM_REDUCER_TYPES.GET_TEAM_DATA,
          payload: response.data.item,
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const saveSelectedTeamMemberChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "put",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: TEAM_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: TEAM_REDUCER_TYPES.GET_SELECTED_TEAM_MEMBER_DATA,
          payload: response.data,
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: TEAM_REDUCER_TYPES.SET_TEAM_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const saveNewTeamMember = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: TEAM_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: TEAM_REDUCER_TYPES.GET_TEAM_DATA,
          payload: response.data.item,
        });
        dispatch({
          type: TEAM_REDUCER_TYPES.SET_NEW_TEAM_MEMBER_DATA,
          payload: { ...NEW_TEAM_MEMBER },
        });
        dispatch(setCustomedNotificationMessage(`${newData.name} has been successfully added!`));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: TEAM_REDUCER_TYPES.SET_TEAM_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const deleteTeamMemberData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "delete",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: TEAM_TABLE,
          id,
        },
      });
      if (response?.status === 200) {
        const { name } = response.data.deletedItem;
        dispatch(setCustomedNotificationMessage(`${name} has been successfuly removed!`));
        dispatch({
          type: TEAM_REDUCER_TYPES.GET_TEAM_DATA,
          payload: response.data.updatedList,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const TeamReducerActions = {
  getTeamData,
  setTeamData,
  getSelectedTeamMemberData,
  setSelectedTeamMemberData,
  setNewTeamMemberData,
  saveChanges,
  saveSelectedTeamMemberChanges,
  saveNewTeamMember,
  deleteTeamMemberData,
  setTeamMembersNewPositions
};
