import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { MediaCoverageReducerActions } from "reducers/mediaCoverageReducer";
import ROUTES from "constants/Routes";

const EditArticle = () => {
  const formContent = {
    header: "Edit Coverage",
    inputs: [
      {
        label: "Publication Name",
        fieldName: "name",
        required: true,
      },
      {
        label: "Publication Logo",
        fieldName: "image",
        type: "file",
        avatar: "image",
      },
      {
        label: "Coverage Date",
        fieldName: "date",
        type: "datepicker",
      },
      {
        label: "Title or Headline",
        fieldName: "title",
      },

      {
        label: "Link",
        fieldName: "link",
        type: "url",
      },

      {
        label: "Excerpt",
        fieldName: "excerpt",
        helperText: "Add a short excerpt or quote from the coverage",
      },
    ],
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const articleData = useSelector(
    (state) => state.mediaCoverage.selectedArticle
  );
  const {
    getSelectedArticleData,
    setSelectedArticleData,
    saveSelectedArticleChanges,
  } = MediaCoverageReducerActions;

  const setArticleData = (fieldName, value) => {
    dispatch(setSelectedArticleData({ ...articleData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveSelectedArticleChanges(kit, articleData));
  };

  useEffect(() => {
    if (kit) dispatch(getSelectedArticleData(kit, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={articleData}
      setNewData={setArticleData}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.MEDIA_COVERAGE}`}
    />
  );
};

export default EditArticle;
