import Box from "@mui/material/Box";

import { useStyles } from "./TabPanel.styles";

const TabPanel = ({ children, activeTabId, tabId, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.tabPanelContainer}
      role="tabpanel"
      hidden={activeTabId !== tabId}
      id={`simple-tabpanel-${tabId}`}
      aria-labelledby={`simple-tab-${tabId}`}
      {...rest}
    >
      {activeTabId === tabId && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
