import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { PressReleasesReducerActions } from "reducers/pressReleasesReducer";
import { setMembersOrderStr } from "util/helpers";

import ROUTES from "constants/Routes";

const PressReleases = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const pressReleasesData = useSelector((state) => state.pressReleases.data);
  const retrievedData = useSelector(
    (state) => state.pressReleases.retrievedData
  );
  const originalPositions = useSelector(
    (state) => state.pressReleases.originalPositions
  );
  const newPositions = useSelector((state) => state.pressReleases.newPositions);
  const {
    getPressReleasesData,
    setPressReleasesData,
    setPressReleasesNewPositions,
    saveChanges,
    deletePressReleaseData,
  } = PressReleasesReducerActions;

  const setNewData = (data) => {
    dispatch(setPressReleasesData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, pressReleasesData));
  };

  const deletePressRelease = (id) => {
    dispatch(deletePressReleaseData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getPressReleasesData(kit));
    if (kit && retrievedData && !pressReleasesData.length)
      dispatch(getPressReleasesData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(
      setPressReleasesNewPositions(setMembersOrderStr(pressReleasesData))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressReleasesData]);

  return (
    <FormListContainer
      header="Add or Edit Press Releases"
      subheader="Add press releases about your product or business"
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_PRESS_RELEASE}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_PRESS_RELEASE}`}
      redirectLabel={"Press Release"}
      data={pressReleasesData}
      fieldForCaptionText="date"
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deletePressRelease}
      itemLabel="title"
      avatar="media"
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default PressReleases;
