import Endpoints from "constants/Endpoints";
import axiosInstance from "services/axiosInstance";

import { GlobalReducerActions } from "reducers/globalReducer";
const { setSubdomain } = GlobalReducerActions;

const AUTH_INITIAL_STATE = {
  isInitialLoad: true,
  user: null,
  role: null,
  currentKit: null,
  errorCode: null,
};

export const AUTH_REDUCER_TYPES = {
  SET_INITIAL_LOAD: "SET_INITIAL_LOAD",
  SET_USER: "SET_USER",
  SET_ROLE: "SET_ROLE",
  SET_ERROR: "SET_ERROR",
};

export const authReducer = (state = AUTH_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_REDUCER_TYPES.SET_INITIAL_LOAD:
      return {
        ...state,
        isInitialLoad: payload,
      };
    case AUTH_REDUCER_TYPES.SET_USER:
      const { isInitialLoad, user, role, currentKit } = payload;
      return {
        ...state,
        isInitialLoad,
        user,
        role,
        currentKit,
      };
    case AUTH_REDUCER_TYPES.SET_ROLE:
      return {
        ...state,
        role: payload,
      };
    case AUTH_REDUCER_TYPES.SET_ERROR:
      return {
        ...state,
        errorCode: payload,
      };
    default:
      return state;
  }
};

const setUserRole = (role) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: AUTH_REDUCER_TYPES.SET_ROLE,
        payload: role,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setInitialLoad = (newState) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: AUTH_REDUCER_TYPES.SET_INITIAL_LOAD,
        payload: newState,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setUser = (newState) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: AUTH_REDUCER_TYPES.SET_USER,
        payload: newState,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const getUser = (email, isSuccessRedirect) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance({
        method: "get",
        url: `${Endpoints.USER}/${email}`,
        //cancelToken,
      });

      let resolvedSubdomain = response?.data?.subdomain || null;

      // This flag identifies the first landing after the user pays a subscription in Stripe.
      // In this case, we use polling with a 2-second backoff each time to wait for the subscription confirmation.
      if (isSuccessRedirect) {
        let attempts = 0;
        const maxAttempts = 10;
        const pollingInterval = 2000;

        while (!resolvedSubdomain && attempts < maxAttempts) {
          const pollingResponse = await axiosInstance({
            method: "get",
            url: `${Endpoints.USER}/${email}`,
            //cancelToken,
          });

          if (pollingResponse?.data?.subdomain) {
            const { email: user, role, subdomain } = pollingResponse.data;

            return dispatch(
              setUser({
                user,
                role,
                currentKit: subdomain,
                isInitialLoad: false,
              })
            );
          } else {
            attempts += 1;
            await new Promise((resolve) =>
              setTimeout(resolve, pollingInterval * attempts)
            );
          }
        }
      }

      if (response?.status === 200) {
        const { email: user, role, subdomain, kits } = response.data;

        dispatch(
          setUser({
            user,
            role,
            currentKit: subdomain || null,
            isInitialLoad: false,
          })
        );
      } else {
        //to be replaced with endpoint error code
        dispatch({
          type: AUTH_REDUCER_TYPES.SET_ERROR,
          payload: "default_error",
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: AUTH_REDUCER_TYPES.SET_ERROR,
        payload: "default_error",
      });
    }
  };
};

export const AuthReducerActions = {
  getUser,
  setUserRole,
  setInitialLoad,
};
