import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { ImagesReducerActions } from "reducers/imagesReducer";
import ROUTES from "constants/Routes";

const EditImage = () => {
  const formContent = {
    header: "Edit Image",
    inputs: [
      {
        label: "File",
        fieldName: "image",
        type: "file",
        required: true,
        avatar: "image",
      },
      {
        label: "Image description",
        fieldName: "description",
        helperText: "Add a short description of this image",
      },
    ],
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const imageData = useSelector((state) => state.images.selectedImage);
  const {
    getSelectedImageData,
    setSelectedImageData,
    saveSelectedImageChanges,
  } = ImagesReducerActions;

  const setImageData = (fieldName, value) => {
    dispatch(setSelectedImageData({ ...imageData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveSelectedImageChanges(kit, imageData));
  };

  useEffect(() => {
    if (kit) dispatch(getSelectedImageData(kit, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={imageData}
      setNewData={setImageData}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.IMAGES}`}
    />
  );
};

export default EditImage;
