import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { useStyles } from "./LoaderStatic.styles";

const LoaderStatic = () => {
  const { loaderContainer, loader } = useStyles();
  return (
    <div className={loaderContainer}>
      <CircularProgress size="80" className={loader} />
    </div>
  );
};

export default LoaderStatic;
