import { makeStyles } from "@material-ui/styles";

import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  designTabContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  titleRow: {
    marginBottom: "15px",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "28px",
    textAlign: "left",
    color: `${colors.black}`,
  },
  formRow: {
    height: "50%",
    display: "flex",
    flexDirection: "row",
  },
  formTextContainer: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
  },
  formTitle: {
    color: `${colors.primary}`,
    fontFamily: "Roboto",
    fontSize: "15px",
    marginBottom: "5px",
  },
  descriptionText: {
    color: `${colors.gray}`,
    fontFamily: "Roboto",
    fontSize: "12px",
    maxWidth: "150px",
  },
  colorInputsContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    "&:first-child": {
      marginRight: "44px",
    },
    "&:last-child": {
      marginLeft: "44px",
    },
  },
}));
