import { makeStyles } from "@material-ui/styles";
import { setHoverEffect } from "util/helpers";

export const useStyles = makeStyles(() => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    padding: "1rem",
    width: "304px",
    height: "14rem",
    paddingLeft: "34px",
    cursor: "pointer",
    ...setHoverEffect(),
  },
}));
