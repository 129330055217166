import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { MediaCoverageReducerActions } from "reducers/mediaCoverageReducer";
import ROUTES from "constants/Routes";

const NewArticle = () => {
  const formContent = {
    header: "Add Coverage",
    inputs: [
      {
        label: "Publication Name",
        fieldName: "name",
        required: true,
      },
      {
        label: "Publication Logo",
        fieldName: "image",
        type: "file",
      },
      {
        label: "Coverage Date",
        fieldName: "date",
        type: "datepicker",
      },
      {
        label: "Title or Headline",
        fieldName: "title",
      },

      {
        label: "Link",
        fieldName: "link",
        type: "url",
      },

      {
        label: "Excerpt",
        fieldName: "excerpt",
        helperText: "Add a short excerpt or quote from the coverage",
      },
    ],
  };

  const dispatch = useDispatch();
  const { setNewArticleData, saveNewArticle } = MediaCoverageReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const articleData = useSelector((state) => state.mediaCoverage.newArticle);

  const setNewArticle = (fieldName, value) => {
    dispatch(setNewArticleData({ ...articleData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveNewArticle(kit, articleData));
  };

  return (
    <FormContainer
      formContent={formContent}
      data={articleData}
      setNewData={setNewArticle}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.MEDIA_COVERAGE}`}
    />
  );
};

export default NewArticle;
