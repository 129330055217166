import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { AwardsReducerActions } from "reducers/awardsReducer";
import ROUTES from "constants/Routes";

const EditAward = () => {
  const formContent = {
    header: "Edit Award",
    inputs: [
      {
        label: "Link",
        fieldName: "link",
      },
      {
        label: "Name of the Reward or Recognition",
        fieldName: "name",
      },
      {
        label: "Award or Recognition Date",
        fieldName: "date",
        type: "datepicker",
      },
      {
        label: "Award Logo",
        fieldName: "image",
        type: "file",
        avatar: "award",
      },
    ],
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const awardData = useSelector((state) => state.awards.selectedAward);
  const {
    getSelectedAwardData,
    setSelectedAwardData,
    saveSelectedAwardChanges,
  } = AwardsReducerActions;

  const setAwardData = (fieldName, value) => {
    dispatch(setSelectedAwardData({ ...awardData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveSelectedAwardChanges(kit, awardData));
  };

  useEffect(() => {
    if (kit) dispatch(getSelectedAwardData(kit, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={awardData}
      setNewData={setAwardData}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.AWARDS}`}
    />
  );
};

export default EditAward;
