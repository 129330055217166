import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import ConfirmationModal from "components/general/ConfirmationModal/ConfirmationModal";
import Notification from "components/general/Notification";
import useNotification from "hooks/useNotification";

import { GlobalReducerActions } from "reducers/globalReducer";

import { useStyles } from "./FormWithPosition.styles";
import ItemRow from "./ItemRow";

const FormWithPosition = ({
  items,
  handleNewPosition,
  editPath,
  deleteItem,
  itemLabel,
  avatar,
  fieldForCaptionText,
}) => {
  const { formWithPositionContainer } = useStyles();
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const { setNotificationMessage } = GlobalReducerActions;
  const {
    displaySuccess,
    displayError,
    notificationHandler,
    notificationState,
    messageType,
  } = useNotification();
  const notificationMessage = useSelector(
    (state) => state.global.notificationMessage
  );

  useEffect(() => {
    return () => {
      resetNotification();
    };
  }, []);

  useEffect(() => {
    if (notificationMessage) {
      if (notificationMessage.toLowerCase().includes("success"))
        displaySuccess(notificationMessage);
      if (notificationMessage.toLowerCase().includes("wrong")) displayError();
    }
  }, [notificationMessage]);

  const resetNotification = () => {
    dispatch(setNotificationMessage(""));
    notificationHandler();
  };

  return (
    <div className={formWithPositionContainer}>
      {items?.length
        ? items.map((item) => (
            <ItemRow
              key={item.id}
              item={item}
              avatar={avatar}
              itemLabel={itemLabel}
              fieldForCaptionText={fieldForCaptionText}
              editPath={editPath}
              handleNewPosition={handleNewPosition}
              setDeleteId={setDeleteId}
              itemLength={items.length}
            />
          ))
        : null}
      <Notification
        handler={resetNotification}
        notificationState={notificationState}
        vertical="bottom"
        type={messageType}
      />
      <ConfirmationModal
        open={deleteId}
        handleClose={() => setDeleteId(null)}
        handleClick={() => {
          deleteItem(deleteId);
          setDeleteId(null);
        }}
      />
    </div>
  );
};

export default FormWithPosition;
