import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { LocationsReducerActions } from "reducers/locationsReducer";
import { setMembersOrderStr } from "util/helpers";
import ROUTES from "constants/Routes";

const Locations = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const locationsData = useSelector((state) => state.locations.data);
  const retrievedData = useSelector((state) => state.locations.retrievedData);
  const originalPositions = useSelector(
    (state) => state.locations.originalPositions
  );
  const newPositions = useSelector((state) => state.locations.newPositions);
  const {
    getLocationsData,
    setLocationsData,
    saveChanges,
    deleteLocationData,
    setLocationsNewPositions,
  } = LocationsReducerActions;

  const setNewData = (data) => {
    dispatch(setLocationsData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, locationsData));
  };

  const deleteLocation = (id) => {
    dispatch(deleteLocationData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getLocationsData(kit));
    if (kit && retrievedData && !locationsData.length)
      dispatch(getLocationsData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(setLocationsNewPositions(setMembersOrderStr(locationsData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsData]);

  return (
    <FormListContainer
      header="Add Locations"
      subheader="Add names, descriptions, and images/logos of your locations"
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_LOCATION}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_LOCATION}`}
      redirectLabel={"location"}
      data={locationsData}
      fieldForCaptionText="adress_line_1"
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      itemLabel="location_name"
      avatar="location"
      deleteItem={deleteLocation}
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default Locations;
