import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { useStyles } from "./TabSwitch.styles";

const a11yProps = (id) => {
  return {
    id: `simple-tab-${id}`,
    "aria-controls": `simple-tabpanel-${id}`,
  };
};

const TabSwitch = ({ activeTabId, tabsConfig, handleClick }) => {
  const classes = useStyles();

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs value={activeTabId} aria-label="tabs component">
          {tabsConfig.map(({ id, label }) => (
            <Tab
              className={classes.tab}
              onClick={() => handleClick(id)}
              value={id}
              key={id}
              label={label}
              {...a11yProps(id)}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default TabSwitch;
