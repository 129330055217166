import { Link } from "react-router-dom";
import Button from "components/general/Button";
import { useStyles } from "./RedirectButton.styles";

const RedirectButton = ({ path, handleClick }) => {
  const { redirectButtonContainer, redirectButtonText, redirectButton } =
    useStyles();
  return (
    <div className={redirectButtonContainer}>
      <Link className={redirectButtonText} to={path}>
        <Button
          color="primary"
          className={redirectButton}
          onClick={handleClick}
        >
          <img src="/button_icons/arrow_back.png" alt="go back icon" />
        </Button>
      </Link>
    </div>
  );
};

export default RedirectButton;
