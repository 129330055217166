import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";
import { setMembersOrderStr } from "util/helpers";
import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage, setCustomedNotificationMessage } = GlobalReducerActions;
const { SECTION, LIST_ITEM } = Endpoints;
const { LOCATIONS_TABLE } = Tables;

const NEW_LOCATION = {
  location_name: "",
  adress_line_1: "",
  adress_line_2: "",
  city: "",
  country: "",
  state: "",
  zip_code: "",
};

const LOCATIONS_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: [],
  selectedLocation: {},
  originalPositions: '',
  newPositions: '',
  newLocation: { ...NEW_LOCATION },
};

export const LOCATIONS_REDUCER_TYPES = {
  GET_LOCATIONS_DATA: "GET_LOCATIONS_DATA",
  SET_LOCATIONS_DATA: "SET_LOCATIONS_DATA",
  GET_SELECTED_LOCATION_DATA: "GET_SELECTED_LOCATION_DATA",
  SET_SELECTED_LOCATION_DATA: "SET_SELECTED_LOCATION_DATA",
  SET_LOCATIONS_RETRIEVED_DATA: "SET_LOCATIONS_RETRIEVED_DATA",
  SET_NEW_LOCATION_DATA: "SET_NEW_LOCATION_DATA",
  SET_LOCATIONS_NEW_POSITIONS_DATA: "SET_LOCATIONS_NEW_POSITIONS_DATA",
};

export const locationsReducer = (
  state = LOCATIONS_REDUCER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case LOCATIONS_REDUCER_TYPES.GET_LOCATIONS_DATA:
      const updatedPayload = [...payload];
      updatedPayload.forEach(item => {
        if (!item.originalPosition) {
          item.originalPosition = item.position
        }
      });
      return {
        ...state,
        data: updatedPayload,
        originalPositions: setMembersOrderStr(updatedPayload, true),
        newPositions: setMembersOrderStr(updatedPayload, true)
      };
    case LOCATIONS_REDUCER_TYPES.SET_LOCATIONS_DATA:
      return {
        ...state,
        data: payload,
      };
    case LOCATIONS_REDUCER_TYPES.SET_LOCATIONS_NEW_POSITIONS_DATA:
      return {
        ...state,
        newPositions: payload,
      };
    case LOCATIONS_REDUCER_TYPES.GET_SELECTED_LOCATION_DATA:
      return {
        ...state,
        selectedLocation: payload,
      };
    case LOCATIONS_REDUCER_TYPES.SET_SELECTED_LOCATION_DATA:
      return {
        ...state,
        selectedLocation: payload,
      };
    case LOCATIONS_REDUCER_TYPES.SET_NEW_LOCATION_DATA:
      return {
        ...state,
        newLocation: payload,
      };
    case LOCATIONS_REDUCER_TYPES.SET_LOCATIONS_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getLocationsData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${LOCATIONS_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: LOCATIONS_REDUCER_TYPES.GET_LOCATIONS_DATA,
          payload: response.data.item,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: LOCATIONS_REDUCER_TYPES.SET_LOCATIONS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const getSelectedLocationData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${LIST_ITEM}/${kit}/${LOCATIONS_TABLE}/${id}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: LOCATIONS_REDUCER_TYPES.GET_SELECTED_LOCATION_DATA,
          payload: response.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: LOCATIONS_REDUCER_TYPES.SET_LOCATIONS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setLocationsData = (payload) => {
  const sortedPositions = payload.sort((a, b) => a.position - b.position);
  return async (dispatch) => {
    try {
      dispatch({
        type: LOCATIONS_REDUCER_TYPES.SET_LOCATIONS_DATA,
        payload: sortedPositions,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setLocationsNewPositions = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOCATIONS_REDUCER_TYPES.SET_LOCATIONS_NEW_POSITIONS_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setSelectedLocationData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOCATIONS_REDUCER_TYPES.SET_SELECTED_LOCATION_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setNewLocationData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOCATIONS_REDUCER_TYPES.SET_NEW_LOCATION_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  const data = [...newData];
  data.forEach(item => delete item.originalPosition);
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: LOCATIONS_TABLE,
          item: data,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: LOCATIONS_REDUCER_TYPES.GET_LOCATIONS_DATA,
          payload: response.data.item,
        });
        dispatch(setNotificationMessage('default_success'));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveSelectedLocationChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "put",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: LOCATIONS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: LOCATIONS_REDUCER_TYPES.GET_SELECTED_LOCATION_DATA,
          payload: response.data,
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: LOCATIONS_REDUCER_TYPES.SET_LOCATIONS_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const saveNewLocation = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: LOCATIONS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: LOCATIONS_REDUCER_TYPES.GET_LOCATIONS_DATA,
          payload: response.data.item,
        });
        dispatch({
          type: LOCATIONS_REDUCER_TYPES.SET_NEW_LOCATION_DATA,
          payload: { ...NEW_LOCATION },
        });
        dispatch(setCustomedNotificationMessage(`${newData.location_name} has been successfully added!`));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: LOCATIONS_REDUCER_TYPES.SET_LOCATIONS_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const deleteLocationData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "delete",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: LOCATIONS_TABLE,
          id,
        },
      });
      if (response?.status === 200) {
        const { location_name } = response.data.deletedItem;
        dispatch(setCustomedNotificationMessage(`${location_name} has been successfuly removed!`));
        dispatch({
          type: LOCATIONS_REDUCER_TYPES.GET_LOCATIONS_DATA,
          payload: response.data.updatedList,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const LocationsReducerActions = {
  getLocationsData,
  setLocationsData,
  getSelectedLocationData,
  setSelectedLocationData,
  setNewLocationData,
  saveChanges,
  saveSelectedLocationChanges,
  saveNewLocation,
  deleteLocationData,
  setLocationsNewPositions,
};
