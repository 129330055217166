import React, { useState, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import { AdvisorsReducerActions } from "reducers/advisorsReducer";
import KitContainer from "components/kit/KitContainer";
import TeamMemberCard from "components/general/TeamMemberCard";

const KitAdvisors = () => {
  const data = useSelector((state) => state.advisors.data, shallowEqual);
  const { getAdvisorsData } = AdvisorsReducerActions;
  const visibleItems = 20;
  const [displayedItems, setDisplayedItems] = useState(visibleItems);

  const handleViewMore = useCallback(() => {
    setDisplayedItems(
      (prevDisplayedItems) => prevDisplayedItems + visibleItems
    );
  }, [visibleItems]);

  return (
    <KitContainer
      header="Advisors"
      selectedState="advisors"
      getData={getAdvisorsData}
      dataLength={data.length}
      displayViewMoreButton={data.length > displayedItems}
      visibleItems={visibleItems}
      setDisplayedItems={handleViewMore}
    >
      <Grid container>
        {data.slice(0, displayedItems).map((member) => {
          const {
            id,
            name,
            title_role,
            email,
            image,
            twitter_url,
            linkedin_url,
          } = member;
          return (
            <TeamMemberCard
              key={id}
              name={name}
              title={title_role}
              email={email}
              picture={image}
              twitter={twitter_url}
              linkedIn={linkedin_url}
            />
          );
        })}
      </Grid>
    </KitContainer>
  );
};

KitAdvisors.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      title_role: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      twitter_url: PropTypes.string,
      linkedin_url: PropTypes.string,
    })
  ),
};

export default React.memo(KitAdvisors);
