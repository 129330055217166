import { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import SectionHeader from "components/general/SectionHeader";
import FormWithPosition from "components/form/FormWithPosition";
import Notification from "components/general/Notification";
import LoaderStatic from "components/general/loaders/Loader/LoaderStatic";
import Button from "@mui/material/Button";
import { useStyles } from "./FormListContainer.styles";
import useNotification from "hooks/useNotification";

import { GlobalReducerActions } from "reducers/globalReducer";

import { deepCopy } from "util/helpers";
import { useSelector, useDispatch } from "react-redux";

const iconsMap = {
  member: <img src="/button_icons/member.png" alt="member icon" />,
  location: <img src="/button_icons/location.png" alt="location icon" />,
  quote: <img src="/button_icons/comment.png" alt="testimonial icon" />,
  media: <img src="/button_icons/document.png" alt="document icon" />,
  video: <img src="/button_icons/image.png" alt="add icon" />,
  image: <img src="/button_icons/image.png" alt="add icon" />,
  award: <img src="/button_icons/image.png" alt="add icon" />,
};

const FormListContainer = ({
  header,
  subheader,
  data,
  redirectPath,
  editPath,
  redirectLabel,
  hasImage,
  setNewData,
  handleSubmit,
  deleteItem,
  itemLabel,
  avatar,
  disableOrderButton,
  fieldForCaptionText,
}) => {
  const loading = useSelector((state) => state.global.loading);
  const customedNotificationMessage = useSelector(
    (state) => state.global.customedNotificationMessage
  );
  const { setCustomedNotificationMessage } = GlobalReducerActions;
  const {
    displaySuccess,
    notificationHandler,
    notificationState,
    messageType,
  } = useNotification(customedNotificationMessage);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetNotification = () => {
    dispatch(setCustomedNotificationMessage(""));
    notificationHandler();
  };

  const handleRedirect = () => {
    dispatch(setCustomedNotificationMessage(""));
    navigate(redirectPath);
  };

  const handleNewPosition = (id, position, variation = 1) => {
    let dataCopy = deepCopy(data);
    const newPosition = position + variation;

    dataCopy.find((item) => item.position === newPosition).position = position;
    dataCopy.find((item) => item.id === id).position = newPosition;

    setNewData(dataCopy);
  };

  useEffect(() => {
    return () => {
      resetNotification();
    };
  }, []);

  useEffect(() => {
    if (customedNotificationMessage) displaySuccess();
  }, [customedNotificationMessage]);

  return (
    <div className={classes.container}>
      <SectionHeader header={header} subheader={subheader} />
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          className={classes.saveButton}
          disabled={disableOrderButton}
          onClick={handleSubmit}
        >
          SAVE ORDER
        </Button>
      </div>
      {!loading ? (
        <div>
          <FormWithPosition
            handleNewPosition={handleNewPosition}
            items={data}
            hasImage={hasImage}
            editPath={editPath}
            deleteItem={deleteItem}
            itemLabel={itemLabel ? itemLabel : "name"}
            avatar={avatar}
            fieldForCaptionText={fieldForCaptionText}
          />
          <div className={classes.addItemButton} onClick={handleRedirect}>
            <div className={classes.iconContainer}>{iconsMap[avatar]}</div>
            <div className={classes.labelContainer}>
              {`Add new ${redirectLabel}`}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.loadingContainer}>
          <LoaderStatic />
        </div>
      )}
      <Notification
        handler={resetNotification}
        notificationState={notificationState}
        vertical="bottom"
        type={messageType}
      />
    </div>
  );
};

FormListContainer.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  data: PropTypes.array.isRequired,
  redirectPath: PropTypes.string.isRequired,
  editPath: PropTypes.string.isRequired,
  redirectLabel: PropTypes.string.isRequired,
  hasImage: PropTypes.bool,
  setNewData: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  itemLabel: PropTypes.string,
  avatar: PropTypes.string,
  disableOrderButton: PropTypes.bool,
  fieldForCaptionText: PropTypes.string,
};

FormListContainer.defaultProps = {
  subheader: "",
  hasImage: false,
  itemLabel: "name",
  avatar: "member",
  disableOrderButton: true,
  fieldForCaptionText: "",
};

export default FormListContainer;
