import CardsWrapper from "../CardsWrapper/CardsWrapper";
import Card from "../Card/Card";
import CardText from "../CardText/CardText";
import { useIconsMap } from "hooks/useIconsMap";
import { useStyles } from "./LocationCard.styles";

const LocationCard = ({ header, streetAddress, zip, country }) => {
  const {
    locationCardUpperContainer,
    locationCardLowerContainer,
    locationIconContainer,
    locationHeaderContainer,
  } = useStyles();
  const iconsMap = useIconsMap({ style: { fontSize: "50px" } });

  return (
    <CardsWrapper>
      <Card
        cardStyles={{
          width: "304px",
          height: "162px",
        }}
      >
        <div className={locationCardUpperContainer}>
          <div className={locationIconContainer}>{iconsMap.locationBlack}</div>
          <div className={locationHeaderContainer}>
            <CardText type="header" text={header} />
          </div>
        </div>
        <div className={locationCardLowerContainer}>
          <CardText
            type="plain"
            text={streetAddress}
            customedStyles={{
              WebkitLineClamp: 2,
              maxHeight: "40px",
            }}
          />
          <CardText type="plain" text={`PC. ${zip}, ${country}`} />
        </div>
      </Card>
    </CardsWrapper>
  );
};

export default LocationCard;
