import { makeStyles } from "@material-ui/styles";
import colors from "theme/colors";

export const useStyles = makeStyles(() => ({
  formActionsContainer: () => ({
    marginBottom: "10px",
    width: "100%",
  }),

  rowStyle: () => ({
    display: "flex",
    width: "100%",
  }),
  formActionsButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  formActionsButton: {
    borderRadius: "8px",
    minWidth: "113px",
    height: "40px",
    color: "white",
  },
  deleteButton: () => ({
    minWidth: "40px",
    height: "40px",
    borderRadius: "12px",
    marginLeft: "24px",
    background: colors.lightGray,
    color: colors.black,
    "&:hover": {
      backgroundColor: "dimGray",
    },
  }),
}));
