import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { PressReleasesReducerActions } from "reducers/pressReleasesReducer";
import ROUTES from "constants/Routes";

const NewPressRelease = () => {
  const formContent = {
    header: "Add Press Release",
    inputs: [
      {
        label: "Title",
        fieldName: "title",
        required: true,
      },
      {
        label: "Press Release Date",
        fieldName: "date",
        type: "datepicker",
      },
      {
        label: "Text",
        fieldName: "text",
        type: "editableTextArea",
        placeholder: "Write or paste your press release copy here",
      },
    ],
  };

  const dispatch = useDispatch();
  const { setNewPressReleaseData, saveNewPressRelease } =
    PressReleasesReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const PressReleaseData = useSelector(
    (state) => state.pressReleases.newPressRelease
  );

  const setNewPressRelease = (fieldName, value) => {
    dispatch(
      setNewPressReleaseData({ ...PressReleaseData, [fieldName]: value })
    );
  };

  const handleSubmit = () => {
    dispatch(saveNewPressRelease(kit, PressReleaseData));
  };

  return (
    <FormContainer
      formContent={formContent}
      data={PressReleaseData}
      setNewData={setNewPressRelease}
      handleSubmit={handleSubmit}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.PRESS_RELEASES}`}
    />
  );
};

export default NewPressRelease;
