import { makeStyles } from "@material-ui/styles";
import { setHoverEffect } from "util/helpers";

export const useStyles = makeStyles(() => ({
  cardContainer: {
    width: "304px",
    height: "300px",
    ...setHoverEffect(),
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
}));
