import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { globalReducer } from "./globalReducer";
import { aboutReducer } from "./aboutReducer";
import { teamReducer } from "./teamReducer";
import { advisorsReducer } from "./advisorsReducer";
import { investorsReducer } from "./investorsReducer";
import { locationsReducer } from "./locationsReducer";
import { linksReducer } from "./linksReducer";
import { mediaContactsReducer } from "./mediaContactsReducer";
import { mediaCoverageReducer } from "./mediaCoverageReducer";
import { pressReleasesReducer } from "./pressReleasesReducer";
import { awardsReducer } from "./awardsReducer";
import { testimonialsReducer } from "./testimonialsReducer";
import { logosReducer } from "./logosReducer";
import { imagesReducer } from "./imagesReducer";
import { videosReducer } from "./videosReducer";
import { designReducer } from "./designReducer";


export const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  about: aboutReducer,
  team: teamReducer,
  advisors: advisorsReducer,
  investors: investorsReducer,
  locations: locationsReducer,
  links: linksReducer,
  mediaContacts: mediaContactsReducer,
  mediaCoverage: mediaCoverageReducer,
  pressReleases: pressReleasesReducer,
  awards: awardsReducer,
  testimonials: testimonialsReducer,
  logos: logosReducer,
  images: imagesReducer,
  videos: videosReducer,
  design: designReducer,
});
