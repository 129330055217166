const MESSAGES = {
  default_success: "Changes have been successfully saved!",
  default_error: "Something went wrong. Please, Try again",
  invalid_email: "Please use a valid email",
  invalid_role: "Please use a valid role",
  invalid_kit_name: "The name for your kit is not valid",
  kit_name_already_exists: "The selected kit name is already in use",
  user_already_exists: "User is already in use",
  payment_error: "Payment has not been confirmed. Try again or contact support",
  removed_item: "Item has been successfully removed"
};

export default MESSAGES;
