import { useSelector, useDispatch } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { LocationsReducerActions } from "reducers/locationsReducer";
import ROUTES from "constants/Routes";

const NewLocation = () => {
  const formContent = {
    header: "Address",
    subheader: "Add a new location",
    route: "/address",
    inputs: [
      {
        label: "Location Name",
        fieldName: "location_name",
        required: true,
      },
      {
        label: "Address Line 1",
        fieldName: "adress_line_1",
        required: true,
      },
      {
        label: "Address Line 2",
        fieldName: "adress_line_2",
        required: true,
      },
      {
        label: "City",
        fieldName: "city",
        required: true,
      },
      {
        label: "State",
        fieldName: "state",
        required: true,
      },
      {
        label: "ZIP/Postal Code",
        fieldName: "zip_code",
        required: true,
      },
      {
        label: "Country",
        fieldName: "country",
        required: true,
      },
    ],
  };

  const dispatch = useDispatch();
  const { setNewLocationData, saveNewLocation } = LocationsReducerActions;
  const kit = useSelector((state) => state.auth.currentKit);
  const locationData = useSelector((state) => state.locations.newLocation);

  const setNewLocation = (fieldName, value) => {
    dispatch(setNewLocationData({ ...locationData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveNewLocation(kit, locationData));
  };

  return (
    <FormContainer
      formContent={formContent}
      data={locationData}
      setNewData={setNewLocation}
      handleSubmit={handleSubmit}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.LOCATIONS}`}
    />
  );
};

export default NewLocation;
