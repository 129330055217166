import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import axiosInstance from "services/axiosInstance";

const useAxiosInterceptors = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        try {
          const JWT = await getAccessTokenSilently();
          setToken(JWT);
        } catch (e) {
          console.error("Access token error: ", e);
        }
      }
    })();
  }, [isAuthenticated, getAccessTokenSilently]);

  axiosInstance.interceptors.request.use((req) => {
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }

    return req;
  });
};

export default useAxiosInterceptors;
