import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { AdvisorsReducerActions } from "reducers/advisorsReducer";
import ROUTES from "constants/Routes";

const AdvisorsEditMember = () => {
  const formContent = {
    header: "Add New Advisor",
    inputs: [
      {
        label: "Name",
        fieldName: "name",
        required: true,
      },
      {
        label: "Title/Role",
        fieldName: "title_role",
      },
      {
        label: "Email",
        fieldName: "email",
        type: "email",
      },
      {
        label: "LinkedIn URL",
        fieldName: "linkedin_url",
        type: "url",
      },
      {
        label: "Twitter URL",
        fieldName: "twitter_url",
        type: "url",
      },
      {
        label: "Image",
        fieldName: "image",
        type: "file",
        avatar: "member",
      },
    ],
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const advisorData = useSelector((state) => state.advisors.selectedAdvisor);
  const {
    getSelectedAdvisorData,
    setSelectedAdvisorData,
    saveSelectedAdvisorChanges,
  } = AdvisorsReducerActions;

  const setAdvisorData = (fieldName, value) => {
    dispatch(setSelectedAdvisorData({ ...advisorData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveSelectedAdvisorChanges(kit, advisorData));
  };

  useEffect(() => {
    if (kit) dispatch(getSelectedAdvisorData(kit, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={advisorData}
      setNewData={setAdvisorData}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.ADVISORS}`}
    />
  );
};

export default AdvisorsEditMember;
