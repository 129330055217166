import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { TestimonialsReducerActions } from "reducers/testimonialsReducer";
import ROUTES from "constants/Routes";
import { setMembersOrderStr } from "util/helpers";

const Testimonials = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const testimonialsData = useSelector((state) => state.testimonials.data);
  const retrievedData = useSelector(
    (state) => state.testimonials.retrievedData
  );
  const originalPositions = useSelector(
    (state) => state.testimonials.originalPositions
  );
  const newPositions = useSelector((state) => state.testimonials.newPositions);
  const {
    getTestimonialsData,
    setTestimonialsData,
    saveChanges,
    deleteTestimonialData,
    setTestimonialsNewPositions,
  } = TestimonialsReducerActions;

  const setNewData = (data) => {
    dispatch(setTestimonialsData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, testimonialsData));
  };

  const deleteTestimonial = (id) => {
    dispatch(deleteTestimonialData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getTestimonialsData(kit));
    if (kit && retrievedData && !testimonialsData.length)
      dispatch(getTestimonialsData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(setTestimonialsNewPositions(setMembersOrderStr(testimonialsData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonialsData]);

  return (
    <FormListContainer
      header="Add or Edit Testimonials or Quotes"
      subheader="Highlight some customer testimonials or quotes"
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_TESTIMONIAL}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_TESTIMONIAL}`}
      redirectLabel={"testimonial"}
      data={testimonialsData}
      fieldForCaptionText="title"
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deleteTestimonial}
      avatar="quote"
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default Testimonials;
