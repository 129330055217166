import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  redirectButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  redirectButtonText: {
    textDecoration: "none",
  },
  redirectButton: () => ({
    minWidth: "40px",
    height: "40px",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "dimGray",
    },
  }),
}));
