import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";
import { setMembersOrderStr } from "util/helpers";
import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage, setCustomedNotificationMessage } = GlobalReducerActions;
const { SECTION, LIST_ITEM } = Endpoints;
const { TESTIMONIALS_TABLE } = Tables;

const NEW_TESTIMONIAL = {
  testimonial: "",
  name: "",
  title: "",
  link: "",
  image: "",
};

const TESTIMONIALS_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: [],
  selectedTestimonial: {},
  originalPositions: '',
  newPositions: '',
  newTestimonial: { ...NEW_TESTIMONIAL },
};

export const TESTIMONIALS_REDUCER_TYPES = {
  GET_TESTIMONIALS_DATA: "GET_TESTIMONIALS_DATA",
  SET_TESTIMONIALS_DATA: "SET_TESTIMONIALS_DATA",
  GET_SELECTED_TESTIMONIAL_DATA: "GET_SELECTED_TESTIMONIAL_DATA",
  SET_SELECTED_TESTIMONIAL_DATA: "SET_SELECTED_TESTIMONIAL_DATA",
  SET_TESTIMONIALS_RETRIEVED_DATA: "SET_TESTIMONIALS_RETRIEVED_DATA",
  SET_NEW_TESTIMONIAL_DATA: "SET_NEW_TESTIMONIAL_DATA",
  SET_TESTIMONIALS_NEW_POSITIONS_DATA: "SET_TESTIMONIALS_NEW_POSITIONS_DATA",
};

export const testimonialsReducer = (
  state = TESTIMONIALS_REDUCER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case TESTIMONIALS_REDUCER_TYPES.GET_TESTIMONIALS_DATA:
      const updatedPayload = [...payload];
      updatedPayload.forEach(item => {
        if (!item.originalPosition) {
          item.originalPosition = item.position
        }
      });
      return {
        ...state,
        data: updatedPayload,
        originalPositions: setMembersOrderStr(updatedPayload, true),
        newPositions: setMembersOrderStr(updatedPayload, true)
      };
    case TESTIMONIALS_REDUCER_TYPES.SET_TESTIMONIALS_DATA:
      return {
        ...state,
        data: payload,
      };
    case TESTIMONIALS_REDUCER_TYPES.SET_TESTIMONIALS_NEW_POSITIONS_DATA:
      return {
        ...state,
        newPositions: payload,
      };
    case TESTIMONIALS_REDUCER_TYPES.GET_SELECTED_TESTIMONIAL_DATA:
      return {
        ...state,
        selectedTestimonial: payload,
      };
    case TESTIMONIALS_REDUCER_TYPES.SET_SELECTED_TESTIMONIAL_DATA:
      return {
        ...state,
        selectedTestimonial: payload,
      };
    case TESTIMONIALS_REDUCER_TYPES.SET_NEW_TESTIMONIAL_DATA:
      return {
        ...state,
        newTestimonial: payload,
      };
    case TESTIMONIALS_REDUCER_TYPES.SET_TESTIMONIALS_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getTestimonialsData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${TESTIMONIALS_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: TESTIMONIALS_REDUCER_TYPES.GET_TESTIMONIALS_DATA,
          payload: response.data.item,
        });
      };
    }

    catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: TESTIMONIALS_REDUCER_TYPES.SET_TESTIMONIALS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const getSelectedTestimonialData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${LIST_ITEM}/${kit}/${TESTIMONIALS_TABLE}/${id}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: TESTIMONIALS_REDUCER_TYPES.GET_SELECTED_TESTIMONIAL_DATA,
          payload: response.data.data,
        });
        
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: TESTIMONIALS_REDUCER_TYPES.SET_TESTIMONIALS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setTestimonialsNewPositions = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TESTIMONIALS_REDUCER_TYPES.SET_TESTIMONIALS_NEW_POSITIONS_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setTestimonialsData = (payload) => {
  const sortedPositions = payload.sort((a, b) => a.position - b.position);
  return async (dispatch) => {
    try {
      dispatch({
        type: TESTIMONIALS_REDUCER_TYPES.SET_TESTIMONIALS_DATA,
        payload: sortedPositions,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setSelectedTestimonialData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TESTIMONIALS_REDUCER_TYPES.SET_SELECTED_TESTIMONIAL_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setNewTestimonialData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TESTIMONIALS_REDUCER_TYPES.SET_NEW_TESTIMONIAL_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  const data = [...newData];
  data.forEach(item => delete item.originalPosition);
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: TESTIMONIALS_TABLE,
          item: data,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: TESTIMONIALS_REDUCER_TYPES.GET_TESTIMONIALS_DATA,
          payload: response.data.item,
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const saveSelectedTestimonialChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "put",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: TESTIMONIALS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: TESTIMONIALS_REDUCER_TYPES.GET_SELECTED_TESTIMONIAL_DATA,
          payload: response.data,
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: TESTIMONIALS_REDUCER_TYPES.SET_TESTIMONIALS_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const saveNewTestimonial = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: TESTIMONIALS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: TESTIMONIALS_REDUCER_TYPES.GET_TESTIMONIALS_DATA,
          payload: response.data.item,
        });
        dispatch({
          type: TESTIMONIALS_REDUCER_TYPES.SET_NEW_TESTIMONIAL_DATA,
          payload: { ...NEW_TESTIMONIAL },
        });
        dispatch(setCustomedNotificationMessage(`${newData.title} has been successfully added!`));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: TESTIMONIALS_REDUCER_TYPES.SET_TESTIMONIALS_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const deleteTestimonialData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "delete",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: TESTIMONIALS_TABLE,
          id,
        },
      });
      if (response?.status === 200) {
        const { name } = response.data.deletedItem;
        dispatch(setCustomedNotificationMessage(`${name} has been successfuly removed!`));
        dispatch({
          type: TESTIMONIALS_REDUCER_TYPES.GET_TESTIMONIALS_DATA,
          payload: response.data.updatedList,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const TestimonialsReducerActions = {
  getTestimonialsData,
  setTestimonialsData,
  getSelectedTestimonialData,
  setSelectedTestimonialData,
  setNewTestimonialData,
  saveChanges,
  saveSelectedTestimonialChanges,
  saveNewTestimonial,
  deleteTestimonialData,
  setTestimonialsNewPositions,
};
