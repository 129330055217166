import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  teamMembersDataContainer: {
    width: "60%",
    height: "100%",
    paddingTop: "20px",
  },
  upperContainer: {
    width: "100%",
    display: "flex",
    height: "60%",
  },
  lowerContainer: {
    display: "flex",
    width: "100%",
    height: "25%",
  },
  iconsContainer: {
    justifyContent: "center",
    display: "flex",
    alignItems: "flex-start",
    width: "40%",
  },
  contactInfoContainer: {
    width: "60%",
    paddingTop: "10px",
  },
}));
