import CardsWrapper from "../CardsWrapper/CardsWrapper";
import Card from "../Card/Card";
import CardText from "../CardText/CardText";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { useStyles } from "./MediaCard.styles";
import { formatIfDate } from "util/dateFormat";

const MediaCard = ({
  title,
  image = "https://img2.rtve.es/i/?w=1600&i=1442912677842.jpg",
  date,
  link,
  excerpt,
}) => {
  const classes = useStyles();

  return (
    <CardsWrapper link={link} lg={4}>
      <Card className={classes.cardContainer}>
        <CardMedia component="img" height="130" image={image} alt={title} />
        <CardContent className={classes.contentContainer}>
          <CardText type="date" text={formatIfDate(date)} />
          <CardText
            type="header"
            text={title}
            customedStyles={{ WebkitLineClamp: 3 }}
          />
          <CardText
            type="plain"
            text={excerpt}
            customedStyles={{ WebkitLineClamp: 2, display: "-webkit-box" }}
          />
        </CardContent>
      </Card>
    </CardsWrapper>
  );
};

export default MediaCard;
