import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import useFetch from "hooks/useFetch";
import useNotification from "hooks/useNotification";

import Notification from "components/general/Notification";
import InformationStep from "components/form/RegistrationForm/InformationStep";
import SubscriptionSelectionStep from "components/form/RegistrationForm/SubscriptionSelectionStep";

import { LoadingButton } from "@mui/lab";
import { Box, Step, Stepper, StepLabel, Button } from "@mui/material";

import { useStyles } from "./RegistrationForm.styles";

const { REACT_APP_STRIPE_PK, REACT_APP_STRIPE_ACCOUNT_ID } = process.env;
const stripePromise = loadStripe(`${REACT_APP_STRIPE_PK}`, {
  stripeAccount: REACT_APP_STRIPE_ACCOUNT_ID,
});

const HorizontalLinearStepper = () => {
  const { user } = useSelector((state) => state.auth);

  const [formState, setFormState] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  const {
    notSelected,
    stepContainer,
    registerFormContainer,
    titlesContainer,
    registrationTitelTextBase,
    registrationTitle,
    registerStepper,
    registerActiveStepper,
    registerStep,
    registerStepLabel,
    previousButton,
  } = useStyles();

  const { displayError, notificationHandler, notificationState } =
    useNotification();

  const {
    loading: linksLoading,
    response: linksResponse,
    error: linksError,
    fetchData: fetchLinks,
  } = useFetch();

  const updateFormState = (newValues, hasError) => {
    if (!hasError) {
      const formStateCopy = { ...formState };

      const newFormState = { ...formStateCopy, ...newValues };

      setFormState(newFormState);

      fetchLinks("/stripe-links", "post", {
        email: user,
        ...newFormState,
      });
    }
  };

  const STEPS = [
    {
      key: "accountName",
      label: "Account name",
      step: 0,
    },
    {
      key: "billing",
      label: "Billing",
      step: 1,
    },
  ];

  useEffect(() => {
    if (linksError) {
      displayError(linksError);
    }
  }, [linksError]);

  const handleNext = () => {
    // if (linksResponse?.data?.link && !linksLoading) {
    //   window.location.href = linksResponse?.data?.link;
    // }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box className={registerFormContainer}>
      <Elements stripe={stripePromise}>
        <Notification
          handler={notificationHandler}
          notificationState={notificationState}
          type="error"
        />
        <div className={titlesContainer}>
          <span className={`${registrationTitelTextBase} ${registrationTitle}`}>
            Create your press kit
          </span>
        </div>
        <Stepper
          activeStep={activeStep}
          className={registerStepper}
          alternativeLabel
        >
          {STEPS.map(({ key, label, step }) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step
                key={key}
                className={`${registerStep} ${
                  step <= activeStep ? registerActiveStepper : ""
                }`}
                {...stepProps}
              >
                <StepLabel {...labelProps} className={registerStepLabel}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div
          className={`${stepContainer} ${0 === activeStep ? "" : notSelected}`}
        >
          <InformationStep updateFormState={updateFormState} />
        </div>
        <div
          className={`${stepContainer} ${1 === activeStep ? "" : notSelected}`}
        >
          <SubscriptionSelectionStep
            updateFormState={updateFormState}
            linksResponse={linksResponse}
            linksLoading={linksLoading}
          />
        </div>

        <>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {!activeStep ? null : (
              <Button
                color="inherit"
                onClick={() => setActiveStep(0)}
                sx={{ mr: 1 }}
              >
                PREVIOUS
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />

            {activeStep === STEPS.length - 1 ? null : (
              <LoadingButton
                variant="contained"
                onClick={() => setActiveStep(1)}
                loading={linksLoading}
                disabled={!linksResponse || linksLoading}
              >
                NEXT STEP
              </LoadingButton>
            )}
          </Box>
        </>
      </Elements>
    </Box>
  );
};

export default HorizontalLinearStepper;
