import axios from "axios";

// const tennatUrl = window.location;
// const tennantProtocol = tennatUrl.protocol;
//to be used if more envs are implemented
//const tennatUrlParts = tennatUrl.hostname.split(".")
//const apiUrl = `${tennatUrl.protocol}//${tennatUrlParts[0]}.api.${tennatUrlParts[2]}.${tennatUrlParts[3]}`

export default axios.create({
  baseURL: `https://rrliygjbuj.execute-api.us-east-1.amazonaws.com`,
});
