import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";

import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage } = GlobalReducerActions;
const { SECTION } = Endpoints;
const { LINKS_TABLE } = Tables;

const LINKS_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: {
    website_url: "",
    crunchbase_url: "",
    blog_url: "",
    medium_url: "",
    linkedin_url: "",
    twitter_url: "",
    facebook_url: "",
    pinterest_url: "",
    youtube_url: "",
    app_store_url: "",
    google_play_url: "",
    other_1: "",
    other_2: "",
    other_3: "",
  },
};

export const LINKS_REDUCER_TYPES = {
  GET_LINKS_DATA: "GET_LINKS_DATA",
  SET_LINKS_DATA: "SET_LINKS_DATA",
  SET_LINKS_RETRIEVED_DATA: "SET_LINKS_RETRIEVED_DATA",
};

export const linksReducer = (state = LINKS_REDUCER_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LINKS_REDUCER_TYPES.GET_LINKS_DATA:
      return {
        ...state,
        data: payload,
      };
    case LINKS_REDUCER_TYPES.SET_LINKS_DATA:
      return {
        ...state,
        data: payload,
      };
    case LINKS_REDUCER_TYPES.SET_LINKS_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getLinksData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${LINKS_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: LINKS_REDUCER_TYPES.GET_LINKS_DATA,
          payload: {
            ...response.data.item,
          },
        });
        
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: LINKS_REDUCER_TYPES.SET_LINKS_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setLinksData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LINKS_REDUCER_TYPES.SET_LINKS_DATA,
        payload: {
          ...payload,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: LINKS_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: LINKS_REDUCER_TYPES.GET_LINKS_DATA,
          payload: {
            ...response.data.item,
          },
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const LinksReducerActions = {
  getLinksData,
  setLinksData,
  saveChanges,
};
