/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import useNotification from "hooks/useNotification";

import { GlobalReducerActions } from "reducers/globalReducer";

import SectionHeader from "components/general/SectionHeader";
import FormTextField from "components/form/FormTextField";
import FormActions from "components/form/FormActions";
import FormFileUploadField from "components/form/FormFileUploadField";
import FormDatepickerField from "components/form/FormDatepickerField";
import FormEditableTextArea from "components/form/FormEditableTextArea";
import FormColorPickerField from "components/form/FormColorPickerField";
import LoaderStatic from "components/general/loaders/Loader/LoaderStatic";
import Notification from "components/general/Notification";
import FormItemAvatar from "../FormItemAvatar";
import { useStyles } from "./FormContainer.styles";
import dayjs from "dayjs";

const FormContainer = ({
  formContent,
  data,
  setNewData,
  handleSubmit,
  fileUpload = false,
  inputAvatar = false,
  listPath = "",
}) => {
  const loading = useSelector((state) => state.global.loading);
  const classes = useStyles({ fileUpload });
  const { setNotificationMessage, setCustomedNotificationMessage } =
    GlobalReducerActions;
  const customedNotificationMessage = useSelector(
    (state) => state.global.customedNotificationMessage
  );
  const notificationMessage = useSelector(
    (state) => state.global.notificationMessage
  );
  const {
    displaySuccess,
    displayError,
    notificationHandler,
    notificationState,
    messageType,
  } = useNotification(customedNotificationMessage);
  const dispatch = useDispatch();

  const resetNotification = () => {
    dispatch(setNotificationMessage(""));
    dispatch(setCustomedNotificationMessage(""));
    notificationHandler();
  };

  const addAvatarToInput = (avatar, input) => {
    return (
      <div className={classes.fieldContainer}>
        {inputAvatar && (
          <div className={classes.itemAvatarContainer}>
            <FormItemAvatar type={avatar} list={false} />
          </div>
        )}
        <div
          className={
            fileUpload
              ? classes.inputContainerWithFileUpload
              : classes.inputContainer
          }
        >
          {input}
        </div>
      </div>
    );
  };

  const setInputSettingsObj = (input) => ({
    fieldName: input.fieldName,
    value: data[input.fieldName],
    key: input.fieldName,
    label: input.label,
    required: input.required,
    retrieveInfo: setNewData,
    avatar: input.avatar,
    placeholder: input.placeholder,
  });

  const generateFileUploadFormInput = () => {
    const input = formContent.inputs.filter(
      (input) => input.type === "file"
    )[0];

    const inputSettings = setInputSettingsObj(input);
    return <FormFileUploadField {...inputSettings} />;
  };

  const generateNonFileUploadFormInputs = () => {
    const inputsArr = [];
    const inputs = formContent.inputs.filter((input) => input.type !== "file");
    inputs.forEach((input) => {
      const inputSettings = setInputSettingsObj(input);

      switch (input.type) {
        case "editableTextArea":
          inputsArr.push(<FormEditableTextArea {...inputSettings} />);
          break;
        case "datepicker":
          if (!inputSettings.value) {
            setNewData(inputSettings.fieldName, dayjs().format("YYYY-MM-DD"));
          }
          inputsArr.push(
            addAvatarToInput(
              input.avatar,
              <FormDatepickerField {...inputSettings} />
            )
          );
          break;
        case "colorPicker":
          inputsArr.push(<FormColorPickerField {...inputSettings} />);
          break;
        default:
          inputsArr.push(
            addAvatarToInput(
              input.avatar,
              <FormTextField
                type={input.type}
                inputHelperText={input.helperText}
                {...inputSettings}
              />
            )
          );
      }
    });
    return inputsArr;
  };

  useEffect(() => {
    return () => {
      resetNotification();
    };
  }, []);

  useEffect(() => {
    if (customedNotificationMessage) displaySuccess();
    if (notificationMessage) {
      if (notificationMessage.toLowerCase().includes("success"))
        displaySuccess(notificationMessage);
      if (notificationMessage.toLowerCase().includes("wrong")) displayError();
    }
  }, [customedNotificationMessage, notificationMessage]);

  return (
    <>
      <SectionHeader
        header={formContent?.header}
        subheader={formContent?.subheader}
      />
      {!loading ? (
        <div>
          <div>
            <FormActions
              loading={loading}
              onSave={handleSubmit}
              listPath={listPath}
            />
          </div>
          <div className={classes.fieldsContainer}>
            {fileUpload && (
              <div className={classes.fileUploadfieldContainer}>
                {generateFileUploadFormInput()}
              </div>
            )}
            <div className={classes.nonFileUploadfieldsContainer}>
              {generateNonFileUploadFormInputs()}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.loadingContainer}>
          <LoaderStatic />
        </div>
      )}
      <Notification
        handler={resetNotification}
        notificationState={notificationState}
        vertical="bottom"
        type={messageType}
      />
    </>
  );
};

export default FormContainer;
