import CardsWrapper from "../CardsWrapper/CardsWrapper";
import Card from "../Card/Card";
import CardText from "../CardText/CardText";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./PressReleaseTextArea.styles";
import { formatIfDate } from "util/dateFormat";

export const PressReleaseTextArea = ({ id }) => {
  const classes = useStyles();
  const data = useSelector((state) => state.pressReleases.data);
  const [selectedRelease, setSelectedRelease] = useState(null);

  useEffect(() => {
    if (data) {
      const release = data.filter((item) => item.id === id)[0];
      setSelectedRelease({
        date: release?.date,
        title: release?.title,
        text: release?.text,
      });
    }
  }, [data, id]);

  return (
    <CardsWrapper md={12} lg={12} xl={12}>
      <Card className={classes.pressReleaseCard}>
        <CardText type="date" text={formatIfDate(selectedRelease?.date)} />
        <CardText type="header" text={selectedRelease?.title} />
        <CardText
          type="plain"
          text={selectedRelease?.text}
          customedStyles={{ WebkitLineClamp: 2 }}
        />
      </Card>
    </CardsWrapper>
  );
};

export default PressReleaseTextArea;
