import {
  Box,
  Card,
  Avatar,
  Skeleton,
  Typography,
  CardContent,
  CardActionArea,
} from "@mui/material";

import {
  Business as BusinessIcon,
  AddBusiness as AddBusinessIcon,
} from "@mui/icons-material";

import { useStyles } from "./SubscriptionSelectionStep.styles";

import { ROLES } from "containers/App/App.constants";

const SubscriptionSelectionStep = ({ linksResponse, linksLoading }) => {
  const {
    subscriptionSelectionContainer,
    unselectedCard,
    cardTextContainer,
    subscriptionCardContainer,
    subscriptionDescriptionText,
    cardIcon,
    clickableArea,
    skeletonStyles,
  } = useStyles();

  const handleClick = () => {
    if (linksResponse?.data?.link && !linksLoading) {
      window.location.href = linksResponse?.data?.link;
    }
  };

  return (
    <Box className={subscriptionSelectionContainer}>
      {ROLES.map(({ role, amount }) => {
        return (
          <Card
            key={role}
            className={subscriptionCardContainer}
            onClick={handleClick}
            sx={{ maxWidth: 345 }}
          >
            {linksLoading ? (
              <div className={clickableArea}>
                <Skeleton
                  className={skeletonStyles}
                  variant="circular"
                  width={60}
                  height={60}
                />
                <Skeleton
                  className={skeletonStyles}
                  variant="rectangular"
                  width={120}
                  height={30}
                />
                <Skeleton
                  className={skeletonStyles}
                  variant="rectangular"
                  width={60}
                  height={30}
                />
                <Skeleton
                  className={skeletonStyles}
                  variant="rectangular"
                  width={200}
                  height={45}
                />
              </div>
            ) : (
              <CardActionArea className={clickableArea}>
                <div className={unselectedCard}>
                  <div>
                    <Avatar className={cardIcon}>
                      {role === "Investor" ? (
                        <AddBusinessIcon />
                      ) : (
                        <BusinessIcon />
                      )}
                    </Avatar>
                  </div>
                  <CardContent
                    className={cardTextContainer}
                    style={{ textAlign: "center" }}
                  >
                    <Typography gutterBottom variant="h5" component="div">
                      {role}
                    </Typography>
                    <Typography variant="body2">$ {amount}</Typography>
                  </CardContent>
                </div>
                <Typography
                  className={subscriptionDescriptionText}
                  sx={{ mt: 2, mb: 1 }}
                >
                  This is a short description of the {role} option.
                </Typography>
              </CardActionArea>
            )}
          </Card>
        );
      })}
    </Box>
  );
};

export default SubscriptionSelectionStep;
