import { GlobalReducerActions } from "./globalReducer";
import axiosInstance from "services/axiosInstance";
import { setMembersOrderStr } from "util/helpers";
import Endpoints from "constants/Endpoints";
import Tables from "constants/Tables";

const { setLoading, setNotificationMessage, setCustomedNotificationMessage } = GlobalReducerActions;
const { SECTION, LIST_ITEM } = Endpoints;
const { PRESS_RELEASES_TABLE } = Tables;

const NEW_PRESS_RELEASE = {
  title: "",
  date: "",
  text: "",
};

const PRESS_RELEASES_REDUCER_INITIAL_STATE = {
  retrievedData: false,
  data: [],
  selectedPressRelease: {},
  originalPositions: '',
  newPositions: '',
  newPressRelease: { ...NEW_PRESS_RELEASE },
};

export const PRESS_RELEASES_REDUCER_TYPES = {
  GET_PRESS_RELEASES_DATA: "GET_PRESS_RELEASES_DATA",
  SET_PRESS_RELEASES_DATA: "SET_PRESS_RELEASES_DATA",
  GET_SELECTED_PRESS_RELEASE_DATA: "GET_SELECTED_PRESS_RELEASE_DATA",
  SET_SELECTED_PRESS_RELEASE_DATA: "SET_SELECTED_PRESS_RELEASE_DATA",
  SET_PRESS_RELEASES_RETRIEVED_DATA: "SET_PRESS_RELEASES_RETRIEVED_DATA",
  SET_NEW_PRESS_RELEASE_DATA: "SET_NEW_PRESS_RELEASE_DATA",
  SET_PRESS_RELEASE_NEW_POSITIONS_DATA: "SET_ADVISORS_NEW_POSITIONS_DATA",
};

export const pressReleasesReducer = (
  state = PRESS_RELEASES_REDUCER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case PRESS_RELEASES_REDUCER_TYPES.GET_PRESS_RELEASES_DATA:
      const updatedPayload = [...payload];
      updatedPayload.forEach(advisor => {
        if (!advisor.originalPosition) {
          advisor.originalPosition = advisor.position
        }
      });
      return {
        ...state,
        data: updatedPayload,
        originalPositions: setMembersOrderStr(updatedPayload, true),
        newPositions: setMembersOrderStr(updatedPayload, true)
      };
    case PRESS_RELEASES_REDUCER_TYPES.SET_PRESS_RELEASES_DATA:
      return {
        ...state,
        data: payload,
      };
    case PRESS_RELEASES_REDUCER_TYPES.SET_PRESS_RELEASE_NEW_POSITIONS_DATA:
      return {
        ...state,
        newPositions: payload,
      };
    case PRESS_RELEASES_REDUCER_TYPES.GET_SELECTED_PRESS_RELEASE_DATA:
      return {
        ...state,
        selectedPressRelease: payload,
      };
    case PRESS_RELEASES_REDUCER_TYPES.SET_SELECTED_PRESS_RELEASE_DATA:
      return {
        ...state,
        selectedPressRelease: payload,
      };
    case PRESS_RELEASES_REDUCER_TYPES.SET_NEW_PRESS_RELEASE_DATA:
      return {
        ...state,
        newPressRelease: payload,
      };
    case PRESS_RELEASES_REDUCER_TYPES.SET_PRESS_RELEASES_RETRIEVED_DATA:
      return {
        ...state,
        retrievedData: payload,
      };
    default:
      return state;
  }
};

const getPressReleasesData = (kit) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${SECTION}/${kit}/${PRESS_RELEASES_TABLE}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: PRESS_RELEASES_REDUCER_TYPES.GET_PRESS_RELEASES_DATA,
          payload: response.data.item,
        });
      };
    }

    catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: PRESS_RELEASES_REDUCER_TYPES.SET_PRESS_RELEASES_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const getSelectedPressReleaseData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "get",
        url: `${LIST_ITEM}/${kit}/${PRESS_RELEASES_TABLE}/${id}`,
      });
      if (response?.status === 200 && response.data) {
        dispatch({
          type: PRESS_RELEASES_REDUCER_TYPES.GET_SELECTED_PRESS_RELEASE_DATA,
          payload: response.data.data,
        });
        
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: PRESS_RELEASES_REDUCER_TYPES.SET_PRESS_RELEASES_RETRIEVED_DATA,
        payload: true,
      });
    }
  };
};

const setPressReleasesNewPositions = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: PRESS_RELEASES_REDUCER_TYPES.SET_PRESS_RELEASE_NEW_POSITIONS_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const setPressReleasesData = (payload) => {
  const sortedPositions = payload.sort((a, b) => a.position - b.position);
  return async (dispatch) => {
    try {
      dispatch({
        type: PRESS_RELEASES_REDUCER_TYPES.SET_PRESS_RELEASES_DATA,
        payload: sortedPositions,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setSelectedPressReleaseData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: PRESS_RELEASES_REDUCER_TYPES.SET_SELECTED_PRESS_RELEASE_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const setNewPressReleaseData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: PRESS_RELEASES_REDUCER_TYPES.SET_NEW_PRESS_RELEASE_DATA,
        payload,
      });
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };
};

const saveChanges = (kit, newData) => {
  const data = [...newData];
  data.forEach(item => delete item.originalPosition);
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: SECTION,
        data: {
          kit,
          tableName: PRESS_RELEASES_TABLE,
          item: data,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: PRESS_RELEASES_REDUCER_TYPES.GET_PRESS_RELEASES_DATA,
          payload: response.data.item,
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const saveSelectedPressReleaseChanges = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "put",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: PRESS_RELEASES_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: PRESS_RELEASES_REDUCER_TYPES.GET_SELECTED_PRESS_RELEASE_DATA,
          payload: response.data,
        });
        dispatch(setNotificationMessage('default_success'));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: PRESS_RELEASES_REDUCER_TYPES.SET_PRESS_RELEASES_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const saveNewPressRelease = (kit, newData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "post",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: PRESS_RELEASES_TABLE,
          item: newData,
        },
      });
      if (response?.status === 200) {
        dispatch({
          type: PRESS_RELEASES_REDUCER_TYPES.GET_PRESS_RELEASES_DATA,
          payload: response.data.item,
        });
        dispatch({
          type: PRESS_RELEASES_REDUCER_TYPES.SET_NEW_PRESS_RELEASE_DATA,
          payload: { ...NEW_PRESS_RELEASE },
        });
        dispatch(setCustomedNotificationMessage(`${newData.title} has been successfully added!`));
      }
    } catch (error) {
      dispatch(setNotificationMessage('default_error'));
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      dispatch({
        type: PRESS_RELEASES_REDUCER_TYPES.SET_PRESS_RELEASES_RETRIEVED_DATA,
        payload: false,
      });
    }
  };
};

const deletePressReleaseData = (kit, id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        method: "delete",
        url: LIST_ITEM,
        data: {
          kit,
          tableName: PRESS_RELEASES_TABLE,
          id,
        },
      });
      if (response?.status === 200) {
        const { title } = response.data.deletedItem;
        dispatch(setCustomedNotificationMessage(`${title} has been successfuly removed!`));
        dispatch({
          type: PRESS_RELEASES_REDUCER_TYPES.GET_PRESS_RELEASES_DATA,
          payload: response.data.updatedList,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const PressReleasesReducerActions = {
  getPressReleasesData,
  setPressReleasesData,
  getSelectedPressReleaseData,
  setSelectedPressReleaseData,
  setNewPressReleaseData,
  saveChanges,
  saveSelectedPressReleaseChanges,
  saveNewPressRelease,
  deletePressReleaseData,
  setPressReleasesNewPositions,
};
