import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useStyles } from "./CardsWrapper.styles";

const CardsWrapper = ({
  children,
  xl = 3,
  lg = 6,
  md = 6,
  sm = 12,
  xs = 12,
  spacing = 2,
  styles = {},
  link,
}) => {
  const { cardsContainer, linkStyles } = useStyles(styles);
  return (
    <Grid
      className={cardsContainer}
      xl={xl}
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
      spacing={spacing}
    >
      {link ? (
        <Link href target="_blank" className={linkStyles} to={link}>
          {children}
        </Link>
      ) : (
        children
      )}
    </Grid>
  );
};

export default CardsWrapper;
