import Typography from "@mui/material/Typography";
import { useStyles } from "./CardText.styles";

const CardText = ({ type, text, customedStyles = {} }) => {
  const {
    cardHeader,
    cardSubheader,
    plainText,
    dateText,
    mediaOutletText,
    nameText,
  } = useStyles(customedStyles);

  const getClassAndVariant = () => {
    switch (type) {
      case "header":
        return { textClass: cardHeader, variant: "h5" };
      case "subheader":
        return { textClass: cardSubheader, variant: "body2" };
      case "plain":
        return { textClass: plainText, variant: "body2" };
      case "date":
        return { textClass: dateText, variant: "body2" };
      case "mediaOutlet":
        return { textClass: mediaOutletText, variant: "body2" };
      case "nameText":
        return { textClass: nameText, variant: "body2" };
      default:
        return "";
    }
  };

  const { textClass, variant } = getClassAndVariant();

  return (
    <Typography
      className={textClass}
      gutterBottom
      variant={variant}
      component="div"
      sx={{ whiteSpace: "pre-line" }}
    >
      {text}
    </Typography>
  );
};

export default CardText;
