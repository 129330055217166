import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: { width: "12%" },
}));
