import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { LocationsReducerActions } from "reducers/locationsReducer";
import ROUTES from "constants/Routes";

const EditLocation = () => {
  const formContent = {
    header: "Address",
    subheader: "Edit your company address",
    route: "/address",
    inputs: [
      {
        label: "Location Name",
        fieldName: "location_name",
        required: true,
      },
      {
        label: "Address Line 1",
        fieldName: "adress_line_1",
        required: true,
      },
      {
        label: "Address Line 2",
        fieldName: "adress_line_2",
        required: true,
      },
      {
        label: "City",
        fieldName: "city",
        required: true,
      },
      {
        label: "State",
        fieldName: "state",
        required: true,
      },
      {
        label: "ZIP/Postal Code",
        fieldName: "zip_code",
        required: true,
      },
      {
        label: "Country",
        fieldName: "country",
        required: true,
      },
    ],
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const locationData = useSelector((state) => state.locations.selectedLocation);
  const {
    getSelectedLocationData,
    setSelectedLocationData,
    saveSelectedLocationChanges,
  } = LocationsReducerActions;

  const setLocationData = (fieldName, value) => {
    dispatch(setSelectedLocationData({ ...locationData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveSelectedLocationChanges(kit, locationData));
  };

  useEffect(() => {
    if (kit) dispatch(getSelectedLocationData(kit, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={locationData}
      setNewData={setLocationData}
      handleSubmit={handleSubmit}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.LOCATIONS}`}
    />
  );
};

export default EditLocation;
