import { useState } from "react";
import Card from "../Card/Card";
import CardsWrapper from "../CardsWrapper/CardsWrapper";
import CardAvatar from "../CardAvatar/CardAvatar";
import CardText from "../CardText/CardText";
import CustomedModal from "../CustomedModal/CustomedModal";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Box from "@mui/material/Box";
import { useStyles } from "./QuotationCard.styles";
import useColor from "hooks/useColor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export const QuotationCard = ({ quote, author, title, picture }) => {
  const [open, setOpen] = useState(false);
  const primary = useColor("primary");
  const classes = useStyles();
  const handleClose = () => setOpen(false);

  return (
    <CardsWrapper>
      <Card cardStyles={{ width: "441px", height: "217px" }}>
        <div className={classes.quoteUpperContainer}>
          <div className={classes.quoteLeftContainer}>
            <FormatQuoteIcon
              style={{
                fontSize: "90px",
                color: primary,
                position: "relative",
              }}
            />
          </div>
          <div className={classes.quoteContainer} onClick={() => setOpen(true)}>
            <CardText
              text={quote}
              type="subheader"
              customedStyles={{
                fontSize: "16px",
                WebkitLineClamp: 5,
                color: "#03010C",
              }}
            />
          </div>
        </div>
        <div className={classes.quotelowerContainer}>
          <div className={classes.quoteLeftContainer}>
            <CardAvatar width={64} height={64} picture={picture} />
          </div>
          <div className={classes.quoteAuthorContainer}>
            <CardText text={author} type="nameText" />
            <CardText
              text={title}
              type="subheader"
              customedStyles={{ fontSize: "12px", WebkitLineClamp: 2 }}
            />
          </div>
        </div>
      </Card>
      <CustomedModal open={open} handleClose={handleClose}>
        <Box sx={style}>
          <CardText
            type="plain"
            text={quote}
            customedStyles={{ fontSize: "16px" }}
          />
        </Box>
      </CustomedModal>
    </CardsWrapper>
  );
};

export default QuotationCard;
