import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "components/form/FormContainer";
import { InvestorsReducerActions } from "reducers/investorsReducer";
import ROUTES from "constants/Routes";

const InvestorsEditMember = () => {
  const formContent = {
    header: "Edit Investor",
    inputs: [
      {
        label: "Name",
        fieldName: "name",
        required: true,
        avatar: "member",
      },
      {
        label: "Title/Organization",
        fieldName: "description",
      },
      {
        label: "Website URL",
        fieldName: "website_url",
        type: "url",
      },
      {
        label: "LinkedIn URL",
        fieldName: "linkedin_url",
        type: "url",
      },
      {
        label: "Twitter URL",
        fieldName: "twitter_url",
        type: "url",
      },
      {
        label: "Image",
        fieldName: "image",
        type: "file",
        avatar: "member",
      },
    ],
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const investorData = useSelector((state) => state.investors.selectedInvestor);
  const {
    getSelectedInvestorData,
    setSelectedInvestorData,
    saveSelectedInvestorChanges,
  } = InvestorsReducerActions;

  const setInvestorData = (fieldName, value) => {
    dispatch(setSelectedInvestorData({ ...investorData, [fieldName]: value }));
  };

  const handleSubmit = () => {
    dispatch(saveSelectedInvestorChanges(kit, investorData));
  };

  useEffect(() => {
    if (kit) dispatch(getSelectedInvestorData(kit, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  return (
    <FormContainer
      formContent={formContent}
      data={investorData}
      setNewData={setInvestorData}
      handleSubmit={handleSubmit}
      fileUpload={true}
      listPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.INVESTORS}`}
    />
  );
};

export default InvestorsEditMember;
