import React from "react";
import PropTypes from "prop-types";

import { useIconsMap } from "hooks/useIconsMap";

import CardsWrapper from "components/general/CardsWrapper";
import CardAvatar from "components/general/CardAvatar";
import Card from "components/general/Card";
import CardText from "components/general/CardText";

import { useStyles } from "./AwardCard.styles";
import { formatIfDate } from "util/dateFormat";

const AwardCard = ({ name, date, image = "", link = "" }) => {
  const { awardLink, awardCardContainer } = useStyles();
  const iconsMap = useIconsMap({ imageStyle: { width: "40px" } });
  return (
    <CardsWrapper lg={5}>
      <a target="_blank" href={link} className={awardLink} rel="noreferrer">
        <Card className={awardCardContainer}>
          {image ? (
            <CardAvatar width={64} height={64} picture={image} />
          ) : (
            <div>{iconsMap.award}</div>
          )}
          <div>
            <CardText
              type="date"
              text={formatIfDate(date)}
              customedStyles={{ marginBottom: "14px" }}
            />
            <CardText type="header" text={name} />
          </div>
        </Card>
      </a>
    </CardsWrapper>
  );
};

AwardCard.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.string,
  image: PropTypes.string,
};

export default AwardCard;
