import { useSelector } from "react-redux";
import { LinksReducerActions } from "reducers/linksReducer";
import Grid from "@mui/material/Grid";
import KitContainer from "components/kit/KitContainer";
import LinkTile from "components/general/LinkTile";

const KitLinks = () => {
  const { getLinksData } = LinksReducerActions;
  const linksData = useSelector((state) => state.links.data);

  const mediaPlatform = Object.keys(linksData);
  return (
    <KitContainer header="Links" selectedState="links" getData={getLinksData}>
      <Grid container>
        {mediaPlatform.map((link) => {
          const resolvedURL = linksData[link];
          const imageToDisplay = link.includes("other")
            ? "link"
            : link.replace("_url", "");

          return resolvedURL ? (
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <LinkTile image={imageToDisplay} url={resolvedURL} />
            </Grid>
          ) : null;
        })}
      </Grid>
    </KitContainer>
  );
};

export default KitLinks;
