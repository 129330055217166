import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { AdvisorsReducerActions } from "reducers/advisorsReducer";
import { setMembersOrderStr } from "util/helpers";

import ROUTES from "constants/Routes";

const Advisors = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const advisorsData = useSelector((state) => state.advisors.data);
  const retrievedData = useSelector((state) => state.advisors.retrievedData);
  const originalPositions = useSelector(
    (state) => state.advisors.originalPositions
  );
  const newPositions = useSelector((state) => state.advisors.newPositions);
  const {
    getAdvisorsData,
    setAdvisorsNewPositions,
    setAdvisorsData,
    saveChanges,
    deleteAdvisorData,
  } = AdvisorsReducerActions;

  const setNewData = (data) => {
    dispatch(setAdvisorsData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, advisorsData));
  };

  const deleteAdvisor = (id) => {
    dispatch(deleteAdvisorData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getAdvisorsData(kit));
    if (kit && retrievedData && !advisorsData.length)
      dispatch(getAdvisorsData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(setAdvisorsNewPositions(setMembersOrderStr(advisorsData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisorsData]);

  return (
    <FormListContainer
      header="Add Advisors"
      subheader="Add names, roles, contact information, and images for each advisor"
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.ADVISORS_NEW_MEMBER}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.ADVISORS_EDIT_MEMBER}`}
      redirectLabel={"advisor"}
      data={advisorsData}
      fieldForCaptionText="title_role"
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deleteAdvisor}
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default Advisors;
