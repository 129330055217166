import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  itemRowStyle: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: "1.5rem",
    background: "#FBFBFA",
    borderRadius: "15px",
    maxHeight: "78px",
    boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.1)",
    padding: "12px 32px 12px 22px",
    marginBottom: "22px",
    marginLeft: "0px",
    "@media(max-width: 900px)": {
      width: "85%",
    },
  },
  itemText: {
    fontWeight: 600,
    fontSize: "1.25rem",
    letterSpacing: "-0.022em",
  },
  captionText: {
    fontWeight: 400,
    fontSize: "1rem",
    letterSpacing: "-0.022em",
    color: "#C2C2B4",
  },
  itemWrapper: {
    width: "350px",
    whiteSpace: "nowrap",
    "@media(max-width: 1200px)": {
      width: "200px",
    },
    "@media(max-width: 700px)": {
      width: "100px",
    },
  },
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
  },
  labelContainer: {
    flex: "1 0 auto",
  },
  buttonsContainer: {
    display: "flex",
    gap: "2.5rem",
    justifyContent: "space-between",
    "@media(max-width: 1200px)": {},
    height: "100%",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  arrowsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem",
    "& .MuiButtonBase-root": {
      padding: 0,
    },
  },
  formWithPositionContainer: {
    marginBottom: "22px",
  },
}));
