import { useSelector } from "react-redux";
import { AboutReducerActions } from "reducers/aboutReducer";
import { makeStyles } from "@material-ui/styles";
import KitContainer from "components/kit/KitContainer";
import { Box } from "@mui/material";

const useStyles = makeStyles({
  aboutText: {
    color: "#867B7B",
    fontSize: "16px",
    lineHeight: "34px",
    fontWeight: 400,
    letterSpacing: "-0.017em",
    whiteSpace: "pre-line",
  },
});

const KitAbout = () => {
  const { aboutText } = useStyles();
  const data = useSelector((state) => state.about.data.about_text);
  const { getAboutData } = AboutReducerActions;

  return (
    <KitContainer header="About" selectedState="about" getData={getAboutData}>
      <Box className={aboutText}>{data}</Box>
    </KitContainer>
  );
};

export default KitAbout;
