import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormListContainer from "components/form/FormListContainer";
import { MediaContactsReducerActions } from "reducers/mediaContactsReducer";
import { setMembersOrderStr } from "util/helpers";
import ROUTES from "constants/Routes";

const MediaContacts = () => {
  const dispatch = useDispatch();
  const kit = useSelector((state) => state.auth.currentKit);
  const mediaContactsData = useSelector((state) => state.mediaContacts.data);
  const retrievedData = useSelector(
    (state) => state.mediaContacts.retrievedData
  );
  const originalPositions = useSelector(
    (state) => state.mediaContacts.originalPositions
  );
  const newPositions = useSelector((state) => state.mediaContacts.newPositions);
  const {
    getMediaContactsData,
    setMediaContactsData,
    saveChanges,
    deleteMediaContactData,
    setMediaContactsNewPositions,
  } = MediaContactsReducerActions;

  const setNewData = (data) => {
    dispatch(setMediaContactsData(data));
  };

  const handleSubmit = () => {
    dispatch(saveChanges(kit, mediaContactsData));
  };

  const deleteMediaContact = (id) => {
    dispatch(deleteMediaContactData(kit, id));
  };

  useEffect(() => {
    if (kit && !retrievedData) dispatch(getMediaContactsData(kit));
    if (kit && retrievedData && !mediaContactsData.length)
      dispatch(getMediaContactsData(kit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit]);

  useEffect(() => {
    dispatch(
      setMediaContactsNewPositions(setMembersOrderStr(mediaContactsData))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaContactsData]);

  return (
    <FormListContainer
      header="Add Media Contacts"
      subheader="Add names, descriptions and images/logos of your media contacts"
      redirectPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.NEW_MEDIA_CONTACT}`}
      editPath={`${ROUTES.SETTINGS_PREFIX}${ROUTES.EDIT_MEDIA_CONTACT}`}
      redirectLabel={"media contact"}
      data={mediaContactsData}
      fieldForCaptionText="description"
      setNewData={setNewData}
      handleSubmit={handleSubmit}
      deleteItem={deleteMediaContact}
      disableOrderButton={originalPositions === newPositions}
    />
  );
};

export default MediaContacts;
